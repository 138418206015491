<script>
    import { sendAnalytics } from "@/core/SendAnalytics";
    import { numberFormat } from "@/utils";
    import TheOrderItem from "@/TheCheckout/TheOrderItem";
    import TheCheckoutConfirmDelete from "@/TheCheckout/TheCheckoutConfirmDelete";

    export default {
        name: "TheCheckoutOrder",
        components: { TheCheckoutConfirmDelete, TheOrderItem },
        props: {
            ordersList: [Object, Array],
            activePaySystem: [Boolean, Number],
            isOverPriceState: Boolean,
            objectForCreatedProduct: Object,
        },
        data() {
            return {
                isModalConfirmOpened: false,
                removableOrderItem: {},
                textOverPrice:
                    "При доставке заказов на сумму свыше 15 000 рублей доступен только вариант оплаты «Картой на сайте». Мы страхуем все наши отправления и гарантируем доставку вашей покупки в указанный срок",
            };
        },
        methods: {
            changeSelect({ type, quantity, basketId, offerId, isUpSale }) {
                this.$emit("start-loading-sidebar");

                if (type === "quantity") {
                    this.changeQuantity({ quantity, basketId, isUpSale });
                }
                if (type === "size" || type === "color") {
                    this.changeSizeOrColor({ isUpSale, basketId, offerId });
                }
            },
            changeSizeOrColor({ isUpSale, basketId, offerId }) {
                this.$store.orderApi
                    .replaceProductCheckout({
                        basket_id: basketId,
                        product_id: offerId,
                        PAY_SYSTEM_ID: this.activePaySystem,
                        isUpSale,
                    })
                    .then((res) => {
                        this.$emit("update-orders-data", res);

                        this.$emit("stop-loading-sidebar");

                        this.$eventBus.$emit("checkout-call-load-delivery");
                        sendAnalytics.send(
                            sendAnalytics.firstStepCheckoutEvents.cartChangeVariant
                        );
                    });
            },
            changeQuantity({ quantity, basketId, isUpSale }) {
                this.$store.orderApi
                    .modifyQuantityOrder({
                        quantity,
                        product_id: basketId,
                        PAY_SYSTEM_ID: this.activePaySystem,
                        isUpSale,
                    })
                    .then((res) => {
                        if (quantity === 0) {
                            let currentProduct = this.ordersList[basketId];
                            sendAnalytics.send(sendAnalytics.events.removeFromCart, {
                                name: currentProduct.name,
                                productId: currentProduct.productId,
                                price: numberFormat(currentProduct.price, 0, ".", ""),
                                quantity: currentProduct.quantity,
                            });
                        }

                        this.$emit("update-orders-data", res);

                        this.$emit("stop-loading-sidebar");

                        if (res.total === 0) {
                            // если удаляем последний товар
                            window.location.href = "/";
                            return false;
                        }

                        this.$eventBus.$emit("checkout-call-load-delivery", true);

                        if (quantity === 0) {
                            sendAnalytics.send(
                                sendAnalytics.firstStepCheckoutEvents.cartItemDelete
                            );
                            this.closeConfirmModal();
                        } else {
                            sendAnalytics.send(
                                sendAnalytics.firstStepCheckoutEvents.cartChangeCount
                            );
                        }
                    });
            },
            openConfirmModal({ isUpSale, basketId }) {
                this.isModalConfirmOpened = true;
                this.removableOrderItem.isUpSale = isUpSale;
                this.removableOrderItem.basketId = basketId;
            },
            closeConfirmModal() {
                this.isModalConfirmOpened = false;
                this.removableOrderItem = {};
            },
            remove() {
                const { isUpSale, basketId } = this.removableOrderItem;
                if (isUpSale) {
                    this.$store.orderApi.removeUpSale().then(() => {
                        let currentProduct = this.ordersList[basketId + 100500];
                        sendAnalytics.send(sendAnalytics.events.removeFromCart, {
                            name: currentProduct.name,
                            productId: currentProduct.productId,
                            price: numberFormat(currentProduct.price, 0, ".", ""),
                            quantity: currentProduct.quantity,
                        });
                        sendAnalytics.send(
                            sendAnalytics.firstStepCheckoutEvents.cartItemDelete
                        );
                        window.location.reload();
                    });
                } else {
                    this.changeQuantity({ quantity: 0, basketId });
                }
            },
            removeAll() {
                this.$emit("start-loading-sidebar");

                this.$store.orderApi.removeAllProducts().then(() => {
                    sendAnalytics.send(sendAnalytics.firstStepCheckoutEvents.cartClear);

                    this.$emit("stop-loading-sidebar");
                    window.location.href = "/";
                });
            },
        },
    };
</script>

<template>
    <div
        class="the-checkout-order the-checkout-style__box-shadow the-checkout-style__no-corner"
    >
        <button class="the-checkout-order__remove-all outline" @click="removeAll">
            Очистить <span>корзину</span>
        </button>
        <div class="the-checkout-order__list">
            <the-order-item
                v-for="order in ordersList"
                :key="order.id"
                :product="order"
                :parent-items="objectForCreatedProduct.parentItems"
                :property-items="objectForCreatedProduct.propertyItems"
                :is-deleted="removableOrderItem.basketId === order.id"
                @change-select="changeSelect"
                @remove="openConfirmModal"
            ></the-order-item>
        </div>
        <div v-show="isOverPriceState" class="the-checkout-order__notification">
            {{ textOverPrice }}
        </div>
        <the-checkout-confirm-delete
            :is-opened="isModalConfirmOpened"
            @on-close="closeConfirmModal"
            @on-confirm="remove"
        ></the-checkout-confirm-delete>
    </div>
</template>

<style lang="scss">
    .the-checkout-order {
        position: relative;
        margin: 0 -10px;
        border-radius: 0;

        @include bp($bp-desktop-sm) {
            margin: 0;
            border-radius: var(--checkout-box-radius);
            padding: 20px;
        }
    }

    .the-checkout-order__remove-all {
        position: absolute;
        top: -36px;
        right: 10px;
        font-size: 14px;
        line-height: 16px;
        color: $checkout-button-link-color;
        font-weight: $checkout-button-link-weight;
        white-space: nowrap;
        z-index: 1;
        transition: 0.3s ease-in-out opacity;

        span {
            display: none;
        }
        @include bp($bp-desktop-sm) {
            top: 9px;
            left: 20px;
            line-height: 22px;
            right: auto;

            span {
                display: inline;
            }
        }

        &:hover {
            opacity: 0.8;
        }
    }

    .the-checkout-order__list {
        padding: 0 10px;

        @include bp($bp-desktop-sm) {
            padding: 0;
            margin-top: 24px;
        }
    }

    .the-checkout-order__notification {
        margin-top: 15px;
        padding: 0 10px 15px;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: $checkout-danger-color;

        @include bp($bp-desktop-sm) {
            font-size: 14px;
            line-height: 22px;
            margin-top: 20px;
            padding: 0;
        }
    }
</style>
