<script>
    export default {
        name: "TheCheckoutDeliveryCity",
        props: {
            city: String,
        },
        methods: {
            showModal() {
                this.$popup.show("modal-select-city", { city: this.city });
            },
        },
    };
</script>

<template>
    <div class="the-checkout-delivery-city the-checkout-style__box-shadow">
        <div class="the-checkout-delivery-city__title">
            Условия доставки в населённый пункт:
        </div>
        <button
            class="the-checkout-delivery-city__city outline"
            data-vue-test="city"
            @click="showModal"
        >
            {{ city }}
        </button>
    </div>
</template>

<style lang="scss">
    .the-checkout-delivery-city {
        padding: 10px 15px;
        margin-bottom: 15px;

        @include bp($bp-desktop-sm) {
            display: flex;
            padding: 15px 20px;
            margin-bottom: 24px;
        }
    }

    .the-checkout-delivery-city__title {
        font-size: 16px;
        line-height: 26px;
        font-weight: $checkout-header-city-title-width;
        color: #1c1c28;
        display: inline;

        @include bp($bp-desktop-sm) {
            line-height: 20px;
            flex: 0 1 auto;
            flex-shrink: 0;
        }
    }

    .the-checkout-delivery-city__city {
        display: inline;
        margin-left: 3px;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        text-decoration-line: underline;
        color: $checkout-aside-accent-color;
        cursor: pointer;
        text-align: left;

        @include bp($bp-desktop-sm) {
            margin-left: 5px;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            flex: 1 2 auto;
            transition: opacity 0.2s ease-in;
            &:hover {
                opacity: 0.8;
            }
        }
    }
</style>
