<script>
    import { sendAnalytics } from "@/core/SendAnalytics";
    import TheCheckoutPaymentMethods from "@/TheCheckout/TheCheckoutPaymentMethods";

    export default {
        name: "TheCheckoutPayment",
        components: { TheCheckoutPaymentMethods },
        props: {
            city: String,
            globalDelivery: Object,
            idExpressDeliveryList: Array,
            deliveryList: Object,
            paySystem: Object,
            isOverPriceState: Boolean,
            idMadRobotsCouriersList: Array,
            currentSelectedPickup: Object,
        },
        data() {
            return {
                paymentMethodsList: [],
                currentPaySystems: this.paySystem.list,
                currentDeliveryId: "",
                textForExpressDelivery:
                    "Для всех экпресс-доставок в день заказа по Москве и Санкт-Петербургу необходима предоплата картой онлайн. Этим мы сокращаем срок доставки, используя курьерские сервисы бысттрой доставки. Мы страхуем все наши отправления и гарантируем доставку вашего заказа в самые кратчайшие возможные сроки.",
                isExpressText: false,
            };
        },
        computed: {
            isMadRobotsCourier() {
                return this.idMadRobotsCouriersList.includes(this.globalDelivery.id);
            },
            isOnlinePaymentDiscount() {
                let isExpress = this.idExpressDeliveryList.includes(this.globalDelivery.id);
                let isMadRobotsDelivery =
                    this.isMadRobotsCourier ||
                    isExpress ||
                    this.currentSelectedPickup.isMr ||
                    this.$root.$_name === "picooc";

                return !isMadRobotsDelivery;
            },
        },
        watch: {
            paySystem: {
                handler(updatedSystems) {
                    this.currentPaySystems = updatedSystems.list;
                },
                deep: true,
            },
        },
        mounted() {
            this.$eventBus.$on("checkout-call-print-pay-system", this.createMethodsPayment);
        },
        beforeDestroy() {
            this.$eventBus.$off("checkout-call-print-pay-system");
        },
        methods: {
            selectPayment(id) {
                this.$emit("set-pay-system", { activeId: parseInt(id), isPrint: false });
                this.changePaymentSystem({ activePaySystem: id, updateDelivery: true });
                sendAnalytics.send(sendAnalytics.thirdStepCheckoutEvents.orderSelectPayment);
                sendAnalytics.send(sendAnalytics.events.payment);
            },

            /**
             *  Вывод способов оплаты (бывшый printDeliveryTypes)
             *  1) вызываем когда происходит ошибка при нет данных с ПВЗ  ккурьера
             *  2) при вызове printDeliveryTypes когда вывод типов доставки, но если есть флаг isPrintPaySystems
             *  3) флаг isPrintPaySystems присваивается при
             *       - когда выбирем способ оплаты он идет в false
             *       - в фу printPaySystems идет в false
             *       - вконце фу printDeliveryTypes идет в true
             *       - при изменение города в true
             *       - по умолчанию в true
             */
            createMethodsPayment({ deliveryId, deliveryType }) {
                let currentPaymentList =
                    this.paySystem.activeListByDelivery[this.globalDelivery.service];
                this.currentDeliveryId = deliveryId;
                this.paymentMethodsList = [];

                let isExpress = this.idExpressDeliveryList.includes(deliveryId);

                if (isExpress) this.$emit("set-pay-system", { activeId: 19 });

                let paySystemsId = this.paySystem.activeId;

                currentPaymentList.sort((a, b) => (this.isMadRobotsCourier ? a - b : b - a));

                if (isExpress || deliveryType === "POST") currentPaymentList = [19];

                this.isExpressText = isExpress;

                currentPaymentList.forEach((payId) => {
                    let name = this.currentPaySystems[payId].NAME,
                        psaName = this.currentPaySystems[payId].PSA_NAME;

                    if (
                        payId === 1 &&
                        deliveryId != null &&
                        (
                            this.deliveryList[deliveryType].items[deliveryId] || {
                                hasPaymentCard: 0,
                            }
                        ).hasPaymentCard === 1
                    ) {
                        name = "При получении";
                        psaName = "Оплата наличными или банковской картой";
                    }

                    this.paymentMethodsList.push({
                        id: payId,
                        title: name,
                        desc: psaName,
                        icons: this.renderIcons(payId, deliveryId, deliveryType),
                    });
                });

                this.$emit("set-pay-system", { isPrint: false });

                this.changePaymentSystem({
                    activePaySystem: paySystemsId,
                    updateDelivery: false,
                });
            },

            changePaymentSystem({ activePaySystem, updateDelivery }) {
                this.$emit("set-pay-system", { activeId: parseInt(activePaySystem) });

                return this.$store.orderApi
                    .checkoutResult({
                        city: this.city,
                        PAY_SYSTEM_ID: activePaySystem,
                        DELIVERY_METHOD: this.globalDelivery.id || this.currentDeliveryId,
                        DELIVERY_ID: this.globalDelivery.service,
                        deliveryMethod: this.globalDelivery.id || this.currentDeliveryId,
                        deliveryService: this.globalDelivery.service,
                    })
                    .then((res) => {
                        this.currentPaySystems = res.paySystems;

                        if (updateDelivery) {
                            this.$eventBus.$emit("checkout-call-load-delivery");
                        }

                        this.$emit("update-from-result", res);
                    });
            },

            renderIcons(paySystemId, deliveryId, deliveryType) {
                const hasPaymentCardValue = (
                    (this.deliveryList[deliveryType] || { items: {} }).items[deliveryId] || {
                        hasPaymentCard: 0,
                    }
                ).hasPaymentCard;

                if (paySystemId === 1) {
                    if (!hasPaymentCardValue) {
                        return [1];
                    } else {
                        return [1, 3, 4, 2];
                    }
                } else {
                    return [3, 4, 5, 6, 2];
                }
                // 1 - cash, 2 - mir, 3 - visa, 4 - master, 5 - gPay, 6 - iPay
            },
        },
    };
</script>

<template>
    <div class="the-checkout-payment the-checkout-style__box-shadow-mobile">
        <div class="the-checkout-payment__title">Выберите способ оплаты</div>
        <the-checkout-payment-methods
            :city="city"
            :paySystem="paySystem"
            :payment-methods-list="paymentMethodsList"
            :is-over-price-state="isOverPriceState"
            :is-online-payment-discount="isOnlinePaymentDiscount"
            @select-payment="selectPayment"
        >
        </the-checkout-payment-methods>

        <div v-show="isExpressText" class="the-checkout-payment__express-text">
            {{ textForExpressDelivery }}
        </div>
    </div>
</template>

<style lang="scss">
    .the-checkout-payment {
        @include bp_max($bp-desktop-sm_max) {
            padding: 15px;
        }
    }
    .the-checkout-payment__title {
        font-size: 14px;
        line-height: 22px;
        color: #1c1c28;
        margin-bottom: 10px;
    }

    .the-checkout-payment__express-text {
        font-size: 14px;
        line-height: 22px;
        color: #e91e63;
        text-align: left;
        margin-top: 16px;
    }
</style>
