<script>
    import { mapMutations } from "vuex"; // для SPA
    import { sendAnalytics } from "@/core/SendAnalytics";
    import { scrollTo, removeVariable } from "@/utils";
    import TheCheckoutButtonPreloader from "@/TheCheckout/TheCheckoutButtonPreloader";
    import TheCheckoutNavigationMobile from "@/TheCheckout/TheCheckoutNavigationMobile";

    export default {
        name: "TheCheckoutSidebarButton",
        components: { TheCheckoutNavigationMobile, TheCheckoutButtonPreloader },
        props: {
            currentStep: Number,
            globalDelivery: Object,
            dataForSendForm: Object,
            isLoadingSidebar: Boolean,
            isContactsCompleted: Boolean,
            errorTextForSteps: String,
            isHiddenFixedNav: Boolean,
            productsText: String,
            calcTotalPrice: Number,
            bonuses: Object,
            basketItems: [Object, Array],
            deliveryInterval: Number,
        },
        data() {
            return {
                isLoadingCompleteOrder: false,
                filteredNames: {
                    0: "Перейти к оформлению",
                    1: "Перейти к способу оплаты",
                    2: "Оформить заказ",
                },
            };
        },
        computed: {
            nameSteps() {
                return this.filteredNames[this.currentStep];
            },
            isCurrentLoading() {
                return this.isLoadingSidebar || this.isLoadingCompleteOrder;
            },
        },
        methods: {
            ...mapMutations("Root", {
                resetCart: "RESET_CART", // для SPA
            }),
            goNextStep() {
                if (this.isCurrentLoading) return false;

                if (this.currentStep === 0) this.$emit("go-delivery-step");

                if (this.currentStep === 1) this.$emit("go-payment-step");

                if (this.currentStep === 2) {
                    if (this.isContactsCompleted) {
                        this.sendForm();
                        sendAnalytics.send(
                            sendAnalytics.thirdStepCheckoutEvents.orderGoToThankyou
                        );
                    } else {
                        this.$emit("set-error-step");
                    }
                }
            },
            sendForm() {
                this.isLoadingCompleteOrder = true;
                let deliveryForSend = {
                    delivery_id: this.globalDelivery.service,
                    delivery_type: this.globalDelivery.type,
                    delivery_method: this.globalDelivery.id,
                    delivery_interval: this.deliveryInterval,
                };

                const other = {
                    confirmorder: "Y",
                    AJAX_CALL: "Y",
                    json: "Y",
                    callme: 1,
                    profile_change: "N",
                };

                this.$store.orderApi
                    .checkoutResult({
                        ...this.dataForSendForm,
                        ...deliveryForSend,
                        ...other,
                    })
                    .then((data) => {
                        this.successOrderForm(data);
                    });
            },
            successOrderForm({
                success,
                paySystemId,
                orderId,
                userEmail,
                sitePhone,
                paramsForPay,
                errors,
                orderPriceForSend,
                deliveryPrice,
            }) {
                if (Array.isArray(errors) && Object.keys(errors).length) {
                    alert(errors[0]);
                    this.isLoadingCompleteOrder = false;
                    return false;
                }

                if (Object.keys(errors).length) {
                    this.$emit("set-errors-from-backend", errors);
                    this.isLoadingCompleteOrder = false;

                    setTimeout(() => {
                        let elsError = document.querySelectorAll(".the-base-input--error");
                        let bodyRect = document.body.getBoundingClientRect();
                        if (elsError.length) {
                            let elemRect = elsError[0].getBoundingClientRect();
                            let offset = elemRect.top - bodyRect.top - 120;
                            scrollTo(offset, 500);
                        }
                    }, 300);
                    return false;
                }

                if (success === "N") {
                    this.isLoadingCompleteOrder = false;
                    return false;
                }

                this.resetLocalStorage();
                let isOnlinePayment = paySystemId > 1;

                if (isOnlinePayment) {
                    this.createCloudPaymentsAgent({
                        paramsForPay,
                        orderId,
                        userEmail,
                        sitePhone,
                    });
                } else {
                    this.isLoadingCompleteOrder = false;
                    this.setStatusAfterLastStep({
                        status: "successOffline",
                        orderId,
                        userEmail,
                        sitePhone,
                    });
                }

                try {
                    sendAnalytics.send(sendAnalytics.events.orderSuccess, {
                        orderId: orderId,
                        orderPrice: orderPriceForSend,
                        deliveryPrice: deliveryPrice,
                        coupons: this.bonuses.coupon.name,
                        basketItems: this.basketItems,
                    });
                } catch (e) {
                    console.error(e);
                }

                let idListForFB = [];

                for (let key in this.basketItems) {
                    let basketItem = this.basketItems[key];
                    idListForFB.push(basketItem.productId);
                }

                try {
                    if (typeof window.fbq !== "undefined") {
                        window.fbq("track", "Purchase", {
                            content_ids: idListForFB, //"ids"
                            content_type: "product",
                            value: parseInt(orderPriceForSend),
                            currency: "RUB",
                        });
                    }
                } catch (e) {
                    console.log(e);
                }
            },
            createCloudPaymentsAgent({ paramsForPay, orderId, userEmail, sitePhone }) {
                let script = document.createElement("script");
                script.async = true;
                script.defer = true;
                script.src = "https://widget.cloudpayments.ru/bundles/cloudpayments";
                document.getElementsByTagName("head")[0].appendChild(script);

                script.onload = () => {
                    setTimeout(() => {
                        this.isLoadingCompleteOrder = false;
                    }, 3000);

                    window._startPayMadRobots = () => {
                        let widget = new window.cp.CloudPayments({ language: "ru-RU" });
                        widget.pay(
                            "charge",
                            {
                                publicId: paramsForPay.publicId, //id из личного кабинета
                                description: paramsForPay.description, //назначение
                                amount: Number(paramsForPay.amount), //сумма
                                currency: paramsForPay.currency, //валюта
                                accountId: paramsForPay.accountId, //идентификатор плательщика (необязательно)
                                invoiceId: paramsForPay.invoiceId, //номер заказа  (необязательно)
                            },
                            {
                                onSuccess: () => {
                                    this.setStatusAfterLastStep({
                                        status: "successOnline",
                                        orderId,
                                        userEmail,
                                        sitePhone,
                                    });
                                },
                                onFail: () => {
                                    this.setStatusAfterLastStep({
                                        status: "fail",
                                        orderId,
                                        userEmail,
                                        sitePhone,
                                    });
                                },
                            }
                        );
                    };
                    window._startPayMadRobots();
                };
            },
            setStatusAfterLastStep({ status, orderId, userEmail, sitePhone }) {
                this.$emit("set-status-order", { status, orderId, userEmail, sitePhone });
                this.$emit("go-next-step");
                this.resetCart(); // для SPA
            },
            resetLocalStorage() {
                let localStorageVariableList = [
                    "MadRobots_Step",
                    "MadRobots_name",
                    "MadRobots_last_name",
                    "MadRobots_second_name",
                    "MadRobots_phone",
                    "MadRobots_email",
                    "MadRobots_street",
                    "MadRobots_house",
                    "MadRobots_build",
                    "MadRobots_housing",
                    "MadRobots_flat",
                    "MadRobots_Main_Delivery_ID",
                    "MadRobots_Main_Delivery_Type",
                    "MadRobots_Main_Delivery_Service",
                    "MadRobots_Pickup_Address",
                    "MadRobots_Pickup_Price",
                    "MadRobots_Pickup_Mr",
                    "MadRobots_Packing",
                ];
                localStorageVariableList.forEach((variable) => {
                    removeVariable(variable);
                });
            },
        },
    };
</script>

<template>
    <div class="the-checkout-sidebar__button">
        <button
            class="the-checkout-sidebar__button-control outline"
            :class="{ 'is-animated': isCurrentLoading }"
            @click="goNextStep"
            :disabled="isCurrentLoading"
        >
            {{ nameSteps }}
            <the-checkout-button-preloader
                v-show="isCurrentLoading"
            ></the-checkout-button-preloader>
        </button>

        <div v-show="currentStep === 2" class="the-checkout-sidebar__button-personal-data">
            Нажимая на кнопку, вы даете согласие на
            <a :href="$root.$_documents.personalData" target="_blank"
                >обработку своих персональных данных</a
            >
        </div>
        <div v-show="errorTextForSteps" class="the-checkout-sidebar__button-errors">
            {{ errorTextForSteps }}
        </div>

        <the-checkout-navigation-mobile
            :class="{ 'is-hidden': isHiddenFixedNav }"
            :current-step="currentStep"
            :products-text="productsText"
            :calc-total-price="calcTotalPrice"
            :is-loading="isCurrentLoading"
            @next-step-from-mobile="goNextStep"
        ></the-checkout-navigation-mobile>
    </div>
</template>

<style lang="scss">
    .the-checkout-sidebar__button {
    }

    .the-checkout-sidebar__button-control {
        position: relative;
        display: block;
        width: 100%;
        padding: 10px;
        color: $checkout-button-text-color;
        background-color: $checkout-button-color;
        border-radius: $checkout-button-radius--large;
        font-weight: $checkout-button-text-weight;
        font-size: $checkout-button-text-size--mobile;
        line-height: 20px;
        text-align: center;
        transition: 0.3s ease-in-out all;

        @include bp($bp-desktop-sm) {
            padding: 16px;
            font-size: $checkout-button-text-size;
        }

        &:not(:disabled):hover {
            background-color: $checkout-button-color--hover;
        }

        &.is-animated {
            color: transparent;
        }
    }

    .the-checkout-sidebar__button-personal-data {
        margin-top: 10px;
        font-weight: 300;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: $checkout-base-text-color;

        @include bp($bp-desktop-sm) {
            margin-bottom: -9px;
        }

        a {
            color: $checkout-active-color;
            @include bp($bp-desktop-sm) {
                transition: color 0.3s ease-in-out;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .the-checkout-sidebar__button-errors {
        margin-top: 14px;
        margin-bottom: -14px;
        font-size: 14px;
        line-height: 16px;
        color: $checkout-danger-color;
    }
</style>
