<script>
    import { apiUrl } from "@/utils";
    import TheOrderItemControls from "@/TheCheckout/TheOrderItemControls";
    import TheOrderItemPrice from "@/TheCheckout/TheOrderItemPrice";

    export default {
        name: "TheOrderItem",
        components: { TheOrderItemPrice, TheOrderItemControls },
        props: {
            product: Object,
            parentItems: Object,
            propertyItems: Object,
            isUpSale: Boolean,
            isDeleted: Boolean,
        },
        computed: {
            currentProperty() {
                return this.propertyItems[this.product.productId];
            },
            currentParent() {
                return this.parentItems[this.product.parentId];
            },
        },
        methods: {
            apiUrl,
            changeSelect(productObj) {
                this.$emit("change-select", productObj);
            },
            remove() {
                this.$emit("remove", {
                    isUpSale: this.product.isUpSale || false,
                    basketId: this.product.id,
                });
            },
        },
    };
</script>

<template>
    <div class="the-order-item" :class="{ 'the-order-item--deleted': isDeleted }">
        <a :href="product.url" class="the-order-item__picture" tabindex="-1">
            <img
                :src="apiUrl(parentItems[product.parentId].images[product.productId])"
                class="the-order-item__picture-img"
                alt=""
            />
        </a>

        <div class="the-order-item__wrapper">
            <the-order-item-price :product="product" is-mobile></the-order-item-price>

            <a :href="product.url" class="the-order-item__name">
                {{ product.name }}
            </a>

            <div class="the-order-item__controls">
                <the-order-item-controls
                    :product="product"
                    :parent-items="parentItems"
                    :current-property="currentProperty"
                    :current-parent="currentParent"
                    @change-select="changeSelect"
                ></the-order-item-controls>
            </div>
        </div>

        <div class="the-order-item__controls the-order-item__controls--mobile">
            <the-order-item-controls
                is-mobile
                :product="product"
                :parent-items="parentItems"
                :current-property="currentProperty"
                :current-parent="currentParent"
                @change-select="changeSelect"
            ></the-order-item-controls>
        </div>

        <the-order-item-price :product="product"></the-order-item-price>

        <button class="the-order-item__delete outline" @click="remove">
            <svg
                class="the-order-item__delete-svg"
                width="24"
                height="24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6 6a1 1 0 011 1v11c0 .547.453 1 1 1h8c.547 0 1-.453 1-1V7a1 1 0 112 0v11c0 1.652-1.348 3-3 3H8c-1.652 0-3-1.348-3-3V7a1 1 0 011-1z"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9 9a1 1 0 011 1v7a1 1 0 11-2 0v-7a1 1 0 011-1zM12 9a1 1 0 011 1v7a1 1 0 11-2 0v-7a1 1 0 011-1zM15 9a1 1 0 011 1v7a1 1 0 11-2 0v-7a1 1 0 011-1zM20 8H4V6h16v2z"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9 4a1 1 0 011-1h4a1 1 0 011 1v2a1 1 0 11-2 0V5h-2v1a1 1 0 11-2 0V4z"
                />
            </svg>
        </button>
    </div>
</template>

<style lang="scss">
    .the-order-item {
        position: relative;
        //z-index: 2;
        display: flex;
        align-items: center;
        padding: 10px 0 50px;

        @include bp($bp-tablet-sm) {
            padding: 10px 0;
        }
        &:not(:first-child) {
            border-color: $checkout-common-border;
            border-width: 1px 0 0 0;
            border-style: solid;
            border-image: $checkout-border-gradient;
            border-image-slice: 1;
        }
    }

    .the-order-item__picture {
        display: block;
        outline: none;

        @include bp($bp-tablet-sm) {
            position: relative;
            width: 170px;
            height: 155px;
            margin-right: 18px;
        }
    }

    .the-order-item__picture-img {
        @include bp($bp-tablet-sm) {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .the-order-item__wrapper {
        position: relative;
        min-width: 190px;
        display: flex;
        align-items: flex-start;
        flex: 1;
        flex-direction: column;
        padding-left: 10px;

        @include bp($bp-tablet-sm) {
            padding-left: 0;
        }

        @include bp($bp-desktop-md_2) {
            flex: auto;
            flex-direction: row;
            align-items: center;
        }
    }

    .the-order-item__name {
        display: block;
        padding-right: 10px;
        font-size: 14px;
        font-weight: $checkout-order-item-text-weight;
        line-height: 16px;
        color: $checkout-base-text-color;

        @include bp($bp-tablet-sm) {
            width: auto;
            max-width: 223px;
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 10px;
        }

        @include bp($bp-desktop-md_2) {
            margin-bottom: 0;
        }
    }

    .the-order-item__controls {
        display: none;

        @include bp($bp-tablet-sm) {
            display: block;
        }

        @include bp($bp-desktop-md_2) {
            margin-left: 18px;
        }
    }

    .the-order-item__delete {
        position: absolute;
        top: 7px;
        right: -4px;
        @include box(24px);
        cursor: pointer;
        //z-index: 1;
        flex-shrink: 0;

        @include bp($bp-tablet-sm) {
            position: relative;
            top: 0;
            right: 0;
            @include box(40px);
            background: #f2f2f5;
            border-radius: 50px;
        }

        &:hover {
            .the-order-item__delete-svg {
                fill: #e91e63;
            }
        }
    }

    .the-order-item__delete-svg {
        @include center-absolute(0);
        margin: auto;
        fill: #c7c9d9;
        transition: 0.3s ease-in-out all;
    }

    //
    // --mod
    //

    .the-order-item--deleted {
        opacity: 0.3;
        pointer-events: none;
    }

    .the-order-item__controls--mobile {
        display: block;
        width: 100%;
        position: absolute;
        bottom: 10px;
        left: 0;
        //z-index: 1;

        @include bp($bp-tablet-sm) {
            display: none;
        }
    }
</style>
