<script>
    import { declineOfNumber, numberFormat, isMobile, stickyScroll, throttle } from "@/utils";
    import TheCheckoutBonuses from "@/TheCheckout/TheCheckoutBonuses";
    import TheCheckoutSidebarButton from "@/TheCheckout/TheCheckoutSidebarButton";

    export default {
        name: "TheCheckoutSidebar",
        components: {
            TheCheckoutSidebarButton,
            TheCheckoutBonuses,
        },
        props: {
            basketItems: [Object, Array],
            basketPrice: Number,
            currentStep: Number,
            isAddressCompleted: String,
            isContactsCompleted: Boolean,
            globalDelivery: Object,
            isLoadingSidebar: Boolean,
            dataForSendForm: Object,
            orderDiscount: Number,
            packing: Object,
            currentSelectedPickup: Object,
            currentPriceDelivery: Number,
            bonuses: Object,
            isMadRobots: Boolean,
            isErrorPostDelivery: Boolean,
            freeDeliveryCount: Number,
            orderFullPrice: Number,
            discountsData: Array,
            upSaleCart: Object,
            isAuthorized: Boolean,
            deliveryInterval: Number,
        },
        data() {
            return {
                isActiveDropDown: false,
                dropdownOrders: false,
                dropdownSales: false,
                isHiddenFixedNav: false,
                errorTextForSteps: "",
                stickyScrollWithThrottle: throttle(this.stickyImageScroll, 35),
                textForAdditional:
                    "Наш оператор позвонит вам в&nbsp;течение 30&nbsp;минут после оформления заказа. Рабочее время: с&nbsp;9:00 до&nbsp;21:00 по&nbsp;МСК",
                packingPrice: 0,
            };
        },
        computed: {
            countOrders() {
                return Object.keys(this.basketItems)
                    .map((key) => this.basketItems[key].quantity)
                    .reduce((p, c) => p + c, 0);
            },
            productsText() {
                return `${this.countOrders} ${declineOfNumber(this.countOrders, [
                    " товар",
                    " товара",
                    " товаров",
                ])}`;
            },
            untilFreeDelivery() {
                return this.freeDeliveryCount - this.basketPrice;
            },
            stateFreeDelivery() {
                return this.untilFreeDelivery > 0;
            },
            freeDeliveryDisabled() {
                return this.freeDeliveryCount < 100000;
            },
            selectTextForDelivery() {
                let filteredNames = {
                    0: "Стоимость без учета <br/> доставки",
                    1: "К оплате",
                };

                if (this.stateFreeDelivery && this.currentStep === 0) {
                    return filteredNames[0];
                }

                return filteredNames[1];
            },
            showAddressForPickup() {
                return (
                    this.globalDelivery.type === "PICKUP" && this.currentSelectedPickup.address
                );
            },
            packingItemsCount() {
                return this.packing.productWrapPositions.reduce((acc, curr) => {
                    return acc + curr.quantity;
                }, 0);
            },
            calcTotalPrice() {
                let optionPrice = this.currentStep > 0 ? this.currentPriceDelivery : 0;

                optionPrice += this.packingItemsCount > 0 ? parseInt(this.packing.price) : 0;

                return this.basketPrice + optionPrice;
            },
            computedPriceForDelivery() {
                return this.stateFreeDelivery &&
                    this.currentStep > 0 &&
                    this.currentPriceDelivery
                    ? this.currentPriceDelivery + " ₽"
                    : "Бесплатно";
            },
            discountsList() {
                let gift = 0;
                let catalog = 0;
                let online = 0;
                this.discountsData.forEach(
                    ({ result, isCatalogDiscount, isCouponDiscount, isGift }) => {
                        let updatedResult = Math.round(result * 1e2) / 1e2;
                        if (isCatalogDiscount) {
                            catalog += updatedResult;
                        }
                        if (isGift) {
                            gift = updatedResult;
                        }
                        if (!isCatalogDiscount && !isCouponDiscount && !isGift) {
                            online = updatedResult;
                        }
                    }
                );
                let upSaleDiscount = this.upSaleCart
                    ? this.upSaleCart.discountPrice * this.upSaleCart.quantity
                    : 0;
                return [
                    {
                        name: "Акция",
                        price: catalog,
                    },
                    {
                        name: "Подарок",
                        price: gift,
                    },
                    {
                        name: "Оплата онлайн",
                        price: online,
                    },
                    {
                        name: "Специальное предложение",
                        price: upSaleDiscount,
                    },
                ];
            },
        },
        watch: {
            bonuses: {
                handler(updated) {
                    if (updated.coupon.discountPercent > 0) {
                        this.isActiveDropDown = true;
                    }
                },
                deep: true,
            },
        },
        mounted() {
            window.addEventListener("scroll", this.stickyScrollWithThrottle);
            window.addEventListener("resize", this.windowResizeHandler);
            this.packingPrice = this.packing.price;
        },
        beforeDestroy() {
            window.removeEventListener("scroll", this.stickyScrollWithThrottle);
            window.removeEventListener("resize", this.windowResizeHandler);
        },
        methods: {
            goDeliveryStep() {
                this.$emit("go-next-step");
            },
            goPaymentStep() {
                let type = this.globalDelivery.type;
                let filteredErrors = {
                    COURIER: "Укажите адрес доставки",
                    POST: "Укажите адрес доставки",
                    PICKUP: "Выберите пункт самовывоза",
                };

                if (type === "PICKUP" && this.currentSelectedPickup.address) {
                    this.$emit("go-next-step");
                } else if (
                    (type === "COURIER" || type === "POST") &&
                    this.isAddressCompleted &&
                    !this.isErrorPostDelivery
                ) {
                    this.$emit("go-next-step");
                } else {
                    this.$emit("set-error-step", type);
                    this.errorTextForSteps = filteredErrors[type];

                    setTimeout(() => {
                        this.errorTextForSteps = "";
                    }, 2000);
                }
            },
            setErrorStep() {
                this.$emit("set-error-step", "CONTACTS");
            },
            setStatusOrder(values) {
                this.$emit("set-status-order", values);
            },
            updateCheckoutOrder(res) {
                this.$emit("update-checkout-order", res);
            },
            showOrders() {
                this.dropdownOrders = !this.dropdownOrders;
            },
            showSales() {
                this.dropdownSales = !this.dropdownSales;
            },
            setErrorsFromBackend(errors) {
                this.$emit("set-errors-from-backend", errors);
            },
            stickyImageScroll() {
                if (!isMobile(992)) {
                    const el = document.querySelector(".js-scroll-sidebar");
                    const elWrap = document.querySelector(".js-scroll-sidebar-wrapper");
                    if (el && elWrap) {
                        stickyScroll({
                            el,
                            elWrap,
                            topMargin: 80,
                        });
                    }
                } else {
                    const sidebarButton = this.$refs["sidebar-button"].$el;
                    const trigger = sidebarButton.getBoundingClientRect().top - 10;
                    this.isHiddenFixedNav = trigger < window.innerHeight && trigger > 0;
                }
            },
            windowResizeHandler() {
                if (isMobile(992)) {
                    const el = document.querySelector(".js-scroll-sidebar");
                    const elWrap = document.querySelector(".js-scroll-sidebar-wrapper");

                    el.style.height = "auto";
                    elWrap.style.top = "auto";
                    elWrap.style.width = "auto";
                }
            },
            isMobile,
            numberFormat,
            startTransition(el) {
                el.style.height = el.scrollHeight + "px";
            },
            endTransition(el) {
                el.style.height = "";
            },
        },
    };
</script>

<template>
    <div
        class="the-checkout-sidebar js-scroll-sidebar"
        :class="{
            'the-checkout-sidebar--padding': currentStep === 2,
        }"
    >
        <div
            class="
                the-checkout-sidebar__wrapper
                the-checkout-style__box-shadow
                js-scroll-sidebar-wrapper
            "
        >
            <div v-if="currentStep === 2" class="the-checkout-sidebar__additional">
                <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="3" y="3" width="34" height="34" rx="10" fill="#52C41A" />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M28.3 10.292a.996.996 0 111.4 1.42l-6.29 6.285H27c.55 0 1 .45 1 1s-.45 1-1 1h-6a1.018 1.018 0 01-1-1V13c0-.55.45-1 1-1s1 .45 1 1v3.587l6.3-6.296zm-4.26 12.542l2.15 2.15c.21.209.37.449.48.729.11.27.17.56.17.85 0 .289-.06.579-.17.849-.11.27-.27.51-.48.72l-.51.509A4.699 4.699 0 0122.4 30a4.643 4.643 0 01-2.516-.74c-.005-.005-.01-.01-.015-.01a33.812 33.812 0 01-9.12-9.114 4.643 4.643 0 01-.72-3.038c.11-1.05.59-2.039 1.33-2.778l.52-.51c.83-.83 2.3-.83 3.13 0l2.15 2.149c.21.21.37.45.48.72.11.27.17.559.17.849 0 .29-.06.57-.17.85-.11.27-.27.509-.48.719-.04.04-.06.09-.06.15s.02.11.06.15l3.43 3.428c.08.08.23.08.31 0 .21-.21.45-.37.72-.48.56-.22 1.16-.22 1.7 0 .27.12.51.28.72.49zm.23 4.378l.51-.51.05-.15-.02-.09-.04-.06-2.15-2.148-.15-.07-.15.07c-.84.83-2.29.83-3.13 0l-3.44-3.438a2.19 2.19 0 01-.65-1.57c0-.599.23-1.149.65-1.568l.06-.16-.02-.1-2.2-2.199a.204.204 0 00-.15-.06.22.22 0 00-.15.06l-.51.51c-.42.42-.69.98-.76 1.579-.06.6.08 1.2.4 1.709a31.72 31.72 0 008.57 8.555c.004.005.012.01.02.015l.02.015.01.01a2.65 2.65 0 003.23-.4z"
                        fill="#fff"
                    />
                </svg>
                <span v-html="textForAdditional"></span>
            </div>

            <div class="the-checkout-sidebar__header">
                <the-checkout-sidebar-button
                    :bonuses="bonuses"
                    :basket-items="basketItems"
                    :current-step="currentStep"
                    :global-delivery="globalDelivery"
                    :products-text="productsText"
                    :calc-total-price="calcTotalPrice"
                    :data-for-send-form="dataForSendForm"
                    :error-text-for-steps="errorTextForSteps"
                    :is-loading-sidebar="isLoadingSidebar"
                    :is-contacts-completed="isContactsCompleted"
                    :is-hidden-fixed-nav="isHiddenFixedNav"
                    :delivery-interval="deliveryInterval"
                    @go-delivery-step="goDeliveryStep"
                    @go-payment-step="goPaymentStep"
                    @set-error-step="setErrorStep"
                    @go-next-step="$emit('go-next-step')"
                    @set-status-order="setStatusOrder"
                    @set-errors-from-backend="setErrorsFromBackend"
                    ref="sidebar-button"
                ></the-checkout-sidebar-button>
            </div>

            <div
                class="the-checkout-sidebar__body"
                :class="{ 'is-animated': isLoadingSidebar }"
            >
                <div class="the-checkout-sidebar__title">Ваша корзина</div>

                <div class="the-checkout-sidebar__list">
                    <!--ТОВАРЫ-->
                    <div
                        class="the-checkout-sidebar-order outline"
                        :class="{ 'the-checkout-sidebar-order--is-active': dropdownOrders }"
                        tabindex="0"
                        @click="showOrders"
                        @keypress.enter="showOrders"
                    >
                        <div class="the-checkout-sidebar-order__accent">
                            <div class="the-checkout-sidebar-order__text">
                                <span class="the-checkout-sidebar-order__text-inner">
                                    {{ productsText }} на сумму
                                </span>
                            </div>
                            <svg
                                width="12"
                                height="12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M1.256 4.16c.244-.33.659-.265 1.007 0L6 7l3.737-2.84c.349-.265.788-.215.947 0 .183.248.165.575-.184.84L6 8.5 1.5 5c-.349-.265-.405-.622-.244-.84z"
                                    fill="currentColor"
                                />
                            </svg>
                        </div>
                        <div class="the-checkout-sidebar-order__total">
                            {{ orderFullPrice | formatPrice }} ₽
                        </div>
                    </div>

                    <transition
                        name="slide-down"
                        @enter="startTransition"
                        @after-enter="endTransition"
                        @before-leave="startTransition"
                        @after-leave="endTransition"
                    >
                        <div v-show="dropdownOrders" class="transition-container">
                            <div class="the-checkout-sidebar__dropdown">
                                <div
                                    v-for="order in basketItems"
                                    :key="order.id"
                                    class="the-checkout-sidebar-order"
                                >
                                    <div class="the-checkout-sidebar-order__text">
                                        <span class="the-checkout-sidebar-order__text-inner">
                                            {{ order.name }}
                                        </span>
                                    </div>

                                    <div class="the-checkout-sidebar-order__total">
                                        {{ numberFormat(order.basePrice, 0, ".", " ") }} ₽
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition>

                    <!--Скидка-->
                    <div
                        v-show="orderDiscount > 0"
                        class="the-checkout-sidebar-order outline"
                        :class="{ 'the-checkout-sidebar-order--is-active': dropdownSales }"
                        tabindex="0"
                        @click="showSales"
                        @keypress.enter="showSales"
                    >
                        <div class="the-checkout-sidebar-order__accent">
                            <div class="the-checkout-sidebar-order__text">
                                <span class="the-checkout-sidebar-order__text-inner">
                                    Скидка
                                </span>
                            </div>
                            <svg
                                width="12"
                                height="12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M1.256 4.16c.244-.33.659-.265 1.007 0L6 7l3.737-2.84c.349-.265.788-.215.947 0 .183.248.165.575-.184.84L6 8.5 1.5 5c-.349-.265-.405-.622-.244-.84z"
                                    fill="currentColor"
                                />
                            </svg>
                        </div>
                        <div class="the-checkout-sidebar-order__total">
                            -{{ Math.ceil(orderDiscount) }} ₽
                        </div>
                    </div>
                    <transition
                        name="slide-down"
                        @enter="startTransition"
                        @after-enter="endTransition"
                        @before-leave="startTransition"
                        @after-leave="endTransition"
                    >
                        <div v-show="dropdownSales" class="transition-container">
                            <div class="the-checkout-sidebar__dropdown">
                                <!--Скидка купон-->
                                <div
                                    v-show="bonuses.coupon.name"
                                    class="the-checkout-sidebar-order"
                                >
                                    <div class="the-checkout-sidebar-order__text">
                                        <span class="the-checkout-sidebar-order__text-inner">
                                            Применен купон <br />
                                            {{ bonuses.coupon.name }}
                                        </span>
                                    </div>

                                    <div
                                        class="
                                            the-checkout-sidebar-order__total
                                            the-checkout-sidebar-order__total--discount
                                        "
                                    >
                                        <span
                                            >-{{ bonuses.coupon.discountSum.toFixed(2) }}</span
                                        >
                                        ₽
                                    </div>
                                </div>
                                <!--Скидка сертификат-->
                                <div
                                    v-if="bonuses.certificate.name"
                                    class="the-checkout-sidebar-order"
                                >
                                    <div class="the-checkout-sidebar-order__text">
                                        <span class="the-checkout-sidebar-order__text-inner">
                                            Применен сертификат <br />
                                            {{ bonuses.certificate.name }}
                                        </span>
                                    </div>

                                    <div
                                        class="
                                            the-checkout-sidebar-order__total
                                            the-checkout-sidebar-order__total--discount
                                        "
                                    >
                                        <span>-{{ bonuses.certificate.discountSum }}</span> ₽
                                    </div>
                                </div>
                                <!--Скидка мэдкоин-->
                                <div
                                    v-if="bonuses.madCoins.isApply"
                                    class="the-checkout-sidebar-order"
                                >
                                    <div class="the-checkout-sidebar-order__text">
                                        <span class="the-checkout-sidebar-order__text-inner">
                                            Использовано <br />
                                            Мэдкоины
                                        </span>
                                    </div>

                                    <div
                                        class="
                                            the-checkout-sidebar-order__total
                                            the-checkout-sidebar-order__total--discount
                                        "
                                    >
                                        <span>-{{ bonuses.madCoins.discountSum }}</span> ₽
                                    </div>
                                </div>
                                <!--Скидка другие-->
                                <div
                                    v-show="Number(item.price) > 0"
                                    class="the-checkout-sidebar-order"
                                    v-for="(item, i) in discountsList"
                                    :key="i"
                                >
                                    <div class="the-checkout-sidebar-order__text">
                                        <span class="the-checkout-sidebar-order__text-inner">
                                            {{ item.name }}
                                        </span>
                                    </div>

                                    <div
                                        class="
                                            the-checkout-sidebar-order__total
                                            the-checkout-sidebar-order__total--discount
                                        "
                                    >
                                        <span>-{{ item.price }}</span> ₽
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition>

                    <!--Доставка Платная и Бесплатная-->
                    <div
                        v-show="
                            (stateFreeDelivery &&
                                currentStep > 0 &&
                                globalDelivery.type !== 'PICKUP') ||
                            (currentStep > 0 && showAddressForPickup) ||
                            !stateFreeDelivery
                        "
                        class="the-checkout-sidebar-order the-checkout-sidebar-order--delivery"
                    >
                        <div class="the-checkout-sidebar-order__text">
                            <span class="the-checkout-sidebar-order__text-inner">
                                Доставка
                            </span>
                        </div>

                        <div class="the-checkout-sidebar-order__total">
                            {{ computedPriceForDelivery }}
                        </div>
                    </div>

                    <!--Упаковка-->
                    <div
                        v-if="packingItemsCount > 0 && currentStep > 0"
                        class="the-checkout-sidebar-order"
                    >
                        <div class="the-checkout-sidebar-order__text">
                            <span class="the-checkout-sidebar-order__text-inner">
                                Упаковка {{ packingItemsCount }} шт.
                            </span>
                        </div>

                        <div class="the-checkout-sidebar-order__total">
                            {{ packing.price }} ₽
                        </div>
                    </div>
                </div>

                <div
                    v-show="
                        showAddressForPickup &&
                        currentStep > 0 &&
                        globalDelivery.type === 'PICKUP'
                    "
                    class="the-checkout-sidebar__pickup"
                >
                    <div class="the-checkout-sidebar__title">Где забрать</div>
                    <div class="the-checkout-sidebar__pickup-text">
                        {{ currentSelectedPickup.address }}
                    </div>
                </div>

                <div
                    v-show="
                        isAddressCompleted &&
                        currentStep > 0 &&
                        (globalDelivery.type === 'COURIER' || globalDelivery.type === 'POST')
                    "
                    class="the-checkout-sidebar__pickup"
                >
                    <div class="the-checkout-sidebar__title">Доставка</div>
                    <div class="the-checkout-sidebar__pickup-text">
                        {{ isAddressCompleted }}
                    </div>
                </div>

                <div class="the-checkout-sidebar-order the-checkout-sidebar-order--footer">
                    <div class="the-checkout-sidebar-order__text">
                        <span
                            class="the-checkout-sidebar-order__text-inner"
                            v-html="selectTextForDelivery"
                        >
                        </span>
                    </div>

                    <div class="the-checkout-sidebar-order__total">
                        {{ Math.floor(calcTotalPrice) | formatPrice }} ₽
                    </div>
                </div>
                <div
                    v-show="stateFreeDelivery && freeDeliveryDisabled"
                    class="the-checkout-sidebar__delivery"
                >
                    До бесплатной доставки {{ untilFreeDelivery }} ₽
                    <div class="the-checkout-tooltip the-checkout-sidebar__tooltip">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M21.5 11c0 5.799-4.701 10.5-10.5 10.5S.5 16.799.5 11 5.201.5 11 .5 21.5 5.201 21.5 11z"
                                stroke="#8F90A6"
                            />
                            <path
                                d="M9.846 13.298c0-.752.088-1.352.264-1.798.177-.446.498-.884.964-1.315.471-.435.784-.787.94-1.056a1.73 1.73 0 00.233-.863c0-.908-.404-1.363-1.212-1.363-.383 0-.691.124-.925.371-.227.242-.347.578-.357 1.008H7.5c.01-1.027.329-1.83.956-2.411C9.088 5.291 9.947 5 11.035 5c1.098 0 1.95.277 2.556.83.606.549.909 1.326.909 2.331 0 .457-.098.89-.295 1.299-.197.403-.541.852-1.034 1.346l-.629.621a2.242 2.242 0 00-.676 1.38l-.03.491h-1.99zm-.225 2.476c0-.36.117-.656.35-.887.238-.236.54-.355.909-.355.367 0 .668.119.9.355.239.231.358.527.358.887 0 .355-.116.648-.35.88-.227.23-.53.346-.908.346-.379 0-.684-.116-.917-.347-.228-.231-.342-.524-.342-.879z"
                                fill="#8F90A6"
                            />
                        </svg>
                        <div
                            class="
                                the-checkout-tooltip__text
                                the-checkout-sidebar__tooltip-text
                            "
                        >
                            Стоимость доставки не учитывается
                        </div>
                    </div>
                </div>
            </div>

            <div v-show="currentStep > 0 && !isMobile()" class="the-checkout-sidebar__footer">
                <the-checkout-bonuses
                    is-sidebar
                    :bonuses="bonuses"
                    :is-mad-robots="isMadRobots"
                    :is-active="isActiveDropDown"
                    :current-step="currentStep"
                    :is-authorized="isAuthorized"
                    :product-wrap-positions="packing.productWrapPositions"
                    @update-checkout-order="updateCheckoutOrder"
                >
                </the-checkout-bonuses>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .the-checkout-sidebar {
        margin-top: 24px;

        @include bp($bp-desktop-sm) {
            margin-top: 0;
            position: absolute;
            top: 60px;
            right: 16px;
            width: 300px;
        }

        @include bp($bp-desktop-md) {
            width: 320px;
        }
    }
    .the-checkout-sidebar--padding {
        padding-bottom: 60px;
        @include bp($bp-desktop-sm) {
            padding-bottom: 0;
        }
    }

    .the-checkout-sidebar__wrapper {
        display: flex;
        flex-direction: column-reverse;
        padding: 15px;

        @include bp($bp-desktop-sm) {
            display: block;
            padding: 24px 20px;
            &.fix-scroll-block {
                position: fixed;
                z-index: 10;
            }
        }
    }

    .the-checkout-sidebar__header {
        margin-top: 24px;

        @include bp($bp-desktop-sm) {
            margin-top: 0;
            border-color: $checkout-common-border;
            border-width: 0 0 1px 0;
            border-style: solid;
            border-image: $checkout-border-gradient;
            border-image-slice: 1;
            padding-bottom: 24px;
            margin-bottom: 16px;
        }
    }

    .the-checkout-sidebar__body {
        transition: 0.3s ease-in-out all;

        &.is-animated {
            opacity: 0.15;

            .the-checkout-sidebar-order__total--discount {
                span {
                    opacity: 0;
                }
            }
        }
    }

    .the-checkout-sidebar__title {
        font-size: 16px;
        font-weight: $checkout-aside-title-weight;
        line-height: 20px;
        color: $checkout-base-text-color;
        margin-bottom: 10px;
    }

    .the-checkout-sidebar__pickup {
        margin-top: 20px;
    }

    .the-checkout-sidebar__pickup-text {
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        color: $checkout-base-text-color;
    }

    .the-checkout-sidebar__list {
        .the-checkout-sidebar-order {
            margin-top: 10px;
        }
    }

    .the-checkout-sidebar__dropdown {
        padding-left: 10px;
        padding-bottom: 10px;
    }

    .the-checkout-sidebar-order {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        font-weight: $checkout-aside-item-weight;
        font-size: 16px;
        line-height: 18px;
        color: $checkout-base-text-color;

        &:after {
            content: "";
            position: absolute;
            bottom: 4px;
            left: 0;
            right: 0;
            width: 100%;
            height: 1px;
            background-color: #ebebf0;
        }
    }

    .the-checkout-sidebar-order__accent {
        position: relative;
        background-color: #fff;
        display: flex;
        align-items: center;
        color: $checkout-aside-accent-color;
        padding-right: 5px;
        z-index: 1;
        cursor: pointer;
        user-select: none;
        @include bp($bp-desktop-sm) {
            transition: opacity 0.2s ease-in;
            &:hover {
                opacity: 0.8;
            }
        }

        svg {
            margin-left: 3px;
            fill: currentColor;
            transition: transform 0.3s ease;
        }
    }

    .the-checkout-sidebar-order__text {
        max-width: 198px;
    }

    .the-checkout-sidebar-order__text-inner {
        position: relative;
        background-color: #fff;
        z-index: 1;
        padding-right: 5px;
    }

    .the-checkout-sidebar-order__total {
        position: relative;
        background-color: #fff;
        flex-shrink: 0;
        z-index: 1;
        padding-left: 5px;
    }

    .the-checkout-sidebar__footer {
        margin-top: 20px;
    }

    .the-checkout-sidebar__delivery {
        display: flex;
        align-items: center;
        margin-top: 6px;
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
        color: $checkout-base-text-color;
    }

    .the-checkout-sidebar__tooltip {
        margin-left: 4px;
        svg {
            display: block;
        }
    }

    .the-checkout-sidebar__tooltip-text {
        line-height: 16px;
        right: -24px;
        top: 20px;
        width: 200px;
    }

    .the-checkout-sidebar__additional {
        position: absolute;
        top: 100%;
        display: flex;
        align-items: center;
        padding-top: 16px;
        font-weight: 300;
        font-size: 12px;
        line-height: 20px;
        color: $checkout-base-text-color;
        z-index: 1;

        @include bp($bp-desktop-sm) {
            left: 0;
            padding: 18px 13px 18px 18px;
        }

        svg {
            flex-shrink: 0;
            margin-right: 12px;
        }
    }

    //
    // --mod
    //

    .the-checkout-sidebar-order--is-active {
        svg {
            transform: rotate(180deg);
        }
    }

    .the-checkout-sidebar-order--footer {
        font-weight: $checkout-aside-total-weight;
        margin-top: 40px;
    }

    .slide-down-enter-active,
    .slide-down-leave-active {
        will-change: height, opacity;
        transition: height 0.3s ease, opacity 0.3s ease;
        overflow: hidden;
    }

    .slide-down-enter,
    .slide-down-leave-to {
        height: 0 !important;
        opacity: 0;
    }
</style>
