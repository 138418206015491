<script>
    export default {
        name: "TheCheckoutProgressBar",
        props: {
            currentStep: Number,
        },
        data() {
            return {
                steps: [
                    {
                        name: "Корзина",
                        type: "cart",
                    },
                    {
                        name: "Доставка",
                        type: "delivery",
                    },
                    {
                        name: "Оплата",
                        type: "payment",
                    },
                    {
                        name: "Заказ оформлен",
                        type: "ready",
                    },
                ],
            };
        },
    };
</script>

<template>
    <div class="the-checkout-progress-bar">
        <template v-for="(step, i) in steps">
            <div
                class="the-checkout-progress-bar__step"
                :class="{ 'is-active': i <= currentStep }"
            >
                <div class="the-checkout-progress-bar__step-title">
                    {{ step.name }}
                </div>
                <div class="the-checkout-progress-bar__step-type">
                    <svg
                        v-if="step.type === 'cart'"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M2 8l1.51 10.566A4 4 0 007.47 22h9.06a4 4 0 003.96-3.434L22 8H2z"
                        />
                        <path
                            d="M11 4h2a2 2 0 012 2v1h2V6a4 4 0 00-4-4h-2a4 4 0 00-4 4v1h2V6a2 2 0 012-2z"
                        />
                    </svg>

                    <svg
                        v-if="step.type === 'delivery'"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M15.434 3.607L6.359 8.253 2.75 6.478l8.915-4.402c.2-.101.44-.101.65 0l3.119 1.531zM21.24 6.478l-9.235 4.575L8.546 9.35l-.499-.254 9.085-4.646.5.253 3.608 1.775zM11.265 12.372L11.255 22l-8.846-4.617A.772.772 0 012 16.705V7.797l3.749 1.846v3.247c0 .416.34.76.75.76s.75-.344.75-.76v-2.495l.5.243 3.516 1.734zM21.99 7.807l-9.225 4.555-.01 9.628L22 17.162l-.01-9.355z"
                        />
                    </svg>

                    <svg
                        v-if="step.type === 'payment'"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M16.584 16.166c2.99 0 5.416-.746 5.416-1.666 0-.92-2.425-1.666-5.416-1.666-2.992 0-5.417.746-5.417 1.666 0 .92 2.425 1.666 5.417 1.666zM16.584 19.083c2.99 0 5.416-.746 5.416-1.666v-1.056c-1.705.985-4.64 1.056-5.416 1.056-.777 0-3.712-.071-5.417-1.056v1.056c-.001.92 2.424 1.666 5.417 1.666zM16.584 20.334c-.777 0-3.712-.072-5.417-1.057v1.057c0 .92 2.425 1.666 5.417 1.666 2.99 0 5.416-.746 5.416-1.666v-1.057c-1.705.984-4.64 1.057-5.416 1.057z"
                        />
                        <path
                            d="M9.916 14.195l.037.021c.375-2.495 5.557-2.632 6.63-2.632.778 0 3.717.077 5.416 1.072V4.291A2.292 2.292 0 0019.709 2H4.29A2.293 2.293 0 002 4.291v8.75a2.293 2.293 0 002.291 2.291h5.625v-1.137zM7.21 5.75H5.125a.625.625 0 010-1.25h2.084a.625.625 0 010 1.25zm4.791 0a2.92 2.92 0 012.916 2.916A2.92 2.92 0 0112 11.584a2.92 2.92 0 01-2.916-2.916A2.92 2.92 0 0112 5.75z"
                        />
                    </svg>

                    <svg
                        v-if="step.type === 'ready'"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M16.576 11.855l-4.375-1.488a.617.617 0 00-.402 0l-4.375 1.488a.624.624 0 00-.424.591v3.349c0 4.088 4.561 6.072 4.756 6.156a.633.633 0 00.488-.001c.195-.082 4.756-2.069 4.756-6.155v-3.349a.624.624 0 00-.424-.591zm-1.837 3.16l-2.292 2.916a.833.833 0 01-1.198.119L9.79 16.8a.832.832 0 111.084-1.265l.799.684 1.755-2.233a.833.833 0 011.31 1.029z"
                        />
                        <path
                            d="M19.5 2h-15A2.507 2.507 0 002 4.5v13.334c0 1.375 1.125 2.5 2.5 2.5h2.484a8.09 8.09 0 01-1.025-1.666H4.5a.836.836 0 01-.834-.834V6.168h16.668v11.666a.836.836 0 01-.834.834h-1.459a8.379 8.379 0 01-1.016 1.666H19.5c1.375 0 2.5-1.125 2.5-2.5V4.5C22 3.125 20.875 2 19.5 2z"
                        />
                    </svg>
                </div>
            </div>
            <div
                v-if="i !== 3"
                class="the-checkout-progress-bar__line"
                :class="{ 'is-active': i <= currentStep, 'is-focus': i === currentStep }"
            ></div>
        </template>
    </div>
</template>

<style lang="scss">
    .the-checkout-progress-bar {
        display: flex;
        align-items: center;
    }

    .the-checkout-progress-bar__step {
        position: relative;

        &.is-active {
            color: $checkout-icon-fill;

            .the-checkout-progress-bar__step-title {
                color: $checkout-steps-title-color;
            }

            .the-checkout-progress-bar__step-type {
                svg {
                    fill: $checkout-icon-fill;
                }
            }
        }
    }

    .the-checkout-progress-bar__step-title {
        display: none;

        @include bp($bp-desktop-sm) {
            display: block;
            position: absolute;
            top: -21px;
            left: 50%;
            transform: translateX(-50%);
            color: $checkout-steps-title-color--disabled;
            font-weight: $checkout-steps-title-weight;
            font-size: 12px;
            line-height: 14px;
            text-transform: uppercase;
            white-space: nowrap;
            transition: 0.3s ease-in-out all;
        }
    }

    .the-checkout-progress-bar__step-type {
        margin: auto;

        svg {
            display: block;
            fill: $checkout-icon-fill--disabled;
            transition: 0.3s ease-in-out all;
            @include box(16px);

            @include bp($bp-desktop-sm) {
                @include box(24px);
            }
        }
    }

    .the-checkout-progress-bar__line {
        flex: 1;
        border-top: 1px solid $checkout-icon-fill--disabled;
        transition: 0.3s ease-in-out all;

        @include bp($bp-desktop-sm) {
            border-top-style: dotted;
            border-width: 5px;
            margin: 0 10px;
        }

        &.is-active {
            border-top-color: $checkout-icon-fill;
        }

        &.is-focus {
            animation-name: color-pulse;
            animation-duration: 1s;
            animation-iteration-count: infinite;
            animation-timing-function: ease-in-out;
            animation-direction: alternate;
        }
    }

    @keyframes color-pulse {
        0% {
            border-top-color: $checkout-icon-fill;
        }
        100% {
            border-top-color: $checkout-icon-fill--disabled;
        }
    }
</style>
