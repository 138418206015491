<script>
    import { scrollState } from "@/utils";
    import TheCheckoutButtonPreloader from "@/TheCheckout/TheCheckoutButtonPreloader";

    export default {
        name: "TheCheckoutConfirmDelete",
        components: { TheCheckoutButtonPreloader },
        props: {
            isOpened: Boolean,
        },
        data() {
            return {
                isLoadingActive: false,
            };
        },
        watch: {
            isOpened(status) {
                status ? this.show() : this.close();
            },
        },
        mounted() {
            window.addEventListener("popstate", () => {
                this.close();
            });
        },
        updated() {
            this.$refs.confirmDeleteClose.focus();
        },
        beforeDestroy() {
            window.removeEventListener("popstate ", () => {});
        },
        methods: {
            show() {
                window.addEventListener("keyup", this.onEscapeKeyUp);
                scrollState.disable();
            },
            close() {
                this.$emit("on-close");
                window.removeEventListener("keyup", this.onEscapeKeyUp);
                scrollState.enable();
                this.isLoadingActive = false;
            },
            confirm() {
                this.isLoadingActive = true;
                this.$emit("on-confirm");
            },
            onEscapeKeyUp(event) {
                if (event.which === 27) {
                    this.close();
                }
            },
        },
    };
</script>

<template>
    <transition name="fade">
        <div v-show="isOpened" class="base-popup-overlay">
            <div class="base-popup-container" @click.self="close">
                <div class="base-popup">
                    <button
                        class="base-popup__close"
                        @click="close"
                        ref="confirmDeleteClose"
                    ></button>
                    <div class="base-popup__content">
                        <div class="base-popup__title">Удаление товара</div>
                        <div class="base-popup__subtitle">Вы точно хотите удалить товар?</div>
                        <button
                            class="base-popup__button outline"
                            :class="{ 'is-loading': isLoadingActive }"
                            @click="confirm"
                            :disabled="isLoadingActive"
                        >
                            Удалить
                            <the-checkout-button-preloader
                                v-if="isLoadingActive"
                            ></the-checkout-button-preloader>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<style lang="scss" scoped>
    .base-popup-overlay {
        --transition-duration: 0.25s;
        position: fixed;
        z-index: 2101;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(#000000, 0.7);
    }

    .base-popup-container {
        width: 100%;
        height: 100%;
        padding: 10px;
        outline: none;
        overflow-y: auto;
        box-sizing: border-box;
    }

    .base-popup {
        position: relative;
        max-width: 300px;
        margin: 50vh auto 10px;
        padding: 30px;
        background-color: #fff;
        box-sizing: border-box;
        border-radius: var(--checkout-box-radius);
        -webkit-overflow-scrolling: touch;
        @include bp($bp-tablet-md) {
            max-width: 400px;
            margin: 20vh auto 10px;
            padding: 40px 50px 45px;
        }
    }

    .base-popup__close {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 30px;
        height: 30px;
        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 2px;
            height: 22px;
            margin: auto;
            background-color: #555770;
            border-radius: 2px;
        }
        &::before {
            transform: rotate(45deg);
        }
        &::after {
            transform: rotate(-45deg);
        }
    }

    .base-popup__content {
        @include bp($bp-tablet-md) {
            text-align: center;
        }
    }

    .base-popup__title {
        margin-bottom: 20px;
        color: #1c1c28;
        font-size: 18px;
        font-weight: 500;
        line-height: 21px;
        @include bp($bp-tablet-md) {
            margin-bottom: 10px;
            font-size: 25px;
            line-height: 30px;
        }
    }

    .base-popup__subtitle {
        margin-bottom: 15px;
        color: $checkout-base-text-color;
        font-size: 14px;
        line-height: 20px;
        @include bp($bp-tablet-md) {
            margin-bottom: 20px;
            font-size: 16px;
            line-height: 20px;
        }
    }

    .base-popup__button {
        position: relative;
        display: block;
        width: 100%;
        max-width: $checkout-popup-button-width;
        margin: 0 auto;
        padding: 12px 15px;
        color: $checkout-button-text;
        background-color: $checkout-button-color;
        border-radius: $checkout-button-radius--large;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        transition: 0.3s ease-in-out all;
        &:not(:disabled):hover {
            background-color: $checkout-button-color--hover;
        }
        &.is-loading {
            color: transparent;
        }
        @include bp($bp-tablet-md) {
            padding: 15px;
            font-size: 16px;
            line-height: 20px;
        }
    }
</style>
