var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"the-checkout-bonus-item",class:{
        'the-checkout-bonus-item--sidebar': _vm.isSidebar && _vm.isMadRobots,
        'the-checkout-bonus-item--success': _vm.isSuccess,
        'the-checkout-bonus-item--single': _vm.singleMode && !_vm.isSidebar,
        'the-checkout-bonus-item--is-madcoin': _vm.isMadCoin,
        'the-checkout-bonus-item--is-action-madcoin': _vm.isMadCoin && _vm.isMadCoinShow,
        'the-checkout-bonus-item--filled': _vm.isFilled,
    },on:{"submit":function($event){$event.preventDefault();return _vm.handlerBonus.apply(null, arguments)}}},[_c('label',{staticClass:"the-checkout-bonus-item__label"},[_c('input',{staticClass:"the-checkout-bonus-item__input",class:_vm.className,attrs:{"disabled":_vm.isSuccess,"type":"text","placeholder":_vm.placeholder},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)}}})]),_c('button',{staticClass:"the-checkout-bonus-item__button outline",class:{
            'is-animated': _vm.isLoading,
            'is-disabled': _vm.value.length < 2 && !_vm.isMadCoinShow,
        },attrs:{"disabled":_vm.isLoading}},[_c('span',{staticClass:"the-checkout-bonus-item__button-apply mobile-hidden"},[_vm._v("Применить")]),_c('span',{staticClass:"the-checkout-bonus-item__button-apply desktop-hidden"},[_vm._v("Ввод")]),_c('span',{staticClass:"the-checkout-bonus-item__button-cancel"},[_vm._v("Отменить")]),_c('the-checkout-button-preloader',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}],attrs:{"size":"small"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }