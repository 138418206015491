<script>
    import { mapState } from "vuex";
    import {
        isMobile,
        scrollTo,
        createFeatures,
        getLabelName,
        setLabelsForCouriers,
        getVariable,
        setVariable,
        getNewDate,
        numberFormat,
        removeVariable,
    } from "@/utils";
    import { sendAnalytics } from "@/core/SendAnalytics";
    import TheCheckoutDeliveryItem from "@/TheCheckout/TheCheckoutDeliveryItem";
    import TheCheckoutDeliveryCourierItem from "@/TheCheckout/TheCheckoutDeliveryCourierItem";
    import TheCheckoutDeliveryAddress from "@/TheCheckout/TheCheckoutDeliveryAddress";
    import TheCheckoutDeliveryPickup from "@/TheCheckout/TheCheckoutDeliveryPickup";
    import TheCheckoutDeliveryCity from "@/TheCheckout/TheCheckoutDeliveryCity";

    const ERROR_TEXT_DELIVERY = {
        msk: "К сожалению, данная служба доставки временно недоступна, после оформления заказа оператор свяжется с вами для уточнения способа и стоимости доставки",
        region: "Ошибка расчета доставки, либо доставка выбранной службой в ваш регион не осуществляется",
        add: `.<br> По всем вопросам обращайтесь на <a href="mailto:info@madrobots.ru">info@madrobots.ru</a> или в онлайн чат в правом нижнем углу экрана.`,
        post: "Ошибка расчета доставки, укажите другой адрес доставки",
    };

    export default {
        name: "TheCheckoutDelivery",
        components: {
            TheCheckoutDeliveryCity,
            TheCheckoutDeliveryPickup,
            TheCheckoutDeliveryAddress,
            TheCheckoutDeliveryCourierItem,
            TheCheckoutDeliveryItem,
        },
        props: {
            globalDelivery: Object,
            currentStep: Number,
            address: Object,
            errorList: Object,
            idExpressDeliveryList: Array,
            deliveryList: Object,
            isErrorPostDelivery: Boolean,
            paySystem: Object,
            isCheckRegions: Boolean,
            idMadRobotsCouriersList: Array,
        },
        data() {
            return {
                // for maps && pickup
                map: {},
                objectManager: null,
                configuredPickupList: [],
                isPickupDelivery: false, // блок с самовывозом d

                //  сохранение выбора ПВЗ
                selectedPickup: {
                    id: "",
                    service: 0,
                    address: "",
                    price: 0,
                    isMr: false,
                },

                // for delivery
                configuredDeliveryList: [],
                isEmptyDelivery: false, // ошибка ядо
                emptyDeliveryText: "", // текст ошибка ядо
                isAddressForm: true, // блок с формой для курьерки

                // for courier
                configuredCouriersList: [],
                notificationAboutSpb: false, // infoTextAboutSpb
                isExpressDelivery: false,
                isLoadFromPost: false,
                courierIntervalId: undefined,
                notificationPeakLoad: false,

                // other
                timeoutID: null,
            };
        },
        computed: {
            ...mapState("Root", ["city"]),
            deliveryTitle() {
                return this.globalDelivery.type === "PICKUP"
                    ? "Комментарий к заказу"
                    : "Куда доставить";
            },
            isMadRobotsCourier() {
                return this.idMadRobotsCouriersList.includes(this.globalDelivery.id);
            },
            // currentCourier() {
            //     return (
            //         this.configuredCouriersList.filter(
            //             (courier) => courier.id === this.globalDelivery.id
            //         )[0] || {}
            //     );
            // },
        },
        watch: {
            city: {
                handler(city) {
                    if (this.paySystem.list) {
                        this.changeCity(city);
                    }
                },
            },
            // currentCourier: {
            //     handler(updatedCourier) {
            //         if (
            //             updatedCourier.deliveryIntervals &&
            //             updatedCourier.deliveryIntervals.length &&
            //             updatedCourier.company === "Madrobots"
            //         ) {
            //             let indexCourier = updatedCourier.deliveryIntervals.findIndex(
            //                 (interval) => interval.id === this.courierIntervalId
            //             );
            //             let currentInterval =
            //                 indexCourier >= 0
            //                     ? updatedCourier.deliveryIntervals[indexCourier]
            //                     : updatedCourier.deliveryIntervals[0];
            //             this.$emit("set-current-price-delivery", currentInterval.price);
            //             this.$emit("set-delivery-interval", currentInterval.id);
            //             this.courierIntervalId = currentInterval.id;
            //         } else {
            //             this.$emit("set-delivery-interval", undefined);
            //         }
            //     },
            // },
        },
        created() {
            this.setSelectedPickup();
        },
        mounted() {
            this.initMapEvents();
            this.$eventBus.$on("checkout-call-load-delivery", this.loadDelivery);
        },
        beforeDestroy() {
            this.$eventBus.$off("checkout-call-load-delivery");
        },
        methods: {
            /**
             * Выбрать доставку
             *
             * @param  { String } type - тип доставки (курьер, почта, пвз)
             */
            selectDeliveryItem({ type }) {
                this.$emit("reset-fields-error");
                this.$emit("set-delivery-interval", undefined);

                let savedId = "";
                let savedService = 0;
                this.isAddressForm = true;
                this.isLoadFromPost = false;
                this.closePickupPopup();

                if (type === "COURIER") {
                    this.configuredCouriersList = this.getCourier();
                }

                if (type === "PICKUP") {
                    this.isAddressForm = false;
                    this.isPickupDelivery = true;
                    this.notificationPeakLoad = false;

                    if (this.selectedPickup.address) {
                        this.saveStateDelivery({
                            id: this.selectedPickup.id,
                            type,
                            service: this.selectedPickup.service,
                        });

                        savedId = this.selectedPickup.id;
                        savedService = this.selectedPickup.service;

                        setVariable("MadRobots_Pickup_Address", this.selectedPickup.address);
                        setVariable("MadRobots_Pickup_Price", this.selectedPickup.price);
                        setVariable("MadRobots_Pickup_Mr", this.selectedPickup.isMr);
                    }

                    this.notificationAboutSpb = false;
                }

                if (type === "POST") {
                    this.setActivePaySystem();
                }

                if (
                    (type === "COURIER" && this.globalDelivery.type === "POST") ||
                    (type === "POST" && this.globalDelivery.type === "COURIER")
                ) {
                    this.$emit("reset-address-fields");
                }

                this.creatingDeliveryAfterLoading({
                    id: savedId,
                    type,
                    service: savedService,
                });

                sendAnalytics.send(
                    sendAnalytics.secondStepCheckoutEvents.orderChangeDeliveryType
                );
                sendAnalytics.send(sendAnalytics.events.delivery);
            },

            /**
             * загрузка способов доставки ###-1
             */
            loadDelivery(isResetMap) {
                if (isResetMap) {
                    this.destroyMap();
                }

                this.$emit("start-loading-sidebar");

                this.closePickupPopup();
                this.isEmptyDelivery = false;

                this.$store.orderApi
                    .getDeliveryCheckout({
                        city: this.city,
                        address: this.getAddress(),
                        paySystemId: this.paySystem.activeId,
                    })
                    .then((res) => {
                        if (
                            res.isDownExternalServices &&
                            Object.keys(res.deliveryItems).length === 0
                        ) {
                            this.$emit("set-state-external-services", true);
                            this.$emit("stop-loading-sidebar");
                            return false;
                        }
                        this.$emit("set-state-external-services", false);
                        this.setDeliveryList(res);
                        this.$emit("stop-loading-sidebar");
                    });
            },

            /**
             * Подготовка данных способов доставки ###-2
             * @param {Array} deliveryItems - список самовывозу, курьерной, почты
             * @param {Number} deliveryService - id сервиса
             * @param {Object} deliveryServices - название сервиса доставки(Яндекс.Доставка)
             * @param {Array} paySystems - список id по оплате
             * @param {Object} paySystemsByDelivery - список id по сервисам доставки(актуально для пикук)
             * @param {Number} error
             * @param {String} errorMessage
             * @returns {Object}
             */
            setDeliveryList({
                deliveryItems,
                deliveryServices,
                paySystems,
                paySystemsByDelivery,
                error,
                errorMessage,
            }) {
                this.$emit("reset-delivery-fastest");
                this.$emit("reset-delivery-list");

                if (Object.keys(paySystems || {}).length > 0) {
                    this.$emit("set-pay-system", {
                        activeList: paySystems,
                        activeListByDelivery: paySystemsByDelivery,
                    });
                }

                if (
                    error ||
                    Object.keys(deliveryServices).length === 0 ||
                    Object.keys(deliveryItems).length === 0
                ) {
                    this.showEmptyDelivery(errorMessage);
                    return false;
                }

                this.$emit("update-delivery", deliveryItems);

                if (
                    !this.globalDelivery.type ||
                    !Object.prototype.hasOwnProperty.call(
                        this.deliveryList,
                        this.globalDelivery.type
                    ) ||
                    !Object.prototype.hasOwnProperty.call(
                        this.deliveryList[this.globalDelivery.type],
                        "items"
                    ) ||
                    this.deliveryList[this.globalDelivery.type] === undefined ||
                    Object.keys(this.deliveryList[this.globalDelivery.type].items).length <= 0
                ) {
                    this.$emit("reset-global-delivery-variables");
                }

                let companiesArr = Object.values(this.deliveryList["COURIER"].items);

                if (companiesArr.length > 0) {
                    setLabelsForCouriers(companiesArr);
                    this.isExpressDelivery =
                        companiesArr.filter((company) =>
                            this.idExpressDeliveryList.includes(company.id)
                        ).length > 0;
                }

                this.creatingDeliveryAfterLoading({
                    id: this.globalDelivery.id,
                    type: this.isLoadFromPost ? "POST" : this.globalDelivery.type || "COURIER",
                    service: this.globalDelivery.service || 0,
                });
            },

            updateCurrentDeliveryList(list) {
                this.configuredDeliveryList = list || [];
            },

            creatingDeliveryAfterLoading({ id, type, service }) {
                this.updateCurrentDeliveryList();
                this.notificationAboutSpb = false;
                this.$emit("set-error-post-delivery", false);
                let innerList = [];

                const COURIER_LIST = this.deliveryList["COURIER"].items;
                const PICKUP_LIST = this.deliveryList["PICKUP"].items;
                const POST_LIST = this.deliveryList["POST"].items;

                const COURIER_LIST_LENGTH = Object.keys(COURIER_LIST).length;
                const PICKUP_LIST_LENGTH = Object.keys(PICKUP_LIST).length;
                // const POST_LIST_LENGTH = Object.keys(POST_LIST).length;

                // если нет курьерской службы
                if (COURIER_LIST_LENGTH === 0 && type === "COURIER") {
                    type = "PICKUP";
                }

                // доступна только почта
                if (COURIER_LIST_LENGTH === 0 && PICKUP_LIST_LENGTH === 0) {
                    type = "POST";
                }

                // let countListForCourier = COURIER_LIST_LENGTH + POST_LIST_LENGTH;

                const isMadRobotsCourier = Object.keys(COURIER_LIST).some(
                    (key) => COURIER_LIST[key].our
                );

                const isShowFirstDelivery = !Object.prototype.hasOwnProperty.call(
                    this.deliveryList[type].items,
                    id
                ); // когда нет id, то есть первая загрузка(по умолчанию установка курьера)

                if (isShowFirstDelivery) {
                    id = this.deliveryList[type].fastest.id;
                    service = this.deliveryList[type].fastest.deliveryId;

                    this.saveStateDelivery({ id, type, service });
                } else {
                    // если уже id установлен или сохранени из стореджа
                    // поидее отсавляем все как есть
                    // ???  изменим сервис так как может быть не наше доставка

                    // тут стоит проеврка на почту
                    service = this.deliveryList[type].items[id].deliveryId;
                }

                if (type === "POST") {
                    Object.keys(POST_LIST).forEach((idPost) => {
                        id = idPost;
                    });
                }

                if (isMadRobotsCourier && type === "COURIER") {
                    this.toggleNotificationForSpb(COURIER_LIST);
                }

                this.notificationPeakLoad = !isMadRobotsCourier;

                /**
                 * Формирования списка доставок
                 */
                Object.keys(this.deliveryList).forEach((innerType) => {
                    if (Object.keys(this.deliveryList[innerType].items).length) {
                        innerList.push(this.createDeliveryItem(innerType));
                    }
                });

                /**
                 * Добавляем фейковую почту, если адрес неверен
                 */
                if (
                    Object.keys(this.deliveryList["POST"].items).length === 0 &&
                    this.isLoadFromPost
                ) {
                    innerList.push(this.createDeliveryItemPost());

                    if (this.globalDelivery.type === "POST") {
                        this.$emit("set-error-post-delivery", true);
                    }
                }

                this.$emit(
                    "set-current-price-delivery",
                    type !== "PICKUP" ? this.deliveryList[type].items[id]?.price || 0 : 0
                );

                this.updateCurrentDeliveryList(innerList);

                this.saveStateDelivery({ id, type, service });

                this.getDataAboutSelectedDelivery(type);

                if (this.paySystem.isPrint) {
                    this.$eventBus.$emit("checkout-call-print-pay-system", {
                        deliveryId: id,
                        deliveryType: type,
                    });
                }

                this.$emit("set-pay-system", { isPrint: true });

                if (this.selectedPickup.address && type === "PICKUP") {
                    this.$emit("set-selected-pickup", this.selectedPickup);
                } else {
                    removeVariable("MadRobots_Pickup_Address");
                    removeVariable("MadRobots_Pickup_Price");
                    removeVariable("MadRobots_Pickup_Mr");
                }
            },

            createDeliveryItem(type) {
                let deliveryItem = this.deliveryList[type];
                let to = getNewDate(deliveryItem.maxDays, false);
                let from = getNewDate(deliveryItem.minDays, deliveryItem.minDays > 2);

                return {
                    companyId: deliveryItem.id,
                    deliveryService: deliveryItem.deliveryId,
                    deliveryCompany: deliveryItem.company,
                    deliveryName: deliveryItem.name,
                    name: deliveryItem.company,
                    type,
                    price: deliveryItem.minPrice,
                    from,
                    to,
                    date: from === to || !to ? from : from + " — " + to,
                    ...this.templateForDeliveryItemDescription(type),
                };
            },

            createDeliveryItemPost() {
                return {
                    companyId: "",
                    deliveryCompany: "Почта России",
                    deliveryName: "Почта России",
                    deliveryService: 8,
                    price: 0,
                    text: "Отслеживание заказа",
                    type: "POST",
                    date: "",
                    disabled: true,
                    words: [],
                };
            },

            templateForDeliveryItemDescription(type) {
                let filteredText = {
                    COURIER: "",
                    PICKUP: "",
                    POST: "Отслеживание заказа",
                };
                let filteredWords = {
                    COURIER: [" служба", " службы", " служб"],
                    PICKUP: [" пункт выдачи", " пункта выдачи", " пунктов выдачи"],
                    POST: [],
                };

                return {
                    text: filteredText[type],
                    words: filteredWords[type],
                    numbers: Object.keys(this.deliveryList[type].items).length,
                };
            },

            toggleNotificationForSpb(courierList) {
                Object.keys(courierList).forEach((key) => {
                    if (this.city === "Санкт-Петербург" && courierList[key].our) {
                        this.notificationAboutSpb = true;
                    }
                });
            },

            /**
             * html типов доставки ###-5
             */
            deliveryTypeTemplate({
                deliveryService = 0,
                deliveryCompany = 0,
                deliveryName = "",
                type = "",
                tooltipType = type,
                from = "",
                to = "",
                price = "",
                active = false,
                companyId = "",
                nameCourier = "",
                isMinPrice = false,
            }) {
                let objForDescription = {};

                if (type === "COURIER") {
                    if (!active && !this.isExpressDelivery) {
                        objForDescription = {
                            numbers: Object.keys(this.deliveryList["COURIER"].items).length,
                            words: [" служба", " службы", " служб"],
                        };
                    } else {
                        objForDescription = {
                            text: deliveryCompany ? deliveryCompany : nameCourier,
                        };
                    }
                }

                if (type === "PICKUP") {
                    objForDescription = {
                        numbers: Object.keys(this.deliveryList["PICKUP"].items).length,
                        words: [" пункт выдачи", " пункта выдачи", " пунктов выдачи"],
                        text: "",
                    };
                }

                if (type === "POST") {
                    objForDescription = {
                        text: "Отслеживание заказа",
                    };
                }

                return {
                    type,
                    tooltipType,
                    active,
                    companyId,
                    deliveryService,
                    deliveryCompany,
                    deliveryName,
                    price,
                    isMinPrice,
                    date: from === to || !to ? from : from + " — " + to,
                    ...objForDescription,
                };
            },

            /**
             * выводим данные о выбранной доставке
             */
            getDataAboutSelectedDelivery(type) {
                if (
                    !Object.prototype.hasOwnProperty.call(this.deliveryList, type) ||
                    !Object.prototype.hasOwnProperty.call(this.deliveryList[type], "items") ||
                    Object.keys(this.deliveryList[type].items).length <= 0
                ) {
                    return false;
                }
                this.hideSecondNameField();

                this.isAddressForm = type === "COURIER" || type === "POST";

                if (type === "COURIER") {
                    this.configuredCouriersList = this.getCourier();
                }

                if (type === "POST") {
                    this.showSecondNameField();
                }

                if (type === "PICKUP") {
                    if (!isMobile(640)) {
                        this.isPickupDelivery = true;
                    }
                    setTimeout(() => {
                        this.getPickup(type);
                    }, 250);
                }
            },

            /**
             * получаем самовывоз
             */
            getPickup(type) {
                let pickupList = [],
                    item,
                    count = 0,
                    center = {
                        lat: 0,
                        lng: 0,
                    };

                if (window.ymaps) {
                    window.ymaps.ready(() => {
                        if (!this.map._type) {
                            this.map = new window.ymaps.Map("madMap", {
                                center: [55.76, 37.64],
                                zoom: 10,
                                controls: ["zoomControl"],
                            });

                            let MyBalloonLayout =
                                window.ymaps.templateLayoutFactory.createClass(
                                    `<div id="balloonPopover" class="popover top">
                                    <div class="popover__close"></div>
                                    <div class="popover__arrow"></div>
                                    <div class="popover__inner">
                                      $[[options.contentLayout]]
                                    </div>
                                  </div>`,
                                    {
                                        build: function () {
                                            this.constructor.superclass.build.call(this);

                                            this._$element =
                                                document.querySelector(".popover"); //$('.popover', this.getParentElement());

                                            this.applyElementOffset();

                                            this._$element.addEventListener("click", (e) => {
                                                if (
                                                    e.target &&
                                                    e.target.className === "popover__close"
                                                ) {
                                                    this.onCloseClick(e);
                                                }
                                            });
                                        },
                                        applyElementOffset: function () {
                                            this._$element.style.left = "-52px";
                                            this._$element.style.top =
                                                -(
                                                    this._$element.offsetHeight +
                                                    this._$element.querySelector(
                                                        ".popover__arrow"
                                                    ).offsetHeight
                                                ) + "px";
                                        },

                                        onCloseClick: function (e) {
                                            e.preventDefault();
                                            this.events.fire("userclose");
                                        },
                                        clear: function () {
                                            this.constructor.superclass.clear.call(this);
                                        },
                                        onSublayoutSizeChange: function () {
                                            MyBalloonLayout.superclass.onSublayoutSizeChange.apply(
                                                this,
                                                arguments
                                            );

                                            if (!this._isElement(this._$element)) {
                                                return;
                                            }

                                            this.applyElementOffset();

                                            this.events.fire("shapechange");
                                        },
                                        getShape: function () {
                                            if (!this._isElement(this._$element)) {
                                                return MyBalloonLayout.superclass.getShape.call(
                                                    this
                                                );
                                            }

                                            return new window.ymaps.shape.Rectangle(
                                                new window.ymaps.geometry.pixel.Rectangle([
                                                    [
                                                        this._$element.offsetLeft,
                                                        this._$element.offsetTop,
                                                    ],
                                                    [
                                                        this._$element.offsetLeft +
                                                            this._$element.offsetWidth,
                                                        this._$element.offsetTop +
                                                            this._$element.offsetHeight +
                                                            this._$element.querySelector(
                                                                ".popover__arrow"
                                                            ).offsetHeight,
                                                    ],
                                                ])
                                            );
                                        },
                                        _isElement: function (element) {
                                            return element;
                                        },
                                    }
                                );
                            let MyBalloonContentLayout =
                                window.ymaps.templateLayoutFactory.createClass(
                                    `<div class="popover__address">$[properties.balloonAddress]</div>
                                <div class="popover__row">
								  <div class="popover__col">
									  {% if properties.balloonNameCompany == "Madrobots" %}
										{% if properties.balloonId == "1974:6:2109:286799" %}
									  		<div class="popover__text popover__text--madrobots">Флагманский магазин Madrobots</div>
									  	{% elseif properties.balloonId == "1974:6:2109:269758" %}
									  		<div class="popover__text popover__text--madrobots">Склад Madrobots</div>
									  	{% else %}
									  		<div class="popover__text popover__text--madrobots">Фирменный магазин Madrobots</div>
									  	{% endif %}
									  {% else %}
								  	  	<div class="popover__text"><span>Служба доставки:</span> $[properties.balloonNameCompany]</div>
								  	  {% endif %}

									  {% if properties.balloonNameCompany == "Madrobots" %}
									  <div class="popover__image">
									  	{% if properties.balloonId == "1974:6:2109:286799" %}
									  		<img src="/static/madrobots-store-moscow.jpg" alt="Флагманский магазин Madrobots в Москве">
									  	{% elseif properties.balloonId == "1974:6:2109:269758" %}
									  		<img src="/static/madrobots-sklad-moscow.jpg" alt="Склад Madrobots в Москве">
									  	{% elseif properties.balloonId == "1974:6:2109:620075" %}
									  		<img src="/static/madrobots-store-ekb.jpg" alt="Магазин Madrobots в Екатеринбурге">
									  	{% else %}
									  		<img src="/static/madrobots-store-spb.jpg" alt="Фирменный магазин Madrobots в Санкт-Петербурге">
									  	{% endif %}
									  </div>
									  {% endif %}
								  </div>
                                  <div class="popover__col">
                                      <div class="popover__text">
                                        <span>Можно забрать:</span> $[properties.balloonDate]
                                      </div>
                                      <div class="popover__text">
                                        <span>Время работы:</span> $[properties.balloonTime]
                                      </div>
                                      {% if properties.balloonPrice %}
                                        <div class="popover__text">
                                          <span>Стоимость доставки:</span> $[properties.balloonPriceText]
                                        </div>
                                      {% else %}
                                        <div class="popover__text">
                                          <span>Доставка:</span> бесплатно
                                        </div>
                                      {% endif %}
                                    </div>
                                  </div>
								  <button class="popover__button" data-id="$[properties.balloonId]" data-service="$[properties.balloonService]" data-price="$[properties.balloonPrice]" data-is-mr="$[properties.balloonIsMr]">Заберу отсюда</button>
									`
                                );

                            // Создаем собственный макет с информацией о выбранном геообъекте.
                            let customBalloonContentLayout =
                                window.ymaps.templateLayoutFactory.createClass(
                                    [
                                        "<div class=cluster-list>",
                                        // Выводим в цикле список всех геообъектов.
                                        "{% for geoObject in properties.geoObjects %}",
                                        '<div data-idObj="{{ geoObject.id}}" class="cluster-list__item">Выбрать {{ geoObject.properties.balloonNameCompany }}</div>',
                                        "{% endfor %}",
                                        "</div>",
                                    ].join("")
                                );

                            this.objectManager = new window.ymaps.ObjectManager({
                                clusterize: true,
                                gridSize: 100,
                                clusterBalloonPanelMaxMapArea: 0,
                                clusterBalloonMaxHeight: 200,
                                clusterBalloonContentLayout: customBalloonContentLayout,
                            });

                            this.objectManager.objects.options.set({
                                balloonLayout: MyBalloonLayout,
                                balloonContentLayout: MyBalloonContentLayout,
                            });

                            this.objectManager.clusters.options.set({
                                preset: "islands#nightClusterIcons",
                            });

                            let listFeatures = [];

                            for (let key in this.deliveryList[type].items) {
                                item = this.deliveryList[type].items[key];

                                //поиск станции метро в описании
                                if (item.lat && item.lng) {
                                    center.lat += parseFloat(item.lat);
                                    center.lng += parseFloat(item.lng);

                                    let price = item.price
                                            ? numberFormat(item.price, 0, ".", "&#8201;") +
                                              " ₽"
                                            : "Бесплатно",
                                        from = getNewDate(item.minDays, item.maxDays > 2),
                                        to = getNewDate(item.maxDays, false);

                                    //формирование разметки списка пунктов
                                    pickupList.push({
                                        id: item.id,
                                        service: item.deliveryId,
                                        lat: item.lat,
                                        lng: item.lng,
                                        address: item.address,
                                        time: item.time,
                                        metro: item.metro,
                                        date: from !== to ? from + " - " + to : from,
                                        price: item.price,
                                        priceText: price,
                                        company: item.company,
                                        isMr: item.company === "Madrobots",
                                        count,
                                        forSearch: `${item.address} ${item.metro}`,
                                        iconImageHref:
                                            item.company.toLowerCase() === "madrobots"
                                                ? "/static/mp-free.png"
                                                : "/static/mp.png",
                                    });

                                    listFeatures.push(createFeatures(pickupList[count]));

                                    count++;
                                }
                            }

                            this.objectManager.add({
                                type: "FeatureCollection",
                                features: listFeatures,
                            });

                            this.map.geoObjects.add(this.objectManager);

                            // добавляем событие
                            //
                            this.objectManager.objects.events.add("click", (e) => {
                                let objectId = e.get("objectId"),
                                    obj = this.objectManager.objects.getById(objectId);

                                this.selectedPickup.id = obj.options.idBalloon;
                                this.selectItemListForMap(obj.options.idBalloon);
                            });

                            // ценрируем
                            //
                            this.map.setCenter([center.lat / count, center.lng / count], 12, {
                                checkZoomRange: false,
                            });

                            // список объектов
                            //
                            this.configuredPickupList = pickupList;

                            // Фантовмны клик если пвз всего одни
                            //
                            if (Object.keys(this.deliveryList[type].items).length === 1) {
                                // автоматичское выбор первого элемента
                                const event = new MouseEvent("click", {
                                    view: window,
                                    bubbles: true,
                                    cancelable: true,
                                });
                                setTimeout(() => {
                                    const item = document.querySelector(
                                        ".the-checkout-delivery-pickup-item"
                                    );
                                    item.dispatchEvent(event);
                                }, 700);
                            }

                            if (this.selectedPickup.address) {
                                setTimeout(() => {
                                    this.selectItemListForMap(this.selectedPickup.id);
                                }, 900);
                            }

                            this.objectManager.objects.balloon.events.add("open", () => {
                                sendAnalytics.send(
                                    sendAnalytics.secondStepCheckoutEvents.orderOpenPickupPoint
                                );
                            });
                        }
                    });
                } else {
                    setTimeout(() => {
                        this.getPickup("PICKUP");
                    }, 600);
                }
            },

            /**
             * обработчики для самовывоза
             */
            initMapEvents() {
                let pickupWrapper = document.querySelector(".the-checkout-delivery__pickup");
                if (pickupWrapper) {
                    pickupWrapper.addEventListener("click", (e) => {
                        // клик по кластер для выбора балуна
                        if (e.target && e.target.className === "cluster-list__item") {
                            let idPlace = e.target.getAttribute("data-idObj");
                            this.objectManager.objects.balloon.open(idPlace);
                        }

                        // клик по кнопки балуна
                        if (e.target && e.target.className === "popover__button") {
                            let id = e.target.getAttribute("data-id");
                            let currentPickup = this.configuredPickupList.filter(
                                (pickup) => pickup.id === id
                            )[0];

                            this.saveStateDelivery({
                                id: currentPickup.id,
                                type: "PICKUP",
                                service: currentPickup.service,
                            });

                            this.notificationPeakLoad = !currentPickup.isMr;

                            this.setActivePaySystem();

                            this.$eventBus.$emit("checkout-call-print-pay-system", {
                                deliveryId: currentPickup.id,
                                deliveryType: "PICKUP",
                            });

                            this.setStatePickup(currentPickup);
                            this.$emit("set-selected-pickup", currentPickup);

                            this.map.balloon.close();

                            if (isMobile()) {
                                this.closePickupPopup();
                            } else {
                                if (String(currentPickup.isMr) == "true") {
                                    setTimeout(() => {
                                        let giftBlock =
                                            document.querySelector(".the-checkout-packing");
                                        let bodyRect = document.body.getBoundingClientRect();

                                        if (giftBlock) {
                                            let giftBlockRect =
                                                giftBlock.getBoundingClientRect();
                                            let offset =
                                                giftBlockRect.top - bodyRect.top - 120;
                                            scrollTo(offset, 500);
                                        }
                                    }, 300);
                                }
                            }

                            sendAnalytics.send(
                                sendAnalytics.secondStepCheckoutEvents.orderSelectPickupPoint
                            );
                        }
                    });
                }
            },

            setStatePickup({ id, service, address, price, isMr }) {
                this.selectedPickup.id = id;
                this.selectedPickup.service = service;
                this.selectedPickup.address = address;
                this.selectedPickup.price = price;
                this.selectedPickup.isMr = isMr;
                setVariable("MadRobots_Pickup_Address", address);
                setVariable("MadRobots_Pickup_Price", price);
                setVariable("MadRobots_Pickup_Mr", isMr);
            },

            selectPickup({ id, address, service, count, price, isMr }) {
                if (isMobile()) {
                    this.saveStateDelivery({
                        id,
                        type: "PICKUP",
                        service,
                    });

                    this.setStatePickup({
                        id,
                        service,
                        address,
                        price,
                        isMr,
                    });
                    this.$emit("set-selected-pickup", this.selectedPickup);

                    this.closePickupPopup();
                    sendAnalytics.send(
                        sendAnalytics.secondStepCheckoutEvents.orderSelectPickupPoint
                    );

                    this.notificationPeakLoad = !isMr;
                }

                this.objectManager.objects.balloon.open(count);
            },

            changeIconMap({ count, iconImageHref }) {
                if (this.objectManager) {
                    this.objectManager.objects.setObjectOptions(count, {
                        iconImageHref,
                    });
                }
            },

            closePickupPopup() {
                this.isPickupDelivery = false;
            },

            selectItemListForMap(id) {
                let currentPickup = document.querySelector(
                    `.the-checkout-delivery-pickup-item[data-val='${id}']`
                );

                if (currentPickup) {
                    currentPickup.scrollIntoView({ block: "center" });
                }
            },

            setActivePaySystem() {
                this.$emit("set-pay-system", { activeId: this.isMadRobotsCourier ? 1 : 19 });
            },

            selectCourier({ id, service, price }) {
                this.$emit("set-current-price-delivery", price);
                this.saveStateDelivery({
                    id,
                    type: "COURIER",
                    service,
                });

                this.setActivePaySystem();

                this.$eventBus.$emit("checkout-call-print-pay-system", {
                    deliveryId: id,
                    deliveryType: "COURIER",
                });

                sendAnalytics.send(sendAnalytics.secondStepCheckoutEvents.orderSelectCourier);
                sendAnalytics.send(sendAnalytics.events.delivery);
            },

            currentDebounce(fn, delay) {
                return () => {
                    clearTimeout(this.timeoutID);
                    let args = arguments;
                    this.timeoutID = setTimeout(() => {
                        fn.apply(this, args);
                    }, delay);
                };
            },

            updateAddress() {
                let address = this.getAddress();

                if (address !== "") {
                    this.isLoadFromPost = this.globalDelivery.type === "POST";
                    this.loadDelivery();
                }
            },

            changeInputForAddress() {
                if (this.globalDelivery.type === "POST" || this.city === "Москва") {
                    this.currentDebounce(this.updateAddress, 700)();
                }
            },

            resetError(name) {
                this.$emit("reset-error", name);
            },

            /**
             * курьерская доставка
             */
            getCourier() {
                const type = "COURIER";
                let list = [];
                if (
                    Object.prototype.hasOwnProperty.call(this.deliveryList, type) &&
                    Object.prototype.hasOwnProperty.call(this.deliveryList[type], "items") &&
                    Object.keys(this.deliveryList[type].items).length
                ) {
                    for (let key in this.deliveryList[type].items) {
                        list.push(this.getCourierHtml(this.deliveryList[type].items[key]));
                    }
                }

                list.sort((a, b) => (a.sort > b.sort ? -1 : 1));

                return list;
            },

            /**
             * html для курьерской доставки
             * @param courier {Object} курьерская служба
             * @returns {string}
             */
            getCourierHtml(courier) {
                let from = "";
                let to = "";
                let labelHtml = {};

                from = getNewDate(courier.minDays, courier.maxDays > 2);
                to = getNewDate(courier.maxDays, false);

                if ((courier.label && Boolean(courier.price)) || courier.label === "fast") {
                    labelHtml = {
                        sort: "2",
                        label: courier.label,
                        textLabel: getLabelName(courier.label),
                    };
                } else {
                    labelHtml = {
                        sort: "1",
                    };
                }

                return {
                    price: courier.price,
                    id: courier.id,
                    service: courier.deliveryId,
                    company: courier.company,
                    date: courier.minDays !== courier.maxDays ? `${from} — ${to}` : `${from}`,
                    ...labelHtml,
                    deliveryIntervals: courier.deliveryIntervals,
                    isMr: courier.company === "Madrobots" && this.city === "Москва",
                };
            },

            selectInterval({ id, price }) {
                this.courierIntervalId = id;
                this.$emit("set-current-price-delivery", price);
                this.$emit("set-delivery-interval", id);
            },

            changeCity(city) {
                // село Ям, городской округ Домодедово, Московская область - только курьерка
                if (city) {
                    this.$emit("reset-global-delivery-variables");

                    this.$emit("reset-delivery-fastest");

                    this.updateCurrentDeliveryList();
                    this.configuredCouriersList = [];
                    this.configuredPickupList = [];

                    this.setStatePickup({
                        id: "",
                        service: 0,
                        address: "",
                        price: 0,
                        isMr: false,
                    });
                    this.$emit("set-selected-pickup", this.selectedPickup);
                    this.$emit("set-current-price-delivery", 0);

                    this.closePickupPopup();
                    this.notificationAboutSpb = false;
                    this.notificationPeakLoad = false;
                    this.isLoadFromPost = false;

                    this.$emit("set-pay-system", {
                        activeId: city === "Москва" ? 1 : 19,
                    });

                    setVariable("MadRobots_Main_Delivery_ID", 0);
                    setVariable("MadRobots_Main_Delivery_Type", "");
                    setVariable("MadRobots_Main_Delivery_Service", 0);

                    removeVariable("MadRobots_street");
                    removeVariable("MadRobots_house");
                    removeVariable("MadRobots_build");
                    removeVariable("MadRobots_housing");
                    removeVariable("MadRobots_flat");
                    removeVariable("MadRobots_Pickup_Address");
                    removeVariable("MadRobots_Pickup_Price");
                    removeVariable("MadRobots_Pickup_Mr");

                    this.$emit("reset-address-fields");

                    this.$emit("set-pay-system", { isPrint: true });

                    this.destroyMap();

                    this.loadDelivery();

                    if (this.currentStep === 2) {
                        this.$emit("set-step-from-delivery");
                    }

                    sendAnalytics.send(sendAnalytics.secondStepCheckoutEvents.orderChangeCity);
                }
            },

            destroyMap() {
                if ("destroy" in this.map) {
                    this.map.destroy();
                    this.map = {};
                    this.objectManager = null;
                }
            },

            showSecondNameField() {
                this.$emit("toggle-second-name", true);
            },

            hideSecondNameField() {
                this.$emit("toggle-second-name", false);
            },

            /**
             * сохранение значения доставки
             * @param id {String} пункт
             * @param type {String} тип доставки
             * @param service {Number, String} id сервиса доставки
             */
            saveStateDelivery({ id, type, service }) {
                this.setGlobalDeliveryVariables({ id, type, service });

                setVariable("MadRobots_Main_Delivery_ID", id);
                setVariable("MadRobots_Main_Delivery_Type", type);
                setVariable("MadRobots_Main_Delivery_Service", service);
            },

            setGlobalDeliveryVariables({ id, type, service }) {
                this.$emit("set-global-delivery-variables", {
                    id,
                    type,
                    service,
                });
            },

            /**
             * Получение адреса доставки
             * @returns {string}
             */
            getAddress() {
                let { street, house, housing, build } = this.address;
                let address = "";

                if (!street || !house) return address;

                address += `${street} ${house}`;

                if (housing !== "") address += `, к. ${housing}`;

                if (build !== "") address += `, стр. ${housing}`;

                return address;
            },

            /**
             * не пришли данные с ЯДО
             */
            showEmptyDelivery(errorMessage = "") {
                let text =
                    this.city === "Москва"
                        ? ERROR_TEXT_DELIVERY.msk
                        : ERROR_TEXT_DELIVERY.region;

                if (errorMessage.length > 0) {
                    text = `${errorMessage}${ERROR_TEXT_DELIVERY.add}`;
                }

                if (this.globalDelivery.type === "POST") {
                    this.$emit("set-error-from-post");
                    text = ERROR_TEXT_DELIVERY.post;
                }

                this.isEmptyDelivery = true;
                this.emptyDeliveryText = text;

                this.updateCurrentDeliveryList();
                this.configuredCouriersList = [];

                console.error("По всем вопросам обращайтесь на info@madrobots.ru");
            },

            setLocalStorage(obj) {
                this.$emit("set-from-local-storage", { type: "address", ...obj });
            },

            setSelectedPickup() {
                if (getVariable("MadRobots_Main_Delivery_Type") === "PICKUP") {
                    this.selectedPickup = {
                        id: getVariable("MadRobots_Main_Delivery_ID") || "",
                        service: getVariable("MadRobots_Main_Delivery_Service") || 0,
                        address: getVariable("MadRobots_Pickup_Address") || "",
                        price: getVariable("MadRobots_Pickup_Price") || 0,
                        isMr: getVariable("MadRobots_Pickup_Mr") || false,
                    };
                }
            },
        },
    };
</script>

<template>
    <div class="the-checkout-delivery">
        <the-checkout-delivery-city :city="city"></the-checkout-delivery-city>

        <div class="the-checkout-delivery__delivery the-checkout-style__box-shadow-desktop">
            <div
                class="
                    the-checkout-delivery__mobile-wrapper
                    the-checkout-style__box-shadow-mobile
                "
            >
                <div class="the-checkout-delivery__title the-checkout-delivery__title--mobile">
                    Выберите способ доставки
                </div>

                <div class="the-checkout-delivery__header">
                    <the-checkout-delivery-item
                        v-for="deliveryItem in configuredDeliveryList"
                        :key="deliveryItem.name"
                        :delivery-list="deliveryList"
                        :delivery-item="deliveryItem"
                        :global-delivery="globalDelivery"
                        :count="configuredDeliveryList.length"
                        :pickup-address="
                            deliveryItem.type === 'PICKUP' ? selectedPickup.address : ''
                        "
                        @select-delivery-item="selectDeliveryItem"
                    ></the-checkout-delivery-item>
                </div>
                <div
                    v-if="configuredDeliveryList.length === 0 && !isEmptyDelivery"
                    class="the-checkout-delivery__header-empty"
                >
                    <div class="the-checkout-delivery__header-item"></div>
                    <div class="the-checkout-delivery__header-item"></div>
                    <div class="the-checkout-delivery__header-item"></div>
                </div>
                <div class="the-checkout-delivery__header-error" v-if="isEmptyDelivery">
                    {{ emptyDeliveryText }}
                </div>
            </div>

            <div
                class="
                    the-checkout-delivery__mobile-wrapper
                    the-checkout-style__box-shadow-mobile
                "
            >
                <div v-if="isErrorPostDelivery" class="the-checkout-delivery__error-text">
                    Ошибка расчета доставки, укажите другой адрес доставки
                </div>
                <div
                    v-show="isPickupDelivery || globalDelivery.type === 'COURIER'"
                    class="the-checkout-delivery__body"
                >
                    <div
                        v-if="globalDelivery.type === 'COURIER'"
                        class="the-checkout-delivery__couriers"
                    >
                        <div class="the-checkout-delivery__title">Выберите курьера</div>
                        <div class="the-checkout-delivery__couriers-wrapper">
                            <the-checkout-delivery-courier-item
                                v-for="courier in configuredCouriersList"
                                :key="courier.id"
                                :courier="courier"
                                :delivery-id="globalDelivery.id"
                                @select-courier="selectCourier"
                            ></the-checkout-delivery-courier-item>
                        </div>

                        <!--                        <div-->
                        <!--                            v-if="city === 'Москва' && currentCourier.company === 'Madrobots'"-->
                        <!--                            class="the-checkout-delivery__intervals"-->
                        <!--                        >-->
                        <!--                            <div class="the-checkout-delivery__intervals-title">-->
                        <!--                                Когда доставить <span>{{ currentCourier.date }}</span>-->
                        <!--                            </div>-->
                        <!--                            <div class="the-checkout-delivery__intervals-list">-->
                        <!--                                <div-->
                        <!--                                    class="the-checkout-delivery__intervals-item"-->
                        <!--                                    :class="{ 'is-active': interval.id === courierIntervalId }"-->
                        <!--                                    v-for="interval in currentCourier.deliveryIntervals"-->
                        <!--                                    :key="interval.id"-->
                        <!--                                    tabindex="0"-->
                        <!--                                    @click="selectInterval(interval)"-->
                        <!--                                    @keypress.enter="selectInterval(interval)"-->
                        <!--                                >-->
                        <!--                                    {{ interval.from }}-{{ interval.to }}-->
                        <!--                                    <span v-show="interval.price">{{ interval.price }} ₽</span>-->
                        <!--                                </div>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                    </div>

                    <div v-show="isPickupDelivery" class="the-checkout-delivery__pickup">
                        <the-checkout-delivery-pickup
                            :selected-pickup="selectedPickup"
                            :pickup-list="configuredPickupList"
                            :selected-city="city"
                            @select-pickup="selectPickup"
                            @change-icons-map="changeIconMap"
                            @close-pickup-popup="closePickupPopup"
                        ></the-checkout-delivery-pickup>
                    </div>
                </div>

                <div
                    class="the-checkout-delivery__footer"
                    :class="{ 'the-checkout-delivery__footer--pickup': isPickupDelivery }"
                >
                    <div class="the-checkout-delivery__address">
                        <div class="the-checkout-delivery__title">{{ deliveryTitle }}</div>

                        <the-checkout-delivery-address
                            :address="address"
                            :is-address-form="isAddressForm"
                            :error-list="errorList"
                            @change-input="changeInputForAddress"
                            @reset-error="resetError"
                            @set-from-local-storage="setLocalStorage"
                        ></the-checkout-delivery-address>
                    </div>

                    <div v-show="notificationAboutSpb" class="the-checkout-delivery__notice">
                        Доставка осуществляется собственными курьерами нашего магазина в
                        интервале с 10 до 22 часов, в пределах КАД и не дальше 3 км от
                        ближайшей станции метро. В остальных случаях мы отправим ваш заказ
                        сторонней транспортной службой, указывайте удобный интервал доставки.
                    </div>

                    <div
                        v-show="notificationPeakLoad"
                        class="the-checkout-delivery__notice"
                        v-html="
                            'Из-за пиковой нагрузки курьерских служб в&nbsp;период распродаж сроки доставки могут незначительно отличаться от&nbsp;заявленных.'
                        "
                    ></div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .the-checkout-delivery__delivery {
        border-top-color: transparent !important;
    }

    .the-checkout-delivery__error-text {
        font-size: 16px;
        line-height: 26px;
        color: #e91e63;
        padding: 20px 20px 0;
        text-align: center;
    }

    .the-checkout-delivery__header {
        display: flex;
        flex-direction: column-reverse;

        @include bp($bp-desktop-sm) {
            width: $checkout-control-width-header;
            margin-left: $checkout-control-ml-header;
            flex-direction: row;
        }
    }

    .the-checkout-delivery__header-error {
        width: 100%;
        height: 106px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 24px;
        font-size: 16px;
        line-height: 26px;
        color: #1c1c28;
    }

    .the-checkout-delivery__header-empty {
        display: flex;
        flex-direction: column;

        @include bp($bp-desktop-sm) {
            flex-direction: row;
        }
    }

    .the-checkout-delivery__header-item {
        width: 100%;
        height: 92px;
        position: relative;
        background-color: #e5e5ec;
        opacity: 0.6;
        overflow: hidden;
        border: 1px solid #ebebf0;
        border-radius: var(--checkout-box-radius);

        &:not(:first-child) {
            margin-top: 10px;
        }
        &:nth-child(1) {
            display: none;
        }

        @include bp($bp-desktop-sm) {
            width: calc(100% / 3);
            height: 106px;
            margin: 8px;

            &:nth-child(1) {
                display: block;
            }
        }

        &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transform: translateX(-100%);
            background-image: linear-gradient(
                90deg,
                rgba(#fff, 0) 0,
                rgba(#fff, 0.2) 20%,
                rgba(#fff, 0.5) 60%,
                rgba(#fff, 0)
            );
            animation: shimmer 2s infinite;
            content: "";
        }
    }
    @keyframes shimmer {
        100% {
            transform: translateX(100%);
        }
    }

    .the-checkout-delivery__title {
        font-size: 14px;
        line-height: 22px;
        color: $checkout-title-color;
        margin-bottom: 16px;
    }

    .the-checkout-delivery__mobile-wrapper {
        @include bp_max($bp-desktop-sm_max) {
            padding: 15px;
            margin-bottom: 24px;
        }
    }

    .the-checkout-delivery__body {
        @include bp($bp-desktop-sm) {
            padding: 20px 20px 0;
        }
    }

    .the-checkout-delivery__couriers-wrapper {
        display: flex;
        flex-wrap: wrap;

        @include bp($bp-desktop-sm) {
            margin: 0 -5px;
        }
    }

    .the-checkout-delivery__footer {
        @include bp($bp-desktop-sm) {
            padding: 20px;
        }
    }

    .the-checkout-delivery__pickup {
        @include bp_max($bp-desktop-sm_max) {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 2005;
            background-color: #fff;
            padding: 10px;
            width: 100%;
            height: 100%;
        }
    }

    .the-checkout-delivery__notice {
        margin-top: 14px;
        font-size: $checkout-delivery-notice-size--mobile;
        line-height: 22px;
        text-align: center;
        color: #e91e63;
        @include bp($bp-desktop-sm) {
            font-size: $checkout-delivery-notice-size;
        }
    }

    .popover {
        position: relative;
        display: block;
        width: 300px;
        background: #ffffff;
        box-shadow: 0 0 25px #c7c9d9;
        border-radius: var(--checkout-box-radius);
        padding: 15px;
    }

    .popover__close {
        position: absolute;
        @include box(24px);
        right: 16px;
        top: 16px;
        cursor: pointer;
        &:after,
        &:before {
            content: "";
            @include center-absolute(0);
            margin: auto;
            width: 18px;
            height: 2px;
            background: #555770;
            border-radius: 3px;
        }

        &:after {
            transform: rotate(-45deg);
        }

        &:before {
            transform: rotate(45deg);
        }
        @include bp($bp-desktop-sm) {
            transition: opacity 0.2s ease-in;
            &:hover {
                opacity: 0.8;
            }
        }
    }

    .popover__arrow {
        position: absolute;
        bottom: -15px;
        left: 34px;
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;

        border-top: 15px solid #fff;
    }

    .popover__row {
        display: flex;
        flex-wrap: wrap;
    }

    .popover__col {
        width: 100%;
    }

    .popover__image {
        margin-bottom: 10px;
    }

    .popover__address {
        font-size: 16px;
        line-height: 20px;
        color: #1c1c28;
        margin-bottom: 14px;
        padding-right: 24px;
    }

    .popover__text {
        font-weight: 300;
        font-size: 12px;
        line-height: 20px;
        color: #1c1c28;

        span {
            font-weight: 500;
        }
    }
    .popover__text--madrobots {
        position: relative;
        margin-bottom: 5px;
        padding-left: 20px;
        color: #03a9f4;
        font-size: 14px;
        font-weight: 500;
        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            width: 16px;
            height: 16px;
            transform: translateY(-50%);
            background-image: url("~@/assets/images/checkout/madrobots-logo--small.svg");
        }
    }
    .popover__button {
        width: 100%;
        margin-top: 10px;
        padding: 11px 15px;
        background: $checkout-button-color;
        border-radius: $checkout-button-radius--large;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: $checkout-button-text;
        cursor: pointer;
        white-space: nowrap;
        transition: background-color 0.3s ease-in-out;
        &:not(:disabled):hover {
            background-color: $checkout-button-color--hover;
        }
        @include bp($bp-desktop-sm) {
            &:focus-visible {
                @include outline;
            }
        }
    }

    .cluster-list__item {
        font-size: 14px;
        line-height: 22px;
        color: $checkout-base-text-color;
        margin-bottom: 5px;
        cursor: pointer;
        transition: 0.3s ease-in-out all;

        &:hover {
            color: $checkout-base-color;
        }
    }

    .the-checkout-delivery__intervals {
        margin-bottom: 24px;

        @include bp($bp-desktop-sm) {
            margin-top: 20px;
            margin-bottom: 10px;
        }
    }

    .the-checkout-delivery__intervals-title {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 14px;

        span {
            color: $checkout-aside-accent-color;
            margin-left: 2px;
        }
    }

    .the-checkout-delivery__intervals-list {
        display: flex;
        flex-wrap: wrap;
        margin-top: -15px;
    }

    .the-checkout-delivery__intervals-item {
        position: relative;
        border: 1px solid $checkout-control-border-color;
        border-radius: var(--checkout-box-radius-sm);
        font-size: 12px;
        line-height: 20px;
        color: #555770;
        padding: 9px 0;
        margin-right: 8px;
        margin-top: 15px;
        cursor: pointer;
        width: 86px;
        white-space: nowrap;
        text-align: center;
        transition: 0.3s ease-in-out all;

        @include bp($bp-desktop-sm) {
            font-size: 14px;
            width: 118px;
        }

        &.is-active {
            background: #f4fcff;
            border-color: $checkout-control-border-color--active;
            pointer-events: none;

            span {
                color: #fff;
                background-color: $checkout-control-border-color--active;
            }
        }

        &:hover {
            border-color: $checkout-control-border-color--active;
        }

        span {
            position: absolute;
            bottom: -9px;
            left: 0;
            font-size: 10px;
            padding: 4px;
            background-color: $checkout-control-border-color;
            border-radius: var(--checkout-box-radius-sm);
            line-height: 1;
            width: 34px;
            height: 18px;
            right: 0;
            margin: auto;
            transition: 0.2s ease-in all;

            @include bp($bp-desktop-sm) {
                bottom: -11px;
                width: 44px;
                height: 20px;
                font-size: 12px;
            }
        }
    }

    //
    // --mod
    //

    .the-checkout-delivery__footer--pickup {
        .the-checkout-delivery__title {
            display: none;
        }
    }

    .the-checkout-delivery__title--mobile {
        display: block;

        @include bp($bp-desktop-sm) {
            display: none;
        }
    }
</style>
