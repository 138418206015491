<script>
    import TheCheckoutDeliveryCity from "@/TheCheckout/TheCheckoutDeliveryCity";
    export default {
        name: "TheCheckoutDownDetector",
        components: { TheCheckoutDeliveryCity },
        props: {
            city: String,
        },
    };
</script>

<template>
    <div class="the-checkout-down-detector">
        <the-checkout-delivery-city :city="city"></the-checkout-delivery-city>

        <div class="the-checkout-down-detector__wrap the-checkout-style__box-shadow-mobile">
            <div class="the-checkout-down-detector__box">
                <div class="the-checkout-down-detector__title">
                    Автоматический расчет стоимости и срока курьерской доставки временно
                    недоступен.
                </div>
                <div class="the-checkout-down-detector__desc">
                    Мы уже устраняем неполадки. Это не помешает вам оформить заказ и не
                    ограничит срок доставки. Укажите ваши контактные данные, мы свяжемся и
                    подтвердим срок и стоимость доставки.
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .the-checkout-down-detector {
        .the-checkout-delivery-city {
            @include bp($bp-desktop-sm) {
                padding-left: 0;
                padding-bottom: 0;
            }
        }
    }
    .the-checkout-down-detector__wrap {
        padding: 15px;

        @include bp($bp-desktop-sm) {
            padding: 0;
            background-color: transparent;
        }
    }

    .the-checkout-down-detector__box {
        padding: 15px;
        background-color: rgba(255, 96, 59, 0.06);
        border-radius: var(--checkout-box-radius);

        @include bp($bp-desktop-sm) {
            padding: 20px;
        }
    }

    .the-checkout-down-detector__title {
        font-size: 16px;
        line-height: 20px;
        font-weight: bold;
        color: #014462;
        margin-bottom: 10px;

        @include bp($bp-desktop-sm) {
            font-size: 18px;
            line-height: 22px;
        }
    }

    .the-checkout-down-detector__desc {
        font-size: 13px;
        line-height: 16px;
        color: $checkout-base-text-color;

        @include bp($bp-desktop-sm) {
            font-size: 14px;
            line-height: 18px;
        }
    }
</style>
