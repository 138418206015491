<script>
    import { declineOfNumber } from "@/utils";

    export default {
        name: "TheCheckoutEditor",
        props: {
            currentStep: Number,
            countOrders: Number,
            nameDelivery: Object,
            city: Object,
        },
        data() {
            return {
                editorsList: [
                    {
                        name: "Корзина",
                        step: 0,
                        nextStep: 1,
                    },
                    {
                        name: "Доставка",
                        step: 1,
                        nextStep: 2,
                    },
                ],
            };
        },
        computed: {
            nameForCart() {
                return `${this.countOrders} ${declineOfNumber(this.countOrders, [
                    "товар",
                    "товара",
                    "товаров",
                ])}`;
            },
            nameForDelivery() {
                let filteredTypes = {
                    POST: "Почта России",
                    PICKUP: "Самовывоз",
                    COURIER: "Курьерская",
                };
                return `${filteredTypes[this.nameDelivery.type]}: ${
                    this.nameDelivery.address
                }`;
            },
        },
        methods: {
            updateCurrentStep(step) {
                this.$emit("update-current-step", step);
            },
            getNameDesc(step) {
                if (step === 0) return this.nameForCart;
                if (step === 1) return this.nameForDelivery;
            },
        },
    };
</script>

<template>
    <div class="the-checkout-editor">
        <div
            v-show="editor.nextStep === 1 || (currentStep === 2 && editor.nextStep === 2)"
            class="the-checkout-editor__item"
            v-for="editor in editorsList"
            :key="editor.step"
            tabindex="0"
            @click="updateCurrentStep(editor.step)"
            @keypress.enter="updateCurrentStep(editor.step)"
        >
            <div class="the-checkout-editor__item-text">{{ editor.name }}</div>
            <div class="the-checkout-editor__item-text the-checkout-editor__item-text--info">
                {{ getNameDesc(editor.step) }}
            </div>
            <svg
                class="the-checkout-editor__edit"
                width="24"
                height="24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M19.26 18.999H9.653a.749.749 0 01-.74-.757c0-.417.332-.756.74-.756h9.609c.408 0 .74.339.74.756a.748.748 0 01-.74.757zM17.241 5.498l-1.045-1.07c-.559-.57-1.532-.57-2.09 0l-1.529 1.565 3.136 3.208 1.528-1.564a1.52 1.52 0 00.434-1.07 1.52 1.52 0 00-.434-1.069zM11.532 7.062l-7.618 7.794a.379.379 0 00-.098.18l-.806 3.499a.385.385 0 00.098.353.366.366 0 00.346.101l3.42-.824a.361.361 0 00.176-.101l7.618-7.794-3.136-3.208z"
                />
            </svg>
        </div>
    </div>
</template>

<style lang="scss">
    .the-checkout-editor {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;

        @include bp($bp-desktop-sm) {
            flex-wrap: nowrap;
            margin-bottom: 10px;
        }
    }

    .the-checkout-editor__item {
        width: 100%;
        padding: 7px 10px 7px 15px;
        background: #ffffff;
        border: $checkout-control-border-width solid $checkout-control-border-color;
        border-radius: 25px;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-bottom: 5px;
        outline: none;

        &:last-child {
            margin-bottom: 0;
        }

        @include bp($bp-desktop-sm) {
            padding-left: 20px;
            padding-right: 15px;
            width: auto;
            margin-right: 10px;
            height: 46px;
            margin-bottom: 0;
            transition: 0.3s ease-in-out border;

            &:nth-child(1) {
                width: 214px;
            }

            &:nth-child(2) {
                width: calc(100% - 214px - 10px);
                margin-right: 0;
            }

            &:hover {
                border-color: $checkout-control-border-color--active;

                .the-checkout-editor__edit {
                    opacity: 0.8;
                }
            }

            &:focus-visible {
                @include outline;
            }
        }
    }

    .the-checkout-editor__item-text {
        font-size: 14px;
        line-height: 16px;
        font-weight: $checkout-header-editor-title-width;

        color: $checkout-base-text-color;

        @include bp($bp-desktop-sm) {
            font-size: 16px;
            line-height: 24px;
        }
    }

    .the-checkout-editor__edit {
        display: block;
        margin-left: auto;
        flex-shrink: 0;
        fill: $checkout-icon-fill;
        transition: 0.3s ease-in-out all;

        @include bp($bp-desktop-sm) {
            margin-left: 2px;
        }
    }

    //
    // --mod
    //

    .the-checkout-editor__item-text--info {
        margin-left: 14px;
        font-weight: 300;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex: 1 2 auto;
    }
</style>
