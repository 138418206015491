<script>
    import { numberFormat } from "@/utils";

    export default {
        name: "TheOrderItemPrice",
        props: {
            product: Object,
            isMobile: Boolean,
        },
        computed: {
            productPrice() {
                return numberFormat(this.product.price, 0, ".", " ");
            },
            productBasePrice() {
                return numberFormat(this.product.basePrice, 0, ".", " ");
            },
            discount() {
                return this.product.price == 0
                    ? 100
                    : Math.round((this.product.discountPrice / this.product.basePrice) * 100);
            },
        },
    };
</script>

<template>
    <div
        class="the-order-item__prices"
        :class="{ 'the-order-item__prices--is-mobile': isMobile }"
    >
        <template v-if="product.basePrice > product.price">
            <div class="the-order-item__prices-wrapper">
                <div class="the-order-item__price the-order-item__price--discount">
                    {{ discount }}%
                </div>
            </div>
            <div class="the-order-item__price the-order-item__price--new">
                {{ productPrice }} ₽
            </div>
            <div class="the-order-item__price the-order-item__price--old">
                {{ productBasePrice }} ₽
            </div>
        </template>
        <template v-else>
            <div class="the-order-item__price the-order-item__price--current">
                {{ productPrice }} ₽
            </div>
        </template>
    </div>
</template>

<style lang="scss">
    .the-order-item__prices {
        display: none;

        @include bp($bp-tablet-sm) {
            display: block;
            width: 120px;
            margin-left: 14px;
        }
    }

    .the-order-item__price {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
    }

    .the-order-item__prices-wrapper {
        width: 100%;
        margin-bottom: 3px;

        @include bp($bp-tablet-sm) {
            margin-bottom: 0;
        }
    }

    //
    // --mod
    //

    .the-order-item__prices--is-mobile {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;

        @include bp($bp-tablet-sm) {
            display: none;
        }

        .the-order-item__price {
            font-size: 14px;
            line-height: 16px;
        }

        .the-order-item__price--old {
            font-size: 12px;
            line-height: 14px;
            margin-left: 5px;
        }

        .the-order-item__price--discount {
            font-size: 12px;
        }
    }

    .the-order-item__price--current {
        color: $checkout-base-text-color;
    }

    .the-order-item__price--new {
        color: $checkout-danger-color;
    }

    .the-order-item__price--old {
        margin-top: 3px;
        font-size: 14px;
        line-height: 16px;
        color: #8f90a6;
        text-decoration: line-through;
    }

    .the-order-item__price--discount {
        display: inline-block;
        border-radius: $checkout-label-radius;
        padding: 0 8px;
        background: $checkout-danger-color;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #ffffff;
    }
</style>
