<script>
    export default {
        name: "TheCheckoutEmpty",
        data() {
            return {
                emptyText: "Положите сюда что-нибудь, чтобы корзине было не так одиноко",
            };
        },
        watch: {
            $route() {
                this.$router.push("/");
            },
        },
    };
</script>

<template>
    <div class="the-checkout-empty">
        <h1 class="the-checkout-empty__title">Ваша корзина пуста!</h1>
        <div class="the-checkout-empty__image">
            <img
                :src="`/static/${$root.$_name}/checkout/empty-cart.svg`"
                alt="Ваша корзина пуста"
            />
        </div>
        <div class="the-checkout-empty__text">{{ emptyText }}</div>
        <a href="/" class="the-checkout-empty__button">Перейти к покупкам</a>
    </div>
</template>

<style lang="scss">
    .the-checkout-empty {
        padding: 20px 25px 25px;
        text-align: center;
        border-radius: var(--checkout-box-radius);
        box-shadow: 0 0 15px #f2f2f5;
        @include bp($bp-tablet-md) {
            padding: 55px 40px 80px;
            box-shadow: 0 0 50px #f2f2f5;
        }
    }
    .the-checkout-empty__title {
        margin-bottom: 30px;
        color: $checkout-icon-fill;
        font-size: 18px;
        font-weight: 700;
        line-height: 21px;
        @include bp($bp-tablet-md) {
            margin-bottom: 45px;
            font-size: 30px;
            line-height: 35px;
        }
    }
    .the-checkout-empty__image {
        margin-bottom: 25px;
        img {
            margin: 0 auto;
            max-width: 120px;
            @include bph($bp-850h) {
                max-width: 100%;
            }
        }
        @include bp($bp-tablet-md) {
            margin-bottom: 25px;
        }
    }
    .the-checkout-empty__text {
        margin-bottom: 25px;
        font-size: 14px;
        line-height: 16px;
        @include bp($bp-tablet-md) {
            margin-bottom: 45px;
            font-size: 16px;
            line-height: 20px;
        }
    }
    .the-checkout-empty__button {
        display: block;
        width: 100%;
        max-width: $checkout-popup-button-width;
        margin: 0 auto;
        padding: 10px;
        color: $checkout-button-text;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        background-color: $checkout-button-color;
        border-radius: $checkout-button-radius--large;
        transition: 0.3s ease-in-out all;
        &:hover {
            background-color: $checkout-button-color--hover;
        }
        @include bp($bp-tablet-md) {
            padding: 15px 10px;
            font-size: 16px;
        }
    }
</style>
