<script>
    import { sendAnalytics } from "@/core/SendAnalytics";
    import TheCheckoutBonusItem from "@/TheCheckout/TheCheckoutBonusItem";

    export default {
        name: "TheCheckoutBonuses",
        components: { TheCheckoutBonusItem },
        props: {
            isSidebar: Boolean,
            isActive: Boolean,
            bonuses: Object,
            isMadRobots: Boolean,
            currentStep: Number,
            isAuthorized: Boolean,
            productWrapPositions: Array,
        },
        data() {
            return {
                currentCoupon: "",
                certificate: "",
                currentIsActive: this.isActive,
                errorText: "",
                noticeTimer: null,
                isLoadingCoupon: false,
                isLoadingMadCoins: false,
                isLoadingCertificate: false,
                successCoupon: false,
                successCertificate: false,
            };
        },
        computed: {
            placeholderForMadCoins() {
                return this.bonuses.madCoins.isApply
                    ? this.bonuses.madCoins.applied
                    : this.bonuses.madCoins.total;
            },
        },
        watch: {
            isActive: {
                handler(updated) {
                    this.currentIsActive = updated;
                },
            },
            errorText: {
                handler(updatedText) {
                    if (updatedText.length > 0) {
                        this.startTimer();
                    }
                },
            },
            bonuses: {
                handler(updated) {
                    if (updated.coupon.discountPercent > 0) {
                        this.successCoupon = true;
                        this.currentCoupon = this.bonuses.coupon.name || "";
                    } else {
                        this.successCoupon = false;
                        this.currentCoupon = "";
                    }

                    if (updated.certificate.name) {
                        this.successCertificate = true;
                        this.certificate = this.bonuses.certificate.name;
                    } else {
                        this.successCertificate = false;
                        this.certificate = "";
                    }
                },
                deep: true,
            },
        },
        methods: {
            submitBonus({ name, value }) {
                this.updateBonus({ type: "add", name, value });
            },
            removeBonus({ name, value }) {
                this.updateBonus({ type: "remove", name, value });
            },
            updateBonus({ type, name, value }) {
                if (name === "coupon") this.isLoadingCoupon = true;
                if (name === "certificate") this.isLoadingCertificate = true;
                if (name === "madCoin") this.isLoadingMadCoins = true;
                this.resetError();

                let filteredAction = {
                    coupon: ["coupon", "clear_coupons"],
                    certificate: ["gift_coupon", "clear_cards"],
                    madCoin: ["apply_bonus", "clear_bonus"],
                };
                let filteredTypes = {
                    add: filteredAction[name][0],
                    remove: filteredAction[name][1],
                };

                let payload = {};
                payload[filteredTypes[type]] = value;
                payload.productWrapPositions = this.productWrapPositions;

                this.$store.orderApi.checkoutResult(payload).then((res) => {
                    if (name === "coupon") {
                        this.responseForCoupon({ type, res });
                        this.isLoadingCoupon = false;
                    }
                    if (name === "certificate") {
                        this.responseForCertificate({ type, res });
                        this.isLoadingCertificate = false;
                    }
                    if (name === "madCoin") {
                        this.responseForMadCoin({ type, res });
                        this.isLoadingMadCoins = false;
                    }
                });
            },
            responseForCoupon({ type, res }) {
                if (type === "add") {
                    if (Object.keys(res.coupons).length > 0) {
                        this.successCoupon = true;

                        this.$emit("update-checkout-order", res);

                        if (this.currentStep === 0) {
                            sendAnalytics.send(
                                sendAnalytics.firstStepCheckoutEvents.cartUsePromocode
                            );
                        }
                        if (this.currentStep === 1) {
                            sendAnalytics.send(
                                sendAnalytics.secondStepCheckoutEvents.orderUsePromocode
                            );
                        }
                        if (this.currentStep === 2) {
                            sendAnalytics.send(
                                sendAnalytics.thirdStepCheckoutEvents.orderUsePromocode
                            );
                        }

                        sendAnalytics.send(sendAnalytics.events.promocode);
                    } else {
                        this.currentCoupon = "";
                        this.errorText =
                            "Данный промокод уже был активирован или не существует";
                    }
                }

                if (type === "remove") {
                    this.currentCoupon = "";
                    this.successCoupon = false;
                    this.$emit("update-checkout-order", res);
                }
            },
            responseForCertificate({ type, res }) {
                if (type === "add") {
                    if (res.isErrorCertificate) {
                        this.certificate = "";
                        this.errorText =
                            "Данный сертификат уже был активирован или не существует";
                    } else {
                        this.successCertificate = true;
                        this.$emit("update-checkout-order", res);
                        if (this.currentStep === 0) {
                            sendAnalytics.send(
                                sendAnalytics.firstStepCheckoutEvents.cartUseCertificate
                            );
                        }
                        if (this.currentStep === 1) {
                            sendAnalytics.send(
                                sendAnalytics.secondStepCheckoutEvents.orderUseCertificate
                            );
                        }
                        if (this.currentStep === 2) {
                            sendAnalytics.send(
                                sendAnalytics.thirdStepCheckoutEvents.orderUseCertificate
                            );
                        }
                        sendAnalytics.send(sendAnalytics.events.certificate);
                    }
                }

                if (type === "remove") {
                    this.$emit("update-checkout-order", res);
                }
            },
            responseForMadCoin({ type, res }) {
                this.$emit("update-checkout-order", res);
                if (type === "add") {
                    if (this.currentStep === 0) {
                        sendAnalytics.send(
                            sendAnalytics.firstStepCheckoutEvents.cartUseMadcoin
                        );
                    }
                    if (this.currentStep === 1) {
                        sendAnalytics.send(
                            sendAnalytics.secondStepCheckoutEvents.orderUseMadcoin
                        );
                    }
                    if (this.currentStep === 2) {
                        sendAnalytics.send(
                            sendAnalytics.thirdStepCheckoutEvents.orderUseMadcoin
                        );
                    }
                }
            },
            showDropdown() {
                this.currentIsActive = !this.currentIsActive;
            },
            resetError() {
                this.errorText = "";
            },
            startTimer() {
                clearTimeout(this.noticeTimer);
                this.noticeTimer = setTimeout(() => {
                    this.resetError();
                }, 3500);
            },
        },
    };
</script>

<template>
    <div
        class="the-checkout-bonuses"
        :class="{
            'the-checkout-style__box-shadow': !isSidebar,
            'the-checkout-bonuses--sidebar': isSidebar && isMadRobots,
            'the-checkout-bonuses--is-active': currentIsActive && isMadRobots,
            'the-checkout-bonuses--sidebar-single': isSidebar && !isMadRobots,
        }"
    >
        <div
            v-show="isSidebar && isMadRobots"
            class="the-checkout-bonuses__title"
            @click="showDropdown"
        >
            <div class="the-checkout-bonuses__title-text">
                Промокод, сертификат или добавить мэдкоины
            </div>
            <button class="the-checkout-bonuses__title-button outline">
                <svg width="16" height="8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1l7 6 7-6" stroke-width="1.5" stroke-linecap="round" />
                </svg>
            </button>
        </div>
        <div
            class="the-checkout-bonuses__dropdown"
            :class="{ 'the-checkout-bonuses__dropdown--madrobots': isMadRobots }"
        >
            <the-checkout-bonus-item
                data-test="coupon"
                v-model="currentCoupon"
                name="coupon"
                placeholder="Введите промокод"
                :is-sidebar="isSidebar"
                :is-success="successCoupon"
                :single-mode="true"
                :is-filled="isAuthorized"
                :is-loading="isLoadingCoupon"
                :is-mad-robots="isMadRobots"
                @submit-bonus="submitBonus"
                @remove-bonus="removeBonus"
            ></the-checkout-bonus-item>

            <the-checkout-bonus-item
                v-show="isMadRobots"
                v-model="certificate"
                name="certificate"
                placeholder="Введите сертификат"
                :is-sidebar="isSidebar"
                :is-loading="isLoadingCertificate"
                :is-success="successCertificate"
                :single-mode="true"
                :is-mad-robots="isMadRobots"
                @submit-bonus="submitBonus"
                @remove-bonus="removeBonus"
            >
            </the-checkout-bonus-item>

            <the-checkout-bonus-item
                v-show="isMadRobots && isAuthorized"
                v-model="bonuses.madCoins.applied"
                name="madCoin"
                is-mad-coin
                :is-mad-coin-show="bonuses.madCoins.totalCount > 0"
                :placeholder="placeholderForMadCoins"
                :is-loading="isLoadingMadCoins"
                :is-sidebar="isSidebar"
                :is-success="bonuses.madCoins.isApply"
                :is-mad-robots="isMadRobots"
                :mad-coins="bonuses.madCoins"
                @submit-bonus="submitBonus"
                @remove-bonus="removeBonus"
            >
            </the-checkout-bonus-item>
        </div>
        <div v-show="errorText" class="the-checkout-bonuses__notices" @click="resetError">
            <div class="the-checkout-bonuses__notices-text">
                {{ errorText }}
                <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M2.86177 12.1952C2.60142 12.4555 2.60142 12.8776 2.86177 13.138C3.12212 13.3983 3.54423 13.3983 3.80457 13.138L7.99984 8.9427L12.1952 13.138C12.4555 13.3984 12.8776 13.3984 13.138 13.138C13.3983 12.8777 13.3983 12.4556 13.138 12.1952L8.94265 7.99989L13.138 3.80457C13.3983 3.54423 13.3983 3.12212 13.138 2.86177C12.8776 2.60142 12.4555 2.60142 12.1952 2.86177L7.99984 7.05708L3.80457 2.86182C3.54423 2.60147 3.12212 2.60147 2.86177 2.86182C2.60142 3.12217 2.60142 3.54428 2.86177 3.80463L7.05703 7.99989L2.86177 12.1952Z"
                        fill="#555770"
                    />
                </svg>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .the-checkout-bonuses {
        padding: 15px;

        @include bp($bp-desktop-sm) {
            padding: 24px 20px;
            &:not(.the-checkout-bonuses--sidebar) {
                .the-checkout-bonuses__dropdown {
                    margin-bottom: -16px;
                }
            }
        }
    }

    .the-checkout-bonuses__dropdown {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        margin: 0 -8px;

        @include bp($bp-desktop-sm) {
            flex-direction: row;
        }
    }

    .the-checkout-bonuses__title {
        display: flex;
        margin: 2px 0;
        padding-left: 12px;
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        color: $checkout-base-text-color;
        cursor: pointer;
        user-select: none;
    }

    .the-checkout-bonuses__title-text {
        font-size: 14px;
    }

    .the-checkout-bonuses__title-button {
        margin-left: 5px;
        margin-right: 6px;
        @include box(40px);
        border-radius: $checkout-button-radius--small;
        border: $checkout-control-border-width solid $checkout-button-color;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        svg path {
            stroke: $checkout-button-color;
        }
        @include bp($bp-desktop-sm) {
            svg path {
                transition: stroke 0.2s ease-in;
            }
            transition: border-color 0.2s ease-in;
            &:hover {
                border-color: $checkout-base-color--hover;
                svg path {
                    stroke: $checkout-base-color--hover;
                }
            }
        }
    }

    .the-checkout-bonuses__notices {
        margin-top: 20px;
        padding: 0 3px;
        cursor: pointer;
    }

    .the-checkout-bonuses__notices-text {
        display: flex;
        font-size: 14px;
        line-height: 16px;
        color: $checkout-danger-color;

        svg {
            flex-shrink: 0;
            margin-left: 6px;
        }
    }

    //
    // --mod
    //

    .the-checkout-bonuses--sidebar {
        border: $checkout-control-border-width solid $checkout-control-border-color;
        border-radius: $checkout-control-radius;
        padding: 10px;

        .the-checkout-bonuses__dropdown {
            display: none;
            margin: 0;
        }
    }

    .the-checkout-bonuses--sidebar-single {
        padding: 0;
    }

    .the-checkout-bonuses--is-active {
        .the-checkout-bonuses__dropdown {
            display: block;
            margin-top: 20px;
        }

        .the-checkout-bonuses__title-button {
            svg {
                transform: rotate(180deg);
            }
        }
    }

    //
    // --mod
    //

    .the-checkout-bonuses__dropdown--madrobots {
        .the-checkout-bonus-item {
            &:nth-child(2) {
                margin-top: 10px;
                margin-bottom: 10px;

                @include bp($bp-desktop-sm) {
                    margin-top: 0;
                    margin-bottom: 0;

                    &.the-checkout-bonus-item--sidebar {
                        margin-top: 10px;
                    }
                }
            }
        }
    }
</style>
