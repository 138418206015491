<script>
    import TheCheckoutProgressBar from "@/TheCheckout/TheCheckoutProgressBar";
    export default {
        name: "TheCheckoutHeader",
        components: { TheCheckoutProgressBar },
        props: {
            currentStep: Number,
            countOrders: Number,
        },
        computed: {
            nameForSteps() {
                let filteredText = {
                    0: "Обратно к покупкам",
                    1: "Обратно в корзину",
                    2: "Обратно к доставке",
                    3: "Обратно к покупкам",
                };
                let filteredTitle = {
                    0: "Корзина",
                    1: "Доставка",
                    2: "Оплата",
                    3: "Заказ оформлен",
                };

                return {
                    text: filteredText[this.currentStep],
                    title: filteredTitle[this.currentStep],
                };
            },
        },
        methods: {
            goTo() {
                if (this.currentStep === 0) window.location.href = "/";
                if (this.currentStep === 1) this.$emit("go-prev-step");
                if (this.currentStep === 2) this.$emit("go-prev-step");
                if (this.currentStep === 3) window.location.href = "/";
            },
        },
    };
</script>

<template>
    <div class="the-checkout-header">
        <div class="the-checkout-header__title">{{ nameForSteps.title }}</div>
        <div class="the-checkout-header__progress">
            <the-checkout-progress-bar :current-step="currentStep"></the-checkout-progress-bar>
        </div>
        <button
            class="the-checkout-header__steps outline"
            :class="{ 'is-hidden': currentStep > 0 }"
            @click="goTo"
        >
            <span>
                <svg
                    v-show="nameForSteps.text"
                    width="4"
                    height="9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M3.744.142c.283.236.34.68.128.995L1.6 4.5l2.272 3.363c.212.314.155.76-.128.995-.283.235-.684.172-.896-.142L0 4.5 2.848.284c.212-.314.613-.377.896-.142z"
                        fill="currentColor"
                    />
                </svg>
            </span>

            <div>{{ nameForSteps.text }}</div>
        </button>
    </div>
</template>

<style lang="scss">
    .the-checkout-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        flex-wrap: wrap;

        @include bp($bp-desktop-sm) {
            flex-wrap: nowrap;
            margin-bottom: 30px;
        }
    }

    .the-checkout-header__title {
        font-weight: $checkout-title-weight;
        font-size: 16px;
        line-height: 20px;
        color: $checkout-title-color;
        width: calc(100% - 150px);

        @include bp($bp-desktop-sm) {
            font-size: 25px;
            line-height: 30px;
            width: 118px;
            white-space: nowrap;
        }
    }

    .the-checkout-header__progress {
        width: 150px;

        @include bp($bp-desktop-sm) {
            max-width: 600px;
            width: 100%;
        }
    }

    .the-checkout-header__steps {
        width: 185px;
        font-size: 14px;
        line-height: 16px;
        color: $checkout-button-link-color;
        font-weight: $checkout-button-link-weight;
        cursor: pointer;
        margin-top: 14px;
        display: flex;
        align-items: baseline;

        svg {
            display: block;
            margin-right: 3px;
        }

        @include bp($bp-desktop-sm) {
            justify-content: flex-end;
            align-items: center;
            margin-top: 0;
            font-size: 16px;
            line-height: 19px;
            text-align: right;
            transition: opacity ease-in-out 0.3s;
            &:hover {
                opacity: 0.8;
            }
        }
        &.is-hidden {
            display: none;
            @include bp($bp-desktop-sm) {
                display: flex;
            }
        }
    }
</style>
