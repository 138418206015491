<script>
    import { debounce } from "@/utils";
    import TheCheckoutDeliveryPickupItem from "@/TheCheckout/TheCheckoutDeliveryPickupItem";

    export default {
        name: "TheCheckoutDeliveryPickup",
        components: { TheCheckoutDeliveryPickupItem },
        props: {
            pickupList: Array,
            selectedPickup: Object,
            selectedCity: String,
        },
        data() {
            return {
                searchInput: "",
                debouncedSearchInput: "",
                tabName: "list",
                isEmpty: false,
            };
        },
        watch: {
            searchInput: debounce(function (newVal) {
                this.debouncedSearchInput = newVal;
            }, 500),
            filteredPickupListLength(length) {
                length ? (this.isEmpty = false) : (this.isEmpty = true);
            },
        },
        computed: {
            filteredPickupList() {
                return this.pickupList.filter((pickup) => {
                    let address = pickup.forSearch.toLowerCase().replace(/москва|м\./, "");
                    let state = ~address.indexOf(this.debouncedSearchInput.toLowerCase());

                    let iconImageHref = state
                        ? pickup.isMr
                            ? "/static/mp-free.png"
                            : "/static/mp.png"
                        : pickup.isMr
                        ? "/static/mp-free_disable.png"
                        : "/static/mp_disable.png";

                    this.$emit("change-icons-map", { count: pickup.count, iconImageHref });

                    return state;
                });
            },
            filteredPickupListLength() {
                return this.filteredPickupList.length;
            },
        },
        mounted() {
            document.addEventListener("keyup", this.changeFocusElement);
        },
        beforeDestroy() {
            document.removeEventListener("keyup", this.changeFocusElement);
        },
        methods: {
            selectPickup(pickup) {
                this.$emit("select-pickup", pickup);
            },
            close() {
                this.$emit("close-pickup-popup");
            },
            changeFocusElement({ key }) {
                if (key === "ArrowLeft") {
                    this.$refs.pickupInput.focus();
                }
                if (key === "ArrowRight") {
                    this.$refs.pickupMap.focus();
                }
            },
        },
    };
</script>

<template>
    <div class="the-checkout-delivery-pickup">
        <div class="the-checkout-delivery-pickup__close" @click="close">
            <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M4.293 18.293a1 1 0 101.414 1.414L12 13.414l6.293 6.293a1 1 0 001.414-1.414L13.414 12l6.293-6.293a1 1 0 00-1.414-1.414L12 10.586 5.707 4.293a1 1 0 00-1.414 1.414L10.586 12l-6.293 6.293z"
                    fill="#555770"
                />
            </svg>
        </div>
        <div class="the-checkout-delivery-pickup__tabs-list">
            <div
                class="the-checkout-delivery-pickup__tab"
                :class="{ 'is-active': tabName === 'list' }"
                @click="tabName = 'list'"
            >
                Списком
            </div>
            <div
                class="the-checkout-delivery-pickup__tab"
                :class="{ 'is-active': tabName === 'map' }"
                @click="tabName = 'map'"
            >
                На карте
            </div>
        </div>
        <div
            class="the-checkout-delivery-pickup__sidebar"
            :class="{ 'is-active': tabName === 'list' }"
        >
            <label class="the-checkout-delivery-pickup__search">
                <input
                    class="the-checkout-delivery-pickup__input"
                    :class="{ selected: searchInput.length > 0 }"
                    type="text"
                    placeholder="Введите улицу или станцию метро"
                    autocomplete="off"
                    v-model="searchInput"
                    ref="pickupInput"
                />
            </label>
            <div class="the-checkout-delivery-pickup__list">
                <div v-if="isEmpty && searchInput" class="the-checkout-delivery-pickup__empty">
                    По этому адресу в городе {{ selectedCity }} ничего не найдено
                </div>
                <the-checkout-delivery-pickup-item
                    v-for="pickup in filteredPickupList"
                    :key="pickup.id"
                    :pickup="pickup"
                    :item-click="selectPickup"
                    :selected-id="selectedPickup.id"
                ></the-checkout-delivery-pickup-item>
            </div>
        </div>
        <div
            id="madMap"
            class="the-checkout-delivery-pickup__map"
            :class="{ 'is-active': tabName === 'map' }"
            ref="pickupMap"
            tabindex="0"
        ></div>
    </div>
</template>

<style lang="scss">
    .the-checkout-delivery-pickup {
        height: 100%;

        @include bp($bp-desktop-sm) {
            display: flex;
            height: 430px;
        }
    }

    .the-checkout-delivery-pickup__close {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 1;

        @include bp($bp-desktop-sm) {
            display: none;
        }
    }

    .the-checkout-delivery-pickup__tabs-list {
        display: none;

        @include bp_max($bp-desktop-sm_max) {
            display: flex;
            width: calc(100% - 34px);
            margin-bottom: 20px;
            border: 1px solid #ebebf0;
            border-radius: $checkout-button-radius--small;
        }
    }

    .the-checkout-delivery-pickup__tab {
        font-weight: 500;
        font-size: 14px;
        line-height: 28px;
        color: $checkout-base-color;
        height: 30px;
        width: 50%;
        text-align: center;

        &.is-active {
            color: #fff;
            background: $checkout-base-color;
            border-radius: $checkout-button-radius--small;
        }
    }

    .the-checkout-delivery-pickup__sidebar {
        display: none;
        height: calc(100% - 50px);

        &.is-active {
            display: block;
        }

        @include bp($bp-desktop-sm) {
            display: block;
            width: 230px;
            height: 100%;
        }

        @include bp($bp-desktop-md) {
            width: 295px;
        }
    }

    .the-checkout-delivery-pickup__search {
        display: block;
        margin-bottom: 10px;
    }

    .the-checkout-delivery-pickup__input {
        width: 100%;
        padding: 14px;
        border-radius: $checkout-control-radius;
        border: $checkout-control-border-width solid $checkout-control-border-color;
        background-color: $checkout-control-input-bg;
        font-size: 16px;
        line-height: 20px;
        color: $checkout-control-color;
        transition: border-color 0.3s ease-in-out;
        &.selected {
            border-color: $checkout-control-border-color--active;
        }
        &:not(.selected):hover,
        &:not(.selected):focus {
            border-color: $checkout-control-border-color--hover;
        }
    }

    .the-checkout-delivery-pickup__list {
        overflow-y: auto;
        height: calc(100% - 60px);

        @include bp($bp-desktop-sm) {
            max-height: 365px;
        }
    }

    .the-checkout-delivery-pickup__empty {
        padding: 10px;
        color: #8f90a6;
        font-size: 14px;
        line-height: 22px;
    }

    .the-checkout-delivery-pickup__map {
        display: none;
        margin-left: -10px;
        width: calc(100% + 20px);
        height: calc(100% - 40px);
        outline: none;

        &.is-active {
            display: block;
        }

        @include bp($bp-desktop-sm) {
            display: block;
            padding-left: 20px;
            width: calc(100% - 230px);
            height: 100%;
            &:focus-visible {
                @include outline;
            }
        }

        @include bp($bp-desktop-md) {
            width: calc(100% - 295px);
        }
    }
</style>
