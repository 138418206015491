<script>
    import { sendAnalytics } from "@/core/SendAnalytics";
    import TheCheckoutInput from "@/TheCheckout/TheCheckoutInput";

    export default {
        name: "TheCheckoutDeliveryAddress",
        props: {
            address: Object,
            isAddressForm: Boolean,
            errorList: Object,
        },
        methods: {
            changeInput() {
                this.$emit("change-input");
            },
            sendDL(name) {
                sendAnalytics.send(sendAnalytics.secondStepCheckoutEvents.orderEditAddress, {
                    dynamicName: name,
                });
                sendAnalytics.send(sendAnalytics.events.delivery);
            },
            resetError(name) {
                this.$emit("reset-error", name);
            },
            setLS(obj) {
                this.$emit("set-from-local-storage", obj);
            },
        },
        components: {
            TheCheckoutInput,
        },
    };
</script>

<template>
    <div class="the-checkout-delivery-address">
        <div v-show="isAddressForm" class="the-checkout-delivery-address__fields">
            <the-checkout-input
                v-model="address.street"
                placeholder="Улица"
                :error="errorList.street"
                name="street"
                @focus="sendDL('street')"
                @keyup="changeInput"
                @reset-error="resetError"
                @set-from-local-storage="setLS"
            ></the-checkout-input>

            <div class="the-checkout-delivery-address__row">
                <the-checkout-input
                    v-model="address.house"
                    placeholder="Дом"
                    :error="errorList.house"
                    name="house"
                    @focus="sendDL('house')"
                    @keyup="changeInput"
                    @reset-error="resetError"
                    @set-from-local-storage="setLS"
                ></the-checkout-input>

                <the-checkout-input
                    v-model="address.build"
                    placeholder="Строение"
                    :error="errorList.build"
                    name="build"
                    @focus="sendDL('build')"
                    @keyup="changeInput"
                    @reset-error="resetError"
                    @set-from-local-storage="setLS"
                ></the-checkout-input>

                <the-checkout-input
                    v-model="address.housing"
                    name="housing"
                    placeholder="Корпус"
                    :error="errorList.housing"
                    @focus="sendDL('housing')"
                    @keyup="changeInput"
                    @reset-error="resetError"
                    @set-from-local-storage="setLS"
                ></the-checkout-input>

                <the-checkout-input
                    v-model="address.flat"
                    name="flat"
                    placeholder="Квартира"
                    @focus="sendDL('flat')"
                    @set-from-local-storage="setLS"
                ></the-checkout-input>
            </div>
        </div>

        <the-checkout-input
            v-model="address.comment"
            placeholder="Комментарий, если нужно"
            @focus="sendDL('comment')"
        ></the-checkout-input>
    </div>
</template>

<style lang="scss">
    .the-checkout-delivery-address {
    }

    .the-checkout-delivery-address__fields {
        .the-checkout-input__error {
            display: none !important;
        }
    }
    .the-checkout-delivery-address__row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -3px 6px;

        @include bp($bp-desktop-sm) {
            margin: 0 -5px 10px;
        }

        .the-checkout-input {
            width: calc(100% / 2 - 6px);
            margin: 6px 3px 0;

            @include bp($bp-desktop-sm) {
                width: calc(100% / 4 - 10px);
                margin: 10px 5px 0;
            }
        }
    }
</style>
