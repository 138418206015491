<script>
    import { apiUrl } from "@/utils";

    export default {
        name: "TheCheckoutPackingPatterns",
        props: {
            selectedProduct: Object,
            isSingle: Boolean,
        },
        computed: {
            allPatterns() {
                return this.selectedProduct?.packing?.giftWraps || [];
            },
            commonPatterns() {
                return this.allPatterns.filter((pattern) => !pattern.any);
            },
            anyPatterns() {
                return this.allPatterns.filter((pattern) => pattern.any);
            },
        },
        methods: {
            apiUrl,
            changePatternHandler(pattern) {
                if (pattern.id === this.selectedProduct.packing.selected) {
                    this.$emit("on-clear-pattern");
                } else {
                    this.$emit("on-select-pattern", pattern.id);
                }
            },
        },
    };
</script>

<template>
    <div class="the-checkout-packing-patterns" :class="{ 'is-single': isSingle }">
        <div class="the-checkout-packing-patterns__list">
            <div
                class="the-checkout-packing-pattern"
                :class="{
                    'the-checkout-packing-pattern--selected':
                        pattern.id === selectedProduct.packing.selected,
                }"
                v-for="pattern in commonPatterns"
                :key="pattern.id"
                @click="changePatternHandler(pattern)"
            >
                <span class="the-checkout-packing-pattern__image">
                    <img :src="apiUrl(pattern.image)" :alt="pattern.name" />
                </span>
                <span class="the-checkout-packing-pattern__title">
                    {{ pattern.name }}
                    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M2.86 12.2a.67.67 0 1 0 .95.94L8 8.94l4.2 4.2a.67.67 0 1 0 .94-.94L8.94 8l4.2-4.2a.67.67 0 0 0-.94-.94L8 7.06l-4.2-4.2a.67.67 0 1 0-.94.94L7.06 8l-4.2 4.2Z"
                        />
                    </svg>
                </span>
            </div>
        </div>

        <div
            class="the-checkout-packing-any"
            :class="{
                'the-checkout-packing-any--selected':
                    pattern.id === selectedProduct.packing.selected,
            }"
            v-for="pattern in anyPatterns"
            :key="pattern.id"
            @click="changePatternHandler(pattern)"
        >
            <div class="the-checkout-packing-any__title">Мне любую упаковку</div>
            <div class="the-checkout-packing-any__text">
                Наш сотрудник подберет для вас праздничную упаковку!
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .the-checkout-packing-patterns {
        &.is-single {
            .the-checkout-packing-any {
                margin-bottom: 20px;
            }
        }
    }

    .the-checkout-packing-patterns__list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -16px;
        @include bp($bp-desktop-sm) {
            margin: 0 -8px;
        }
    }

    .the-checkout-packing-pattern {
        flex-basis: calc(50% - 12px);
        max-width: calc(50% - 12px);
        margin: 0 6px 20px;
        border-radius: $checkout-packing-radius;
        border: $checkout-packing-border-width solid transparent;
        overflow: hidden;
        transition: border-color 0.2s ease-in;
        @include bp($bp-desktop-sm) {
            flex-basis: calc(25% - 16px);
            max-width: calc(25% - 16px);
            margin: 0 8px 20px;
            cursor: pointer;
            &:hover {
                &:not(.the-checkout-packing-pattern--selected) {
                    border-color: $checkout-packing-border-color;
                }
            }
        }
    }

    .the-checkout-packing-pattern--selected {
        border-color: $checkout-packing-border-color--selected;
        .the-checkout-packing-pattern__title {
            svg {
                opacity: 1;
                visibility: visible;
            }
        }
        @include bp($bp-desktop-sm) {
            .the-checkout-packing-pattern__title {
                &:hover {
                    color: $checkout-packing-danger-color;
                }
            }
        }
    }

    .the-checkout-packing-pattern__image {
        position: relative;
        display: block;
        img {
            @include center-absolute(0);
        }
        &::before {
            content: "";
            display: block;
            padding-bottom: 100%;
        }
    }

    .the-checkout-packing-pattern__title {
        position: relative;
        display: block;
        margin: 11px 15px 13px;
        padding-right: 20px;
        color: var(--primary-text);
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        svg {
            position: absolute;
            right: 0;
            top: 50%;
            fill: currentColor;
            opacity: 0;
            visibility: hidden;
            transform: translateY(-50%);
            transition: fill 0.2s ease-in, opacity 0.2s ease-in;
        }
        @include bp($bp-desktop-sm) {
            margin: 11px 13px 13px;

            font-size: 16px;
            line-height: 20px;
            transition: color 0.2s ease-in;
        }
    }

    .the-checkout-packing-any {
        position: relative;
        padding: 15px;
        border: $checkout-packing-border-width solid $checkout-packing-border-color;
        border-radius: $checkout-button-radius--small;
        transition: border-color 0.2s ease-in;
        &::before,
        &::after {
            content: "";
            position: absolute;
            border-radius: 50%;
        }
        &::before {
            top: 15px;
            right: 15px;
            width: 20px;
            height: 20px;
            border: $checkout-packing-border-width solid $checkout-packing-border-color;
            transition: border-color 0.2s ease-in;
        }
        &::after {
            top: 19px;
            right: 19px;
            width: 12px;
            height: 12px;
            background-color: $checkout-packing-border-color--selected;
            opacity: 0;
            transition: opacity 0.2s ease;
        }
        @include bp($bp-desktop-sm) {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            padding: 0;
            padding-left: 40px;
            border: none;
            cursor: pointer;
            &::before {
                top: 0;
                left: 15px;
            }
            &::after {
                top: 4px;
                left: 19px;
            }
        }
    }

    .the-checkout-packing-any--selected {
        border-color: $checkout-packing-border-color--selected;
        &::before {
            border-color: $checkout-packing-border-color--selected;
        }
        &::after {
            opacity: 1;
        }
    }

    .the-checkout-packing-any__title {
        margin-bottom: 4px;
        color: var(--primary-text);
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        @include bp($bp-desktop-sm) {
            margin-bottom: 0;
            margin-right: 14px;
            font-size: 16px;
        }
    }

    .the-checkout-packing-any__text {
        color: var(--secondary-text);
        font-size: 12px;
        line-height: 20px;
    }
</style>
