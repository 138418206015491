<script>
    import TheCheckoutButtonPreloader from "@/TheCheckout/TheCheckoutButtonPreloader";

    export default {
        name: "TheCheckoutNavigationMobile",
        components: { TheCheckoutButtonPreloader },
        props: {
            currentStep: Number,
            productsText: String,
            calcTotalPrice: [String, Number],
            isLoading: Boolean,
        },
        computed: {
            nameSteps() {
                let filteredNames = {
                    0: "К оформлению",
                    1: "К оплате",
                    2: "Оформить заказ",
                };
                return filteredNames[this.currentStep];
            },
        },
        methods: {
            nextStep() {
                this.$emit("next-step-from-mobile");
            },
        },
    };
</script>

<template>
    <div class="the-checkout-navigation-mobile">
        <the-checkout-button-preloader v-show="isLoading"></the-checkout-button-preloader>
        <div class="the-checkout-navigation-mobile__text" :class="{ 'is-hidden': isLoading }">
            {{ Math.floor(calcTotalPrice) | formatPrice }} ₽
        </div>
        <div
            class="the-checkout-navigation-mobile__sub-text"
            :class="{ 'is-hidden': isLoading }"
        >
            {{ productsText }}
        </div>
        <button
            class="
                the-checkout-navigation-mobile__text the-checkout-navigation-mobile__text--last
            "
            :class="{ 'is-hidden': isLoading }"
            @click="nextStep"
        >
            {{ nameSteps }}
        </button>
    </div>
</template>

<style lang="scss">
    .the-checkout-navigation-mobile {
        display: flex;
        align-items: center;
        position: fixed;
        width: calc(100% - 20px);
        height: 40px;
        left: 10px;
        bottom: 24px;
        background: $checkout-button-color;
        border-radius: $checkout-button-radius--small;
        padding: 0 10px;
        z-index: 2000;
        transition: all 0.3s ease-in-out;

        &.is-hidden {
            opacity: 0;
            transform: scale(0.9);
            pointer-events: none;
        }

        @include bp($bp-desktop-sm) {
            display: none;
        }

        &:after {
            content: "";
            @include center-absolute(0);
            margin: auto;
            border-radius: $checkout-button-radius--small;
            box-shadow: 0 0 40px #f2f2f5;
            z-index: -1;
        }
    }

    .the-checkout-navigation-mobile__text,
    .the-checkout-navigation-mobile__sub-text {
        color: $checkout-button-text;
        transition: opacity 0.1s ease-in;
        &.is-hidden {
            opacity: 0;
        }
    }

    .the-checkout-navigation-mobile__text {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
    }

    .the-checkout-navigation-mobile__sub-text {
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
        margin-left: 10px;
    }

    //
    // --mod
    //

    .the-checkout-navigation-mobile__text--last {
        margin-left: auto;
        line-height: 40px;
    }
</style>
