<script>
    import { numberFormat } from "@/utils";

    export default {
        name: "TheCheckoutDeliveryCourierItem",
        props: {
            courier: Object,
            deliveryId: String,
        },
        computed: {
            calcLabel() {
                return this.courier.label
                    ? `the-checkout-delivery-courier-item__label--${this.courier.label}`
                    : "";
            },
            price() {
                let prefix = this.courier.isMr ? "" : "";
                return this.courier.price
                    ? prefix + numberFormat(this.courier.price, 0, ".", "&#8201;") + " ₽"
                    : "Бесплатно";
            },
        },
        methods: {
            selectCourier() {
                this.$emit("select-courier", {
                    id: this.courier.id,
                    service: this.courier.service,
                    price: this.courier.price,
                });
            },
        },
    };
</script>

<template>
    <div
        class="the-checkout-delivery-courier-item"
        :class="{ 'is-active': deliveryId == this.courier.id }"
        :tabindex="deliveryId === this.courier.id ? -1 : 0"
        @keypress.enter="selectCourier"
        @click="selectCourier"
    >
        <div class="the-checkout-delivery-courier-item__radio"></div>
        <div class="the-checkout-delivery-courier-item__info">
            <div class="the-checkout-delivery-courier-item__name">
                {{ courier.company }}
            </div>
            <div class="the-checkout-delivery-courier-item__date" :title="courier.date">
                {{ courier.date }}
            </div>
        </div>
        <div class="the-checkout-delivery-courier-item__price" v-html="price"></div>
        <div class="the-checkout-delivery-courier-item__label" :class="calcLabel">
            {{ courier.textLabel }}
        </div>
    </div>
</template>

<style lang="scss">
    .the-checkout-delivery-courier-item {
        width: 100%;
        min-height: 62px;
        display: flex;
        position: relative;
        border: $checkout-control-border-width solid $checkout-control-border-color;
        border-radius: $checkout-control-radius;
        padding: 9px;
        background: #ffffff;
        transition: 0.3s ease-out all;
        cursor: pointer;
        margin-bottom: 20px;
        color: $checkout-base-text-color;
        outline: none;

        @include bp($bp-desktop-sm) {
            width: calc(100% / 2 - 10px);
            min-height: 80px;
            padding: 15px;
            margin: 0 5px 10px;
            &:focus-visible {
                @include outline;
            }
        }

        @include bp($bp-desktop-md) {
            width: calc(100% / 3 - 10px);
        }

        &.is-active {
            background: $checkout-control-fill--hover;
            border-color: $checkout-control-border-color--active;
            pointer-events: none;
            color: #1c1c28;

            .the-checkout-delivery-courier-item__radio {
                border-color: $checkout-control-border-color--active;

                &:after {
                    opacity: 1;
                    background-color: $checkout-checkbox-fill;
                }
            }
        }

        &:hover {
            @include bp($bp-desktop-sm) {
                border-color: $checkout-control-border-color--active;

                .the-checkout-delivery-courier-item__radio {
                    border-color: $checkout-control-border-color--active;
                }
            }
        }
    }

    .the-checkout-delivery-courier-item__radio {
        position: relative;
        @include box(20px);
        border: $checkout-control-border-width solid $checkout-control-border-color;
        border-radius: 50%;
        flex-shrink: 0;
        transition: 0.3s ease-in-out all;

        &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            @include box(12px);
            border-radius: 50%;
            opacity: 0;
            background: $checkout-accent-color;
            transform: translate(-50%, -50%);
        }
    }

    .the-checkout-delivery-courier-item__info {
        margin-left: 8px;
    }

    .the-checkout-delivery-courier-item__name {
        font-size: 14px;
        line-height: 16px;

        @include bp($bp-desktop-sm) {
            font-size: $checkout-courier-item-font-size;
            line-height: 20px;
        }
    }

    .the-checkout-delivery-courier-item__date {
        margin-top: 5px;
        font-weight: 300;
        font-size: 14px;
        line-height: 16px;

        @include bp($bp-desktop-sm) {
            margin-top: 7px;
        }
    }

    .the-checkout-delivery-courier-item__price {
        //margin-left: auto;
        position: absolute;
        top: 9px;
        right: 11px;
        font-size: 14px;
        line-height: 16px;

        @include bp($bp-desktop-sm) {
            top: 15px;
            right: 15px;
            font-size: 16px;
            line-height: 20px;
        }
    }

    .the-checkout-delivery-courier-item__label {
        position: absolute;
        top: -14px;
        right: 11px;
        white-space: nowrap;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        padding: 3px 6px;
        border-radius: $checkout-label-radius;
        color: #fff;

        @include bp($bp-desktop-sm) {
            top: -10px;
            right: 15px;
        }
    }

    //
    // --mod
    //

    .the-checkout-delivery-courier-item__label--cheap {
        background: #52c41a;
    }
    .the-checkout-delivery-courier-item__label--fast {
        background: #e91e63;
    }
</style>
