<script>
    export default {
        name: "TheCheckoutPaymentMethodsItem",
        props: {
            payment: Object,
            activePaySystem: [String, Boolean, Number],
        },
        methods: {
            calcIcon(number) {
                return `the-checkout-payment-methods-item__icon--${number}`;
            },
            select() {
                this.$emit("select-payment", this.payment.id);
            },
        },
    };
</script>

<template>
    <div
        class="the-checkout-payment-methods-item"
        :class="{ 'is-active': payment.id === activePaySystem }"
        @click="select"
        @keypress.enter="select"
        :tabindex="payment.id === activePaySystem ? -1 : 0"
    >
        <div class="the-checkout-payment-methods-item__name">
            {{ payment.title }}
        </div>
        <div class="the-checkout-payment-methods-item__desc">
            {{ payment.desc }}
        </div>
        <div class="the-checkout-payment-methods-item__icons">
            <div
                v-for="icon in payment.icons"
                :key="icon"
                class="the-checkout-payment-methods-item__icon"
                :class="calcIcon(icon)"
            ></div>
        </div>
    </div>
</template>

<style lang="scss">
    .the-checkout-payment-methods-item {
        position: relative;
        width: 100%;
        padding: 9px 14px;
        background: #ffffff;
        border: $checkout-control-border-width solid $checkout-control-border-color;
        box-sizing: border-box;
        border-radius: $checkout-payment-box-radius;
        margin-bottom: 10px;
        transition: 0.3s ease-in-out all;
        cursor: pointer;
        outline: none;

        &:last-child {
            margin-bottom: 0;
        }

        @include bp($bp-tablet-sm) {
            padding: 19px;
        }

        @include bp($bp-desktop-sm-for-card) {
            width: calc(100% / 2 - 7px);
            margin-bottom: 0;

            &:hover {
                border-color: $checkout-control-border-color--hover;
            }

            &:focus-visible {
                @include outline;
            }

            &:first-child {
                margin-right: 14px;
            }
        }

        &.is-active {
            border-color: $checkout-control-border-color--active;
            background-color: $checkout-control-fill--hover;
            pointer-events: none;
        }
    }

    .the-checkout-payment-methods-item__name {
        font-size: 14px;
        font-weight: $checkout-payment-title-weight;
        line-height: 16px;
        color: $checkout-base-text-color;
        margin-bottom: 5px;

        @include bp($bp-desktop-sm) {
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 10px;
        }
    }

    .the-checkout-payment-methods-item__desc {
        font-size: 14px;
        font-weight: $checkout-payment-text-weight;
        line-height: 16px;
        color: $checkout-base-text-color;
        margin-bottom: 10px;

        @include bp($bp-desktop-sm) {
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 20px;
        }

        @include bp($bp-desktop-sm-for-card) {
            margin-bottom: 42px;
        }
    }

    .the-checkout-payment-methods-item__icons {
        display: flex;
        flex-wrap: wrap;

        @include bp($bp-desktop-sm) {
            margin: 0 -10px;
        }
    }

    .the-checkout-payment-methods-item__icon {
        position: relative;
        display: block;
        width: 60px;
        height: 36px;

        &:before {
            @include center-absolute(0);
            margin: auto;
            content: "";
        }

        &--1 {
            &:before {
                @include box(32px);
                background-image: url("~@/assets/images/checkout/pay/ico-flying-cash.svg");
                //.ico-flying-cash;
            }
        }

        &--2 {
            &:before {
                width: 46px;
                height: 13px;
                background-image: url("~@/assets/images/checkout/pay/ico-mir.svg");
                //.ico-mir;
            }
        }

        &--3 {
            &:before {
                width: 40px;
                height: 13px;
                background-image: url("~@/assets/images/checkout/pay/ico-visa.svg");
                //.ico-visa;
            }
        }

        &--4 {
            &:before {
                width: 32px;
                height: 25px;
                background-image: url("~@/assets/images/checkout/pay/ico-mc.svg");
                //.ico-mc;
            }
        }

        &--5 {
            &:before {
                width: 40px;
                height: 16px;
                background-image: url("~@/assets/images/checkout/pay/gpay.svg");
                //.ico-mc;
            }
        }

        &--6 {
            &:before {
                width: 40px;
                height: 16px;
                background-image: url("~@/assets/images/checkout/pay/ipay.svg");
                //.ico-mc;
            }
        }

        /*&--5 {*/
        /*    &:before {*/
        /*        .ico-ap;*/
        /*    }*/
        /*}*/

        /*&--6 {*/
        /*    box-shadow: none;*/
        /*    &:before {*/
        /*        .ico-google-pay-mark;*/
        /*    }*/
        /*}*/
    }

    //
    // --mod
    //

    .the-checkout-payment-methods-item--over-price {
        background: #fff4f8;
        border: none;
        pointer-events: none;

        .the-checkout-payment-methods-item__name {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #014462;
            margin-bottom: 4px;

            @include bp($bp-tablet-sm) {
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 11px;
            }
        }

        .the-checkout-payment-methods-item__desc {
            font-weight: 300;
            font-size: 12px;
            line-height: 16px;
            color: $checkout-base-text-color;
            margin-bottom: 0;

            @include bp($bp-tablet-sm) {
                line-height: 20px;
            }

            @include bp($bp-desktop-sm-for-card) {
                padding-right: 100px;
            }
        }
    }
</style>
