<script>
    import TheCheckoutSelectBox from "@/TheCheckout/TheCheckoutSelectBox";
    export default {
        name: "TheOrderItemControls",
        components: { TheCheckoutSelectBox },
        props: {
            product: Object,
            currentProperty: Object,
            currentParent: Object,
            parentItems: Object,
        },
        computed: {
            selectedColor() {
                return this.currentProperty.color;
            },
            selectedSize() {
                return this.currentProperty.size;
            },
            optionsForColor() {
                return this.currentParent.colorsList;
            },

            optionsForSizesInColor() {
                return this.sizesInColor[this.selectedColor].map((name) => {
                    return {
                        name,
                    };
                });
            },
            optionsForQuantity() {
                return [...Array(this.currentProperty.quantity).keys()].map((i) => {
                    return { name: i + 1 };
                });
            },
            sizesInColor() {
                return this.currentParent.sizesInColor;
            },
            sizes() {
                return this.currentParent.sizes;
            },
        },
        methods: {
            updateSelected({ option, type }) {
                let keyForSize = `${this.selectedColor}_${option.name}`;
                let productParentId = this.currentProperty.parentId;
                let map = this.parentItems[productParentId].map;

                let offerId = type === "size" ? map[keyForSize] : option.offerId;
                this.$emit("change-select", {
                    type,
                    offerId,
                    basketId: this.product.id,
                    quantity: option.name,
                    isUpSale: this.product.isUpSale || false,
                });
            },
        },
    };
</script>

<template>
    <div class="the-order-item-controls">
        <div v-if="optionsForColor" class="the-order-item-controls__col">
            <div
                class="the-order-item-controls__select"
                :class="{ 'the-order-item-controls__select--half': sizesInColor }"
            >
                <the-checkout-select-box
                    type="color"
                    :value="selectedColor"
                    :options="optionsForColor"
                    @input="updateSelected"
                ></the-checkout-select-box>
            </div>
            <div
                v-if="sizesInColor"
                class="
                    the-order-item-controls__select
                    the-order-item-controls__select--half
                    the-order-item-controls__select--last
                "
            >
                <the-checkout-select-box
                    type="size"
                    :value="selectedSize"
                    :options="optionsForSizesInColor"
                    @input="updateSelected"
                ></the-checkout-select-box>
            </div>
        </div>
        <div v-else-if="sizes" class="the-order-item-controls__col">
            <div class="the-order-item-controls__select">
                <the-checkout-select-box
                    v-if="sizesInColor"
                    type="size"
                    :value="selectedSize"
                    :options="optionsForSizesInColor"
                    @input="updateSelected"
                ></the-checkout-select-box>
            </div>
        </div>

        <div v-if="product.price" class="the-order-item-controls__col">
            <div
                class="
                    the-order-item-controls__select
                    the-order-item-controls__select--quantity
                    the-order-item-controls__select--desktop-md
                "
            >
                <the-checkout-select-box
                    type="quantity"
                    :value="product.quantity"
                    :options="optionsForQuantity"
                    @input="updateSelected"
                ></the-checkout-select-box>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .the-order-item-controls {
        display: flex;
        align-items: center;
        width: 100%;

        @include bp($bp-tablet-sm) {
            width: auto;
        }
    }

    .the-order-item-controls__col {
        &:nth-child(1) {
            display: flex;
            flex-grow: 1;
            max-width: calc(100% - 65px);

            @include bp($bp-tablet-sm) {
                display: block;
                width: auto;
                max-width: 100%;
            }
        }

        &:nth-child(2) {
            margin-left: 5px;

            @include bp($bp-tablet-sm) {
                margin-left: 16px;
            }
        }

        &:only-child {
            max-width: 100%;
        }
    }

    .the-order-item-controls__select {
        flex-grow: 1;
        flex-basis: 50%;
        @include bp($bp-tablet-sm) {
            width: 170px;
        }
    }

    //
    // --mod
    //

    .the-order-item-controls__select--last {
        margin-left: 5px;
        @include bp($bp-tablet-sm) {
            margin-left: 0;
            margin-top: 5px;
        }
    }
    .the-order-item-controls__select--half {
        max-width: calc(50% - 2.5px);
        @include bp($bp-tablet-sm) {
            max-width: 100%;
        }
    }

    .the-order-item-controls__select--quantity {
        width: 60px;
    }

    .the-order-item-controls__select--desktop-md {
        margin-left: auto;

        @include bp($bp-tablet-sm) {
            height: 30px;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            //z-index: 1;
        }
    }
</style>
