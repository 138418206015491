<script>
    import TheCheckoutPaymentMethodsItem from "@/TheCheckout/TheCheckoutPaymentMethodsItem";
    export default {
        name: "TheCheckoutPaymentMethods",
        components: { TheCheckoutPaymentMethodsItem },
        props: {
            paymentMethodsList: Array,
            city: String,
            paySystem: Object,
            isOverPriceState: Boolean,
            isOnlinePaymentDiscount: Boolean,
        },
        data() {
            return {
                titleText:
                    "Для доставки заказа на&nbsp;сумму свыше 15&nbsp;000&nbsp;₽ необходимо оплатить покупку картой на&nbsp;сайте",
                subTitleText:
                    "Мы&nbsp;страхуем все наши отправления и&nbsp;гарантируем доставку в&nbsp;указанный срок",
            };
        },
        methods: {
            selectPayment(id) {
                this.$emit("select-payment", id);
            },
        },
    };
</script>

<template>
    <div class="the-checkout-payment-methods">
        <div v-show="isOnlinePaymentDiscount" class="the-checkout-payment-methods__sale">
            <div class="the-checkout-payment-methods__sale-text">
                Cкидка 3% при оплате картой онлайн!
            </div>
            <div
                class="
                    the-checkout-payment-methods__sale-icon
                    the-checkout-payment-methods__sale-icon--desktop
                "
            >
                <img :src="require('@/assets/images/checkout/sale_3.svg')" alt="" />
            </div>
            <div
                class="
                    the-checkout-payment-methods__sale-icon
                    the-checkout-payment-methods__sale-icon--mobile
                "
            >
                <img :src="require('@/assets/images/checkout/sale_3_mobile.svg')" alt="" />
            </div>
        </div>

        <div class="the-checkout-payment-methods__list">
            <the-checkout-payment-methods-item
                v-for="payment in paymentMethodsList"
                :key="payment.id"
                :payment="payment"
                :active-pay-system="paySystem.activeId"
                @select-payment="selectPayment"
            ></the-checkout-payment-methods-item>

            <div
                v-if="isOverPriceState"
                class="
                    the-checkout-payment-methods-item
                    the-checkout-payment-methods-item--over-price
                "
            >
                <div class="the-checkout-payment-methods-item__name" v-html="titleText"></div>
                <div
                    class="the-checkout-payment-methods-item__desc"
                    v-html="subTitleText"
                ></div>
                <div class="the-checkout-payment-methods-item__img">
                    <img :src="require('@/assets/images/checkout/over_price.svg')" alt="" />
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .the-checkout-payment-methods {
        position: relative;

        @include bp($bp-desktop-md) {
            padding-right: 188px;
        }
    }

    .the-checkout-payment-methods__list {
        @include bp($bp-desktop-sm) {
            display: flex;
            flex-wrap: wrap;
            min-height: 188px;
        }
    }

    .the-checkout-payment-methods__sale {
        position: relative;
        background: #e8f9ff;
        border-radius: $checkout-payment-box-radius;
        padding: 10px 15px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        width: 100%;
        height: 62px;

        @include bp($bp-desktop-md) {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            width: 174px;
            padding: 15px;
            height: 100%;
            margin-bottom: 0;
        }
    }

    .the-checkout-payment-methods__sale-text {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #014462;
        width: 130px;

        @include bp($bp-tablet-sm) {
            width: auto;
        }

        @include bp($bp-desktop-sm) {
            font-size: 16px;
            padding-right: 20px;
        }
    }

    .the-checkout-payment-methods__sale-icon {
        position: absolute;
        bottom: 0;
        right: 0;
    }

    .the-checkout-payment-methods-item__img {
        display: none;

        @include bp($bp-desktop-sm-for-card) {
            display: block;
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }

    //
    // --mod
    //

    .the-checkout-payment-methods__sale-icon--desktop {
        display: none;

        @include bp($bp-desktop-md) {
            display: block;
        }
    }

    .the-checkout-payment-methods__sale-icon--mobile {
        display: block;

        @include bp($bp-desktop-md) {
            display: none;
        }
    }
</style>
