<script>
    export default {
        name: "TheOrderItemSkeleton",
        data() {
            return {
                list: 2,
            };
        },
    };
</script>

<template>
    <div class="the-order-item-skeleton the-checkout-style__box-shadow-desktop">
        <div v-for="item in list" :key="item" class="the-order-item-skeleton__item">
            <div
                class="the-order-item-skeleton__picture the-order-item-skeleton-animation"
            ></div>
            <div class="the-order-item-skeleton__wrapper">
                <div
                    class="the-order-item-skeleton__name the-order-item-skeleton-animation"
                ></div>
                <div
                    class="the-order-item-skeleton__price the-order-item-skeleton-animation"
                ></div>
            </div>
            <div
                class="the-order-item-skeleton__delete the-order-item-skeleton-animation"
            ></div>
        </div>
    </div>
</template>

<style lang="scss">
    .the-order-item-skeleton-animation {
        position: relative;
        background-color: #e5e5ec;
        overflow: hidden;
        border-radius: $checkout-button-radius--small;

        &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transform: translateX(-100%);
            background-image: linear-gradient(
                90deg,
                rgba(#fff, 0) 0,
                rgba(#fff, 0.2) 20%,
                rgba(#fff, 0.5) 60%,
                rgba(#fff, 0)
            );
            animation: shimmer 2s infinite;
            content: "";
            opacity: 0.6;
        }
    }

    .the-order-item-skeleton {
        //position: absolute;
        //width: 100%;
        //top: 0;
        //left: 0;
        //opacity: 0.5;
        background-color: #fff;

        @include bp($bp-desktop-sm) {
            padding: 20px;
        }
    }

    .the-order-item-skeleton__item {
        position: relative;
        padding: 10px 0;
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        overflow: hidden;

        @include bp($bp-tablet-sm) {
            margin-bottom: 0;
        }

        @include bp($bp-desktop-sm) {
            margin-top: 24px;
        }

        &:not(:first-child) {
            margin-top: 0;
            border-top: 1px solid #ebebf0;
        }
    }

    .the-order-item-skeleton__picture {
        padding-top: 45%;
        width: 37%;

        @include bp($bp-tablet-sm) {
            width: 170px;
            height: 155px;
            padding-top: 0;
            flex-shrink: 0;
        }
    }

    .the-order-item-skeleton__wrapper {
        width: 63%;
        padding-left: 10px;

        @include bp($bp-tablet-sm) {
            width: 100%;
            padding-left: 0;
            display: flex;
            align-items: center;
        }
    }

    .the-order-item-skeleton__name {
        width: calc(100% - 75px);
        height: 40px;
        margin-bottom: 10px;

        @include bp($bp-tablet-sm) {
            width: 223px;
            height: 60px;
            margin-left: 18px;
            margin-bottom: 0;
        }
    }

    .the-order-item-skeleton__price {
        width: 100%;
        height: 50px;

        @include bp($bp-tablet-sm) {
            width: 76px;
            height: 60px;
            margin-left: auto;
        }
    }

    .the-order-item-skeleton__delete {
        position: absolute;
        top: 8px;
        right: 0px;
        @include box(25px);

        @include bp($bp-tablet-sm) {
            position: static;
            @include box(40px);
            margin-left: 50px;
            border-radius: 50%;
            flex-shrink: 0;
        }
    }

    @keyframes shimmer {
        100% {
            transform: translateX(100%);
        }
    }
</style>
