<script>
    import TheUpSaleItem from "@/TheCheckout/TheUpSaleItem";
    export default {
        name: "TheCheckoutUpSaleList",
        components: { TheUpSaleItem },
        props: {
            upSaleList: Object,
            objectForCreatedProduct: Object,
        },
        methods: {
            addUpSale(id) {
                this.$emit("add-up-sale", id);
            },
        },
    };
</script>

<template>
    <div class="the-checkout-up-sale-list">
        <div class="the-checkout-up-sale-list__title">
            <div class="the-checkout-up-sale-list__title-text">
                Специальные предложения. С ними доставка будет бесплатной
                <div
                    class="the-checkout-tooltip the-checkout-up-sale-list__tooltip"
                    @click.stop
                >
                    <div class="the-checkout-up-sale-list__tooltip-icon">
                        <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M21.5 11c0 5.799-4.701 10.5-10.5 10.5S.5 16.799.5 11 5.201.5 11 .5 21.5 5.201 21.5 11z"
                                stroke="#8F90A6"
                            />
                            <path
                                d="M9.846 13.298c0-.752.088-1.352.264-1.798.177-.446.498-.884.964-1.315.471-.435.784-.787.94-1.056a1.73 1.73 0 00.233-.863c0-.908-.404-1.363-1.212-1.363-.383 0-.691.124-.925.371-.227.242-.347.578-.357 1.008H7.5c.01-1.027.329-1.83.956-2.411C9.088 5.291 9.947 5 11.035 5c1.098 0 1.95.277 2.556.83.606.549.909 1.326.909 2.331 0 .457-.098.89-.295 1.299-.197.403-.541.852-1.034 1.346l-.629.621a2.242 2.242 0 00-.676 1.38l-.03.491h-1.99zm-.225 2.476c0-.36.117-.656.35-.887.238-.236.54-.355.909-.355.367 0 .668.119.9.355.239.231.358.527.358.887 0 .355-.116.648-.35.88-.227.23-.53.346-.908.346-.379 0-.684-.116-.917-.347-.228-.231-.342-.524-.342-.879z"
                                fill="#8F90A6"
                            />
                        </svg>
                    </div>
                    <div
                        class="
                            the-checkout-tooltip__text
                            the-checkout-delivery-item__tooltip-text
                        "
                    >
                        Спецпредложением можете воспользоваться только вы и только на этой
                        странице. <br />Приобрести товар со скидкой можно только при условии
                        полной покупки первоначально набранного вами заказа
                    </div>
                </div>
            </div>
        </div>
        <div class="the-checkout-up-sale-list__wrapper">
            <div class="the-checkout-up-sale-list__wrapper-inner">
                <the-up-sale-item
                    v-for="upSale in upSaleList"
                    :key="upSale.id"
                    :up-sale="upSale"
                    :parent-items="objectForCreatedProduct.parentItems"
                    :property-items="objectForCreatedProduct.propertyItems"
                    @add-up-sale="addUpSale"
                ></the-up-sale-item>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .the-checkout-up-sale-list {
        margin: 24px auto 4px;

        @include bp($bp-desktop-sm) {
            margin: 60px auto 50px;
        }
    }

    .the-checkout-up-sale-list__title {
        position: relative;
        display: inline-block;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #1c1c28;

        @include bp($bp-tablet-sm) {
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 20px;
        }

        .the-checkout-up-sale-list__tooltip {
            display: inline-block;
            vertical-align: middle;
            position: static;
            @include bp($bp-tablet-sm) {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
            }
            svg {
                position: relative;
                display: block;
                @include box(13px);
                @include bp($bp-desktop-sm) {
                    @include box(22px);
                }
            }

            @include bp($bp-tablet-sm) {
            }
        }

        .the-checkout-up-sale-list__tooltip-icon {
            position: relative;
            &::after {
                position: absolute;
                content: "";
                top: -10px;
                bottom: -10px;
                right: -10px;
                left: -10px;
            }
            @include bp($bp-desktop-sm) {
                &::after {
                    content: none;
                }
            }
        }
    }

    .the-checkout-up-sale-list__title-text {
        padding-right: 27px;
    }

    .the-checkout-up-sale-list__wrapper {
        width: calc(100% + 10px);
        overflow-x: auto;
        padding: 20px 11px 20px;
        margin: 0 -11px -24px;

        @include bp($bp-desktop-sm) {
            margin: 0 -22px;
            width: 100%;
        }
    }

    .the-checkout-up-sale-list__wrapper-inner {
        display: flex;
    }
</style>
