<script>
    import { mapState } from "vuex";
    import { declineOfNumber, numberFormat, isMobile } from "@/utils";

    export default {
        name: "TheCheckoutDeliveryItem",
        props: {
            deliveryItem: [Object, Array],
            deliveryList: [Object, Array],
            globalDelivery: Object,
            count: Number,
            pickupAddress: String,
        },
        computed: {
            ...mapState("Root", ["city"]),
            descriptionText() {
                return this.deliveryItem.words.length
                    ? this.deliveryItem.numbers +
                          " " +
                          declineOfNumber(this.deliveryItem.numbers, this.deliveryItem.words)
                    : this.deliveryItem.text;
            },
            currentPrice() {
                let before = this.deliveryItem.type !== "POST" ? "от " : "";
                const deliveryPrice =
                    before + numberFormat(this.deliveryItem.price, 0, ".", "&#8201;") + " ₽";

                if (this.deliveryItem.type === "PICKUP") {
                    const isOur = Object.keys(this.deliveryList.PICKUP.items).some((key) => {
                        return this.deliveryList.PICKUP.items[key].our;
                    });
                    if (isOur) {
                        return deliveryPrice;
                    }
                    return this.deliveryItem.price ? deliveryPrice : "Бесплатно";
                }

                return this.deliveryItem.price ? deliveryPrice : "Бесплатно";
            },
            activeDelivery() {
                return this.deliveryItem.type === this.globalDelivery.type;
            },
            createClass() {
                return [
                    this.activeDelivery ? "is-active" : "",
                    (this.globalDelivery.type === "POST" ||
                        (this.globalDelivery.type === "PICKUP" && this.count === 5)) &&
                    this.deliveryItem.type !== "PICKUP" &&
                    !this.activeDelivery
                        ? "is-first-no-active"
                        : "",
                    this.count === 3 &&
                    !this.activeDelivery &&
                    this.deliveryItem.type === "POST" &&
                    this.globalDelivery.type === "PICKUP"
                        ? "is-post-no-active"
                        : "",
                    this.count === 3 &&
                    !this.activeDelivery &&
                    this.deliveryItem.type === "PICKUP" &&
                    this.globalDelivery.type === "COURIER"
                        ? "is-pickup-no-active"
                        : "",
                    this.count === 1 ? "the-checkout-delivery-item--single" : "",
                    this.count === 2 ? "the-checkout-delivery-item--half" : "",
                    this.deliveryItem.disabled ? "the-checkout-delivery-item--disabled" : "",
                ].filter(Boolean);
            },
        },
        watch: {
            pickupAddress(address) {
                if (isMobile(992)) {
                    this.$refs.descriptionText.textContent = address;
                }
            },
        },
        mounted() {
            this.setPickupAddress();
        },
        methods: {
            selectDelivery() {
                const isClickable = isMobile(992) && this.deliveryItem.type === "PICKUP";
                if (!this.activeDelivery || isClickable) {
                    this.$emit("select-delivery-item", {
                        id: this.deliveryItem.companyId,
                        type: this.deliveryItem.type,
                        service: this.deliveryItem.deliveryService,
                    });
                }
            },

            /**
             * получить описание доставки
             * @param type
             * @returns {string}
             */
            getTooltip(type) {
                const item = this.deliveryList[type];
                let interval = null;

                if (type === "COURIER") {
                    if (this.city === "Москва" || this.city === "Санкт-Петербург") {
                        return "Указана ближайшая дата доставки. При подтверждении заказа в&nbsp;разговоре с&nbsp;оператором вы&nbsp;сможете выбрать более позднюю дату и&nbsp;уточнить время доставки";
                    }
                    interval = "3-5 рабочих дней";
                } else if (type === "PICKUP") {
                    if (this.city === "Москва") {
                        return "Вы&nbsp;сможете забрать заказ на&nbsp;следующий день или в&nbsp;день заказа, после подтверждения наличия продукта в&nbsp;точке самовывоза";
                    }

                    interval = "3-7 рабочих дней";
                }

                if (item.minDays && item.maxDays) {
                    interval =
                        (item.minDays < item.maxDays
                            ? item.minDays + "-" + item.maxDays
                            : item.maxDays) +
                        declineOfNumber(item.maxDays, [
                            " рабочий день",
                            " рабочих дня",
                            " рабочих дней",
                        ]);
                }

                return item.tooltip.replace("#NUM#", interval);
            },

            setPickupAddress() {
                if (
                    this.pickupAddress &&
                    isMobile(992) &&
                    this.deliveryItem.type === "PICKUP"
                ) {
                    this.$refs.descriptionText.textContent = this.pickupAddress;
                }
            },
        },
    };
</script>

<template>
    <div
        class="the-checkout-delivery-item"
        :class="createClass"
        @click="selectDelivery"
        @keypress.enter="selectDelivery"
        :tabindex="this.activeDelivery ? -1 : 0"
    >
        <div class="the-checkout-delivery-item__top">
            <div class="the-checkout-delivery-item__name">
                {{ deliveryItem.deliveryName }}
            </div>
            <div class="the-checkout-delivery-item__price" v-html="currentPrice"></div>
        </div>

        <div class="the-checkout-delivery-item__date" :title="deliveryItem.date">
            {{ deliveryItem.date }}
        </div>

        <div class="the-checkout-delivery-item__about">
            <div class="the-checkout-delivery-item__about-wrap">
                <div class="the-checkout-delivery-item__about-icon">
                    <svg
                        v-if="deliveryItem.type === 'COURIER'"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M19 22c-1.38 0-2.5-1.122-2.5-2.5 0-1.379 1.12-2.5 2.5-2.5 1.379 0 2.501 1.121 2.501 2.5a2.505 2.505 0 01-2.5 2.5zM4.5 22A2.504 2.504 0 012 19.5C2 18.12 3.12 17 4.5 17s2.501 1.121 2.501 2.5c0 1.378-1.122 2.5-2.5 2.5zM14.75 11h5.749c.215 0 .42.052.618.127l-.512-2.044A2.746 2.746 0 0017.938 7h-3.189a.75.75 0 00-.75.75v3.426c.23-.11.483-.177.752-.177z"
                        />
                        <path
                            d="M23.652 13.868l-2.75-1.75A.748.748 0 0020.5 12h-5.749a.75.75 0 00-.75.75v6.5c0 .413.336.75.75.75h.801c-.024-.166-.051-.33-.051-.5 0-1.93 1.57-3.5 3.5-3.5 1.928 0 3.499 1.57 3.499 3.5 0 .17-.027.336-.051.5h.801a.75.75 0 00.75-.75V14.5a.748.748 0 00-.348-.632zM12.25 5H.75a.75.75 0 00-.75.75v13.499c0 .414.336.75.75.75h.3c-.024-.165-.051-.329-.051-.5 0-1.928 1.57-3.499 3.5-3.499 1.929 0 3.499 1.57 3.499 3.5 0 .17-.027.335-.051.499h4.3a.75.75 0 00.75-.75V5.75a.746.746 0 00-.746-.75z"
                        />
                    </svg>
                    <svg
                        v-if="deliveryItem.type === 'PICKUP'"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M9.128 12.625l-6.465.009.568-6.017c.118-1.183 1.11-2.075 2.322-2.075h.762a5.71 5.71 0 00-.245 1.675C6.069 8.81 7.813 11.2 9.128 12.625zM13.177 14.291L7.73 22H4.33a2.36 2.36 0 01-1.728-.75 2.279 2.279 0 01-.593-1.759L2.5 14.3l8.54-.009h2.136zM14.88 12.625c1.305-1.425 3.05-3.816 3.05-6.409a5.75 5.75 0 00-.245-1.675h.762a2.31 2.31 0 012.322 2.075l.568 6-6.457.009zM21.494 14.281l-6.256.009L9.793 22h9.877c.654 0 1.282-.273 1.721-.749a2.278 2.278 0 00.6-1.757l-.497-5.213zM12 2C9.638 2 7.716 3.83 7.716 6.08c0 3.103 3.71 6.123 3.868 6.251.12.097.268.145.418.145.149 0 .298-.048.417-.145.159-.127 3.868-3.146 3.868-6.252C16.286 3.83 14.363 2 12 2zm0 5.641c-.945 0-1.713-.723-1.713-1.611 0-.889.767-1.613 1.713-1.613.947 0 1.714.723 1.714 1.612 0 .888-.768 1.612-1.713 1.612z"
                        />
                    </svg>
                    <svg
                        v-if="deliveryItem.type === 'POST'"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M4.5 21.166a.623.623 0 01-.421-.163C3.994 20.925 2 19.083 2 17.155 2 15.691 3.121 14.5 4.5 14.5S7 15.691 7 17.156c0 1.927-1.994 3.77-2.079 3.847a.617.617 0 01-.421.163z"
                        />
                        <path
                            d="M15.045 22H4.5a.625.625 0 010-1.25h10.545a.197.197 0 00.195-.134.194.194 0 00-.055-.229l-5.542-5.014a1.447 1.447 0 01-.383-1.604 1.45 1.45 0 011.361-.935h7.213a.625.625 0 010 1.25H10.62a.197.197 0 00-.195.133.194.194 0 00.055.23l5.543 5.014c.45.408.601 1.037.382 1.604a1.45 1.45 0 01-1.361.935z"
                        />
                        <path
                            d="M17.833 2c-2.297 0-4.166 1.892-4.166 4.219 0 3.21 3.606 6.332 3.76 6.465a.622.622 0 00.813 0C18.392 12.55 22 9.429 22 6.219 22 3.892 20.13 2 17.833 2zm0 5.834a1.668 1.668 0 01-1.666-1.667 1.666 1.666 0 111.666 1.666z"
                        />
                    </svg>
                </div>
                <div class="the-checkout-delivery-item__about-text" ref="descriptionText">
                    {{ descriptionText }}
                </div>
            </div>

            <div
                class="the-checkout-tooltip the-checkout-delivery-item__about-tooltip"
                @click.stop
            >
                <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M21.5 11c0 5.799-4.701 10.5-10.5 10.5S.5 16.799.5 11 5.201.5 11 .5 21.5 5.201 21.5 11z"
                        stroke="#8F90A6"
                    />
                    <path
                        d="M9.846 13.298c0-.752.088-1.352.264-1.798.177-.446.498-.884.964-1.315.471-.435.784-.787.94-1.056a1.73 1.73 0 00.233-.863c0-.908-.404-1.363-1.212-1.363-.383 0-.691.124-.925.371-.227.242-.347.578-.357 1.008H7.5c.01-1.027.329-1.83.956-2.411C9.088 5.291 9.947 5 11.035 5c1.098 0 1.95.277 2.556.83.606.549.909 1.326.909 2.331 0 .457-.098.89-.295 1.299-.197.403-.541.852-1.034 1.346l-.629.621a2.242 2.242 0 00-.676 1.38l-.03.491h-1.99zm-.225 2.476c0-.36.117-.656.35-.887.238-.236.54-.355.909-.355.367 0 .668.119.9.355.239.231.358.527.358.887 0 .355-.116.648-.35.88-.227.23-.53.346-.908.346-.379 0-.684-.116-.917-.347-.228-.231-.342-.524-.342-.879z"
                        fill="#8F90A6"
                    />
                </svg>
                <div
                    class="the-checkout-tooltip__text the-checkout-delivery-item__tooltip-text"
                    v-html="getTooltip(deliveryItem.type)"
                ></div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .the-checkout-delivery-item {
        position: relative;
        width: 100%;
        min-height: 92px;
        padding: 9px;
        border: $checkout-control-border-width solid $checkout-control-border-color;
        transition: 0.3s ease-out all;
        cursor: pointer;
        border-radius: $checkout-delivery-item-radius;
        margin-top: 10px;
        font-size: 14px;
        line-height: 16px;
        will-change: auto;
        outline: none;

        &:last-child {
            margin-top: 0;
        }

        @include bp($bp-desktop-sm) {
            width: calc(100% / 3 - 5px);
            height: 106px;
            padding: 15px;
            background-color: $checkout-control-fill--active;
            border-width: $checkout-control-border-width-single;
            font-size: 15px;
            line-height: 20px;
            margin-top: 0;

            &:not(.is-active):hover {
                &:before {
                    content: "";
                    position: absolute;
                    width: calc(100% + 4px);
                    height: calc(100% + 4px);
                    top: -2px;
                    left: -2px;
                    z-index: 1;
                    box-shadow: 0 0 0 2px $checkout-control-border-color--active inset;
                }

                .the-checkout-delivery-item__price {
                    color: $checkout-delivery-tab-price-color--active;
                }

                //&:nth-child(1) {
                //    &:before {
                //        border-radius: var(--checkout-box-radius) 0 0 0;
                //    }
                //}
                //&:nth-child(2) {
                //    &:before {
                //        border-radius: 0 0 0 var(--checkout-box-radius-single);
                //    }
                //}
                //&:nth-child(3) {
                //    &:before {
                //        border-radius: 0 var(--checkout-box-radius) 0 0;
                //    }
                //}
            }

            &:not(.is-active):focus-visible {
                @include outline(-1px);
            }

            &:nth-child(1) {
                padding-left: 20px;
                border-radius: var(--checkout-box-radius) 0 var(--checkout-box-radius-single) 0;

                &.is-active {
                    + .the-checkout-delivery-item {
                        border-radius: 0 0 0 var(--checkout-box-radius-single);
                        border-right-color: transparent;
                    }
                }

                &.is-first-no-active {
                    border-radius: var(--checkout-box-radius) 0 0 0;
                    border-right-color: transparent;
                }

                &:not(.is-active):hover {
                    &:before {
                        border-radius: var(--checkout-box-radius) 0
                            var(--checkout-box-radius-single) 0;
                    }

                    &.is-first-no-active {
                        &:before {
                            border-radius: var(--checkout-box-radius) 0 0 0;
                        }
                    }
                }
            }

            &:nth-child(2) {
                border-radius: 0 0 var(--checkout-box-radius-single) 0;

                &.is-active {
                    + .the-checkout-delivery-item {
                        border-radius: 0 var(--checkout-box-radius) 0
                            var(--checkout-box-radius-single);
                    }
                }

                &:not(.is-active):hover {
                    &:before {
                        border-radius: 0 0 var(--checkout-box-radius-single) 0;
                    }

                    &.is-pickup-no-active {
                        &:before {
                            border-radius: 0 0 0 var(--checkout-box-radius-single);
                        }
                    }
                }
            }

            &:nth-child(3) {
                border-radius: 0 var(--checkout-box-radius) 0 0;

                &:not(.is-active):hover {
                    &:before {
                        border-radius: 0 var(--checkout-box-radius) 0 0;
                    }
                    &.is-post-no-active {
                        &:before {
                            border-radius: 0 var(--checkout-box-radius) 0
                                var(--checkout-box-radius-single);
                        }
                    }
                }
            }
        }

        @include bp($bp-desktop-md) {
            font-size: 16px;
        }

        &.is-active {
            cursor: default;
            background-color: #fff;
            border-color: $checkout-control-border-color--active;

            @include bp($bp-desktop-sm) {
                width: calc(100% / 3 + 11px);
                padding: 19px 19px 0 19px;
                height: 116px;
                margin-top: -10px;
                border-color: $checkout-control-border-color-single;
                border-radius: var(--checkout-box-radius) var(--checkout-box-radius) 0 0;
                border-bottom-color: transparent;
            }

            .the-checkout-delivery-item__top {
                &:after {
                    border-color: $checkout-control-border-color--active;
                }

                &:before {
                    opacity: 1;
                }
            }

            .the-checkout-delivery-item__name {
                font-weight: $checkout-delivery-tab-title-weight--active;
                color: $checkout-delivery-tab-title-color--active;
            }

            .the-checkout-delivery-item__about-icon {
                svg {
                    fill: $checkout-icon-fill;
                }
            }

            .the-checkout-delivery-item__date {
                color: #1c1c28;
            }

            .the-checkout-delivery-item__about-text {
                color: #1c1c28;
            }

            .the-checkout-delivery-item__price {
                color: $checkout-delivery-tab-price-color--active;
            }
        }
    }

    .the-checkout-delivery-item__top {
        position: relative;
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
        padding-left: 30px;

        @include bp_max($bp-desktop-sm_max) {
            &:after {
                content: "";
                position: absolute;
                @include box(20px);
                top: 0;
                left: 0;
                border-radius: 50%;
                border: $checkout-control-border-width solid $checkout-control-border-color;
            }

            &:before {
                content: "";
                position: absolute;
                top: 4px;
                left: 4px;
                @include box(12px);
                border-radius: 50%;
                background: $checkout-checkbox-fill;
                opacity: 0;
            }
        }

        @include bp($bp-desktop-sm) {
            margin-bottom: 0;
            padding-left: 0;
        }
    }

    .the-checkout-delivery-item__name {
        color: $checkout-delivery-tab-title-color;
        font-weight: $checkout-delivery-tab-title-weight;
    }

    .the-checkout-delivery-item__price {
        color: $checkout-delivery-tab-price-color;
        font-weight: $checkout-delivery-tab-price-weight;
        transition: color 0.2s ease-in;
    }

    .the-checkout-delivery-item__date {
        font-weight: $checkout-delivery-tab-date-weight;
        color: $checkout-base-text-color;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-left: 30px;
        height: 20px;

        @include bp($bp-desktop-sm) {
            padding-left: 0;
        }
    }

    .the-checkout-delivery-item__about {
        display: flex;
        align-items: center;
        margin-top: 12px;
    }

    .the-checkout-delivery-item__about-wrap {
        display: flex;
        align-items: center;
        margin-left: 2px;

        @include bp($bp-desktop-sm) {
            margin-left: 0;
        }
    }

    .the-checkout-delivery-item__about-icon {
        flex-shrink: 0;

        svg {
            display: block;
            @include box(16px);
            fill: $checkout-icon-fill--disabled;
            transition: fill 0.2s ease-in;

            @include bp($bp-desktop-sm) {
                @include box(24px);
            }
        }
    }

    .the-checkout-delivery-item__about-text {
        margin-left: 12px;
        font-weight: 300;
        color: $checkout-base-text-color;

        @include bp($bp-desktop-sm) {
            font-size: 14px;
            margin-left: 6px;
            line-height: 26px;
        }
    }

    .the-checkout-delivery-item__about-tooltip {
        margin-left: auto;

        svg {
            display: block;
            @include box(20px);

            @include bp($bp-desktop-sm) {
                @include box(22px);
            }
        }
    }

    .the-checkout-delivery-item__tooltip-text {
        line-height: 18px;
        left: auto;
        right: 0;
        top: 27px;
        width: 280px;

        @include bp($bp-tablet-sm) {
            top: 30px;
            left: 50%;
            transform: translateX(-50%);
            width: 334px;
        }
        @include bp($bp-desktop-sm) {
            display: block;
        }
    }

    //
    // --mod
    //

    .the-checkout-delivery-item--single {
        width: 100%;

        &.is-active {
            width: 100%;
        }
    }

    .the-checkout-delivery-item--half {
        @include bp($bp-desktop-sm) {
            width: calc(100% / 2 - 5px);

            &.is-active {
                width: calc(100% / 2 + 11px);
            }

            &:not(.is-active):hover {
                &:nth-child(2) {
                    &:before {
                        border-radius: 0 var(--checkout-box-radius) 0
                            var(--checkout-box-radius-single);
                    }
                }
            }

            &:nth-child(1) {
                &.is-active {
                    + .the-checkout-delivery-item {
                        border-radius: 0 var(--checkout-box-radius) 0
                            var(--checkout-box-radius-single);
                        border-right-color: $checkout-control-border-color;
                    }
                }
            }
        }
    }

    .the-checkout-delivery-item--disabled {
        .the-checkout-delivery-item__price {
            visibility: hidden;
        }

        .the-checkout-delivery-item__date {
            color: #b41249;
        }
    }
</style>
