<script>
    import { sendAnalytics } from "@/core/SendAnalytics";
    import TheCheckoutInput from "@/TheCheckout/TheCheckoutInput";
    import TheCheckoutInputPhone from "@/TheCheckout/TheCheckoutInputPhone";

    export default {
        name: "TheCheckoutContacts",
        props: {
            isSecondName: Boolean,
            contact: Object,
            errorList: Object,
            isSubscribe: Boolean,
            isLoginUser: Boolean,
            isMadRobots: Boolean,
        },
        data() {
            return {
                currentSubscribe: this.isSubscribe,
            };
        },
        watch: {
            currentSubscribe: {
                handler() {
                    this.$emit("set-subscribe", this.currentSubscribe);
                },
            },
        },
        methods: {
            resetError(name) {
                this.$emit("reset-error", name);
            },
            setLS(obj) {
                this.$emit("set-from-local-storage", { type: "contacts", ...obj });
            },
            sendDL(name) {
                sendAnalytics.send(sendAnalytics.thirdStepCheckoutEvents.orderEditContacts, {
                    dynamicName: name,
                });
            },
            sendDlForRadio() {
                sendAnalytics.send(sendAnalytics.thirdStepCheckoutEvents.orderSettings, {
                    dynamicName: "subscribe",
                });
            },
        },
        components: {
            TheCheckoutInputPhone,
            TheCheckoutInput,
        },
    };
</script>

<template>
    <div class="the-checkout-contacts the-checkout-style__box-shadow-mobile">
        <div class="the-checkout-contacts__title">Контактная информация</div>

        <div v-show="!isLoginUser && isMadRobots" class="the-checkout-contacts__desc">
            Вы можете войти под своим логином, зарегистрироваться или ввести данные в форме
            ниже и заказ привяжется к вашему аккаунту. Если у вас нет аккаунта, он будет создан
            автоматически.
        </div>

        <div class="the-checkout-contacts__wrapper">
            <div class="the-checkout-contacts__row the-checkout-contacts__row--first">
                <div class="the-checkout-contacts__input">
                    <the-checkout-input
                        v-model="contact.name"
                        placeholder="Имя"
                        name="name"
                        :error="errorList.name"
                        @reset-error="resetError"
                        @set-from-local-storage="setLS"
                        @focus="sendDL('name')"
                    ></the-checkout-input>
                </div>

                <div class="the-checkout-contacts__input">
                    <the-checkout-input
                        v-model="contact.last_name"
                        placeholder="Фамилия"
                        name="last_name"
                        :error="errorList.last_name"
                        @reset-error="resetError"
                        @set-from-local-storage="setLS"
                        @focus="sendDL('last_name')"
                    ></the-checkout-input>
                </div>
            </div>

            <div class="the-checkout-contacts__row">
                <div v-show="isSecondName" class="the-checkout-contacts__input">
                    <the-checkout-input
                        v-model="contact.second_name"
                        placeholder="Отчество"
                        name="second_name"
                        :error="errorList.second_name"
                        @reset-error="resetError"
                        @set-from-local-storage="setLS"
                        @focus="sendDL('second_name')"
                    ></the-checkout-input>
                </div>

                <div class="the-checkout-contacts__input">
                    <the-checkout-input-phone
                        :value="contact.phone"
                        @input="(e) => (contact.phone = e)"
                        :error="errorList.phone"
                        @reset-error="resetError"
                        @set-from-local-storage="setLS"
                        @focus="sendDL('phone')"
                    ></the-checkout-input-phone>
                </div>

                <div class="the-checkout-contacts__input">
                    <the-checkout-input
                        type="email"
                        v-model="contact.email"
                        placeholder="Почта"
                        name="email"
                        :error="errorList.email"
                        @reset-error="resetError"
                        @set-from-local-storage="setLS"
                        @focus="sendDL('email')"
                    ></the-checkout-input>
                </div>
            </div>
        </div>

        <label class="the-checkout-contacts__subscribe" @click="sendDlForRadio">
            <span class="the-checkout-contacts__subscribe-text">
                Получать информацию о&nbsp;скидках и&nbsp;секретных распродажах
            </span>
            <span>
                <input
                    type="checkbox"
                    name="subscribe"
                    class="the-checkout-contacts__subscribe-checkbox visually-hidden"
                    v-model="currentSubscribe"
                />
                <span class="the-checkout-contacts__subscribe-radio"></span>
            </span>
        </label>
    </div>
</template>

<style lang="scss">
    .the-checkout-contacts {
        margin-top: 24px;

        @include bp_max($bp-desktop-sm_max) {
            padding: 15px;
        }
    }

    .the-checkout-contacts__title {
        font-size: 14px;
        line-height: 22px;
        color: #1c1c28;
    }

    .the-checkout-contacts__desc {
        margin-top: 10px;
        font-weight: 300;
        font-size: 12px;
        line-height: 20px;
        color: $checkout-base-text-color;
    }

    .the-checkout-contacts__wrapper {
        margin: 16px -5px 0;
    }

    .the-checkout-contacts__row {
        @include bp($bp-desktop-sm) {
            display: flex;
        }
    }

    .the-checkout-contacts__input {
        flex-shrink: 0;
        flex-grow: 1;
        margin: 0 5px 5px;

        @include bp($bp-desktop-sm) {
            width: calc(100% / 3 - 10px);
            margin-bottom: 10px;
        }
    }

    .the-checkout-contacts__subscribe {
        padding-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        font-weight: 300;
        font-size: 12px;
        line-height: 20px;

        @include bp($bp-desktop-sm) {
            font-size: 16px;
        }
    }

    .the-checkout-contacts__subscribe-text {
        padding-right: 20px;
        max-width: 500px;
    }

    .the-checkout-contacts__subscribe-checkbox {
        @include bp($bp-desktop-sm) {
            &:focus-visible {
                & + .the-checkout-contacts__subscribe-radio {
                    @include outline;
                }
            }
        }
    }

    .the-checkout-contacts__subscribe-radio {
        transition-property: background-color;
        transition-duration: 0.2s;
        transition-timing-function: linear;
        position: relative;
        display: block;
        width: 44px;
        height: 24px;
        background: #c7c9d9;
        border-radius: 100px;
    }

    .the-checkout-contacts__subscribe-checkbox:checked
        + .the-checkout-contacts__subscribe-radio {
        background: $checkout-checkbox-fill;
    }

    .the-checkout-contacts__subscribe-radio:before {
        transition-property: left;
        transition-duration: 0.2s;
        transition-timing-function: linear;
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 3px;
        width: 18px;
        height: 18px;
        background: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
        border-radius: 50%;
        margin: auto 0;
        cursor: pointer;
    }

    .the-checkout-contacts__subscribe-checkbox:checked
        + .the-checkout-contacts__subscribe-radio:before {
        left: 23px;
    }

    //
    // --mod
    //

    .the-checkout-contacts__row--first {
        .the-checkout-contacts__input {
            &:first-child {
                flex-grow: 0;
            }
        }
    }
</style>
