<script>
    export default {
        name: "TheUpSaleColors",
        props: {
            upSale: Object,
            parentItems: Object,
            propertyItems: Object,
        },
        data() {
            return {
                selectedColor: {
                    name: this.propertyItems[this.upSale.productId].color,
                },
            };
        },
        computed: {
            currentParent() {
                return this.parentItems[this.upSale.parentId];
            },
        },
        methods: {
            selectColor(color) {
                this.selectedColor = color;
                this.$emit("on-select", color);
            },
        },
    };
</script>

<template>
    <div class="the-up-sale-item-colors">
        <div class="the-up-sale-item-colors__list">
            <button
                class="the-up-sale-item-colors__item outline"
                :class="{ selected: selectedColor.name === item.name }"
                v-for="(item, key) in currentParent.colorsList"
                :key="key"
                :style="{ backgroundColor: '#' + key }"
                :data-name="selectedColor.name === item.name"
                @click="selectColor(item)"
                :tabindex="selectedColor.name === item.name ? -1 : 0"
            ></button>
        </div>
    </div>
</template>

<style lang="scss">
    .the-up-sale-item-colors {
    }
    .the-up-sale-item-colors__list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px;
    }
    .the-up-sale-item-colors__item {
        position: relative;
        width: 15px;
        height: 15px;
        margin: 0 5px 8px;
        border-radius: 50%;
        cursor: pointer;
        transition: transform 0.2s ease-in-out;
        &:not(.selected):hover {
            transform: scale(1.1);
        }
        &::before {
            content: "";
            position: absolute;
            top: -3px;
            bottom: -3px;
            left: -3px;
            right: -3px;
            border: 1px solid $checkout-active-color;
            border-radius: inherit;
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
        }
        &.selected {
            &::before {
                opacity: 1;
            }
        }
    }
</style>
