<script>
    import { apiUrl, numberFormat } from "@/utils";
    import TheUpSaleColors from "@/TheCheckout/TheUpSaleColors";

    export default {
        name: "TheUpSaleItem",
        components: { TheUpSaleColors },
        props: {
            upSale: Object,
            parentItems: Object,
            propertyItems: Object,
        },
        data() {
            return {
                upSaleItem: {
                    id: this.upSale.id,
                    imageSrc: this.upSale.picture,
                },
            };
        },
        computed: {
            calcSlug() {
                if (this.upSale.sameSite) {
                    return {
                        url: `/p/${this.upSale.url}/`,
                        target: "",
                    };
                } else {
                    return {
                        url: `https://madrobots.ru/p/${this.upSale.url}/`,
                        target: "_blank",
                    };
                }
            },
            price() {
                return numberFormat(this.upSale.price, 0, ".", " ");
            },
            oldPrice() {
                return numberFormat(this.upSale.basePrice, 0, ".", " ");
            },
        },
        methods: {
            apiUrl,
            addUpSale() {
                this.$emit("add-up-sale", this.upSaleItem.id);
            },
            selectColorHandler(color) {
                this.upSaleItem.id = color.offerId;
                this.upSaleItem.imageSrc = color.image;
            },
        },
    };

    // TODO: переделать href на router-link
</script>

<template>
    <div class="the-up-sale-item">
        <div class="the-up-sale-item__discount">{{ upSale.discountPercent }}%</div>
        <a
            :href="calcSlug.url"
            class="the-up-sale-item__picture"
            tabindex="-1"
            :target="calcSlug.target"
        >
            <img
                :src="apiUrl(upSaleItem.imageSrc)"
                :alt="upSale.name"
                class="the-up-sale-item__picture-img"
            />
        </a>
        <a :href="calcSlug.url" class="the-up-sale-item__name" :target="calcSlug.target">
            {{ upSale.name }}
        </a>

        <the-up-sale-colors
            class="the-up-sale-item__colors"
            :up-sale="upSale"
            :parent-items="parentItems"
            :property-items="propertyItems"
            @on-select="selectColorHandler"
        ></the-up-sale-colors>

        <div class="the-up-sale-item__price">
            <div class="the-up-sale-item__price-number the-up-sale-item__price-number--new">
                {{ price }} ₽
            </div>
            <div class="the-up-sale-item__price-number the-up-sale-item__price-number--old">
                {{ oldPrice }} ₽
            </div>
        </div>
        <button class="the-up-sale-item__button outline" @click="addUpSale()">
            <span class="mobile-hidden">Добавить&nbsp;к&nbsp;заказу</span>
            <span class="desktop-hidden">К&nbsp;заказу</span>
        </button>
    </div>
</template>

<style lang="scss">
    .the-up-sale-item {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 220px;
        background: #ffffff;
        box-shadow: $checkout-upsale-shadow;
        border-radius: var(--checkout-box-radius);
        padding: 10px;
        border: $checkout-upsale-border;
        transition: 0.3s ease-in-out all;
        margin-right: 16px;

        &:hover {
            background-color: transparent;
            border-color: #ebebf0;
        }

        @include bp($bp-desktop-sm) {
            margin: 0 11px;
            padding: 15px;
            width: calc(100% / 3 - 22px);
        }
    }

    .the-up-sale-item__discount {
        position: absolute;
        top: 15px;
        left: 15px;
        background: $checkout-danger-color;
        border-radius: $checkout-label-radius;
        font-weight: bold;
        font-size: var(--checkhout-upsale-label-size);
        line-height: 26px;
        padding: 0 5px;
        color: #ffffff;
        z-index: 1;
    }

    // TODO: уопределиться с размером картинки
    .the-up-sale-item__picture {
        display: block;
        position: relative;
        height: calc(120px + 18px);
        margin-bottom: 10px;
        outline: none;

        @include bp($bp-desktop-sm) {
            height: calc(168px + 18px);
        }
    }

    .the-up-sale-item__picture-img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .the-up-sale-item__name {
        display: block;
        margin-bottom: 5px;
        font-size: $checkout-upsale-title-size;
        font-weight: $checkout-upsale-title-weight;
        line-height: 20px;
        color: $checkout-base-text-color;
        overflow: hidden;
    }

    .the-up-sale-item__colors {
        min-height: 28px;
    }

    .the-up-sale-item__price {
        margin-top: auto;
        margin-bottom: 14px;
        display: flex;
        align-items: baseline;
    }

    .the-up-sale-item__price-number {
        font-weight: $checkout-upsale-price-weight;
    }

    .the-up-sale-item__button {
        display: block;
        width: 100%;
        background: #ffffff;
        border: $checkout-upsale-button-border-width solid $checkout-upsale-button-border-color;
        box-sizing: border-box;
        border-radius: $checkout-button-radius--large;
        font-weight: $checkout-button-text-weight;
        font-size: $checkout-upsale-button-size--mobile;
        line-height: 19px;
        color: $checkout-upsale-button-text-color;
        padding: 15px;
        text-align: center;
        transition: 0.3s ease-in-out all;

        &:hover,
        &:focus-visible {
            border-color: $checkout-button-color;
            background: $checkout-button-color;
            color: $checkout-button-text;
        }
        @include bp($bp-desktop-sm) {
            font-size: $checkout-upsale-button-size;
        }
    }

    //
    // --mod
    //

    .the-up-sale-item__price-number--new {
        font-weight: $checkout-upsale-price-weight;
        font-size: var(--checkhout-upsale-price-new-size);
        line-height: 28px;
        color: $checkout-danger-color;
    }

    .the-up-sale-item__price-number--old {
        font-size: var(--checkhout-upsale-price-old-size);
        font-weight: 500;
        line-height: 16px;
        text-decoration-line: line-through;
        color: #8f90a6;
        margin-left: 5px;
        margin-bottom: 3px;
    }
</style>
