<script>
    import { getVariable, setVariable } from "@/utils";

    export default {
        name: "TheCheckoutInput",
        props: {
            value: {
                type: String,
            },
            placeholder: {
                type: String,
                default: "",
            },
            type: {
                type: String,
                default: "text",
                validator: (value) => {
                    return ["text", "number", "email", "password", "tel"].includes(value);
                },
            },
            variant: {
                type: String,
                default: "primary",
                validator: (value) => {
                    return ["primary", "secondary", "tertiary"].includes(value);
                },
            },
            error: Object,
            name: String,
        },
        data() {
            return {
                errorText: "",
                list: {
                    street: {
                        empty: "Введите улицу",
                    },
                    house: {
                        empty: "Введите номер дома",
                    },
                    name: {
                        empty: "Введите имя",
                        checkLetter: "Пожалуйста используйте кириллицу",
                        length: "Длинна больше 2 символов",
                    },
                    second_name: {
                        empty: "Введите отчесвто",
                        checkLetter: "Пожалуйста используйте кириллицу",
                        length: "Длинна больше 2 символов",
                    },
                    last_name: {
                        empty: "Введите фамилию",
                        checkLetter: "Пожалуйста используйте кириллицу",
                        length: "Длинна больше 2 символов",
                    },
                    phone: {
                        empty: "Введите телефон",
                    },
                    email: {
                        empty: "Введите почту",
                    },
                },
            };
        },
        computed: {
            className() {
                return this.componentClassNames("the-base-input").filter(Boolean);
            },
            showError() {
                return this.error?.status;
            },
            isCyrillic() {
                return this.errorText === "Пожалуйста используйте кириллицу";
            },
        },
        mounted() {
            if (getVariable(`MadRobots_${this.name}`)) {
                this.$emit("set-from-local-storage", {
                    name: this.name,
                    value: getVariable(`MadRobots_${this.name}`),
                });
            }
        },
        methods: {
            isNumber(symbol) {
                return /\d/.test(symbol);
            },
            componentClassNames(className) {
                return [
                    this.variant ? `${className}--${this.variant}` : "",
                    this.size ? `${className}--${this.size}` : "",
                    this.disabled ? `${className}--disabled` : "",
                    this.value.length > 0 ? `${className}--selected` : "",
                    this.showError ? `${className}--error` : "",
                ];
            },
            keyupElement(e) {
                if (this.name === "house") {
                    let firstSymbol = e.target.value.charAt(0);

                    if (!this.isNumber(firstSymbol)) {
                        e.target.value = e.target.value.slice(1);
                        e.target.value = e.target.value.replace(/[^,. ]+$/, "");
                    }
                }

                let listForLetter = ["name", "second_name", "last_name"];

                if (listForLetter.includes(this.name)) {
                    if (e.target.value.length === 0) return false;
                    if (/^[а-яА-ЯЁё\s]+$/.test(this.value)) {
                        this.$emit("keyup", e.target.value);
                        this.$emit("reset-error", this.name);
                        this.resetError();
                    } else {
                        this.errorText = this.list[this.name].checkLetter;
                        e.preventDefault(); // If not match, don't add to input text
                    }
                    return false;
                }

                this.$emit("keyup", e.target.value);
                this.$emit("reset-error", this.name);
            },
            changeElement() {
                if (this.value.length === 0) {
                    this.$emit("reset-error", this.name);
                    this.resetError();
                }
                this.$emit("change");
                setVariable(`MadRobots_${this.name}`, this.value);
            },
            resetError() {
                this.errorText = "";
            },
        },
    };
</script>

<template>
    <label class="the-base-input the-checkout-input">
        <input
            class="the-base-input__field"
            :class="className"
            :placeholder="placeholder"
            :type="type"
            :value="value"
            :name="name"
            @input="$emit('input', $event.target.value)"
            @focus="$emit('focus')"
            @blur="$emit('blur')"
            @keyup="keyupElement"
            @click="$emit('click')"
            @change="changeElement"
            @keypress="$emit('keypress')"
        />
        <span v-if="errorText" class="the-base-input__error">
            {{ errorText }}
            <span v-if="isCyrillic" class="the-base-input__error-icon">
                <img src="@/assets/images/question.svg" alt="" />
                <span class="the-base-input__error-tooltip">
                    Для корректной передачи информации в службу доставки
                </span>
            </span>
        </span>
    </label>
</template>

<style lang="scss">
    .the-base-input {
        width: 100%;
        display: block;
    }

    .the-base-input__field {
        width: 100%;
        color: #1c1c28;
        font-family: inherit;
        font-size: 16px;
        line-height: 1;
        border: $checkout-control-border-width solid transparent;
        padding: 9px 14px;
        border-radius: $checkout-control-radius;
        background-color: $checkout-control-input-bg;
        transition: 0.3s ease-in-out all;

        @include bp($bp-desktop-sm) {
            padding: 14px;
        }

        @include input-placeholder {
            color: $checkout-control-placeholder-color;
        }

        &:not(.the-base-input--selected):not(.the-base-input--error):hover,
        &:not(.the-base-input--selected):not(.the-base-input--error):focus {
            border-color: $checkout-control-border-color--hover;
        }
    }

    .the-base-input__error {
        display: block;
        margin-top: 4px;
        padding-left: 12px;
        font-size: 12px;
        line-height: 16px;
        color: $checkout-input-danger;
        text-align: left;
    }

    .the-base-input__error-icon {
        position: relative;
        display: inline;
        margin-left: 1px;

        &:hover {
            .the-base-input__error-tooltip {
                visibility: visible;
                opacity: 1;
            }
        }

        img {
            display: inline;
            vertical-align: middle;
            width: 16px;
            height: 16px;
            object-fit: cover;
        }
    }

    .the-base-input__error-tooltip {
        position: absolute;
        top: 17px;
        left: 50%;
        width: 234px;
        transform: translateX(-50%);
        font-weight: 300;
        font-size: 12px;
        min-height: 32px;
        padding: 10px;
        text-align: center;
        color: #000;
        background: #ffffff;
        box-shadow: 0 8px 15px #f2f2f5;
        border-radius: 10px;
        visibility: hidden;
        opacity: 0;
        z-index: 111;
        transition: 0.2s ease-in-out all;
    }

    .the-base-input--primary {
        border-color: $checkout-control-border-color; //var(--color-base-black);
    }

    .the-base-input--secondary {
        border-color: #cecece;
    }

    .the-base-input--tertiary {
        border-color: transparent;
    }

    .the-base-input--sm {
        padding: 4px 12px;
    }

    .the-base-input--md {
        padding: 8px 16px;
    }

    .the-base-input--lg {
        padding: 12px 16px;
    }

    .the-base-input--filled {
        width: 100%;
        max-width: 100%;
    }

    .the-base-input--squared {
        border-radius: 0;
    }

    .the-base-input--disabled {
        opacity: 0.7;
    }

    .the-base-input--selected {
        border-color: $checkout-control-border-color--active;
    }

    .the-base-input--error {
        border-color: $checkout-input-danger;
    }
</style>
