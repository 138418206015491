<script>
    import TheCheckoutPackingProduct from "@/TheCheckout/TheCheckoutPackingProduct";

    export default {
        name: "TheCheckoutPackingProducts",
        components: { TheCheckoutPackingProduct },
        props: {
            products: [Object, Array],
            initialProductId: [String, Number],
        },
        data() {
            return {
                selectedProductId: "",
            };
        },
        watch: {
            initialProductId(id) {
                this.selectedProductId = id;
            },
        },
        mounted() {
            if (!this.selectedProductId) {
                this.selectedProductId = this.initialProductId;
            }
        },
        methods: {
            selectProduct(id) {
                this.selectedProductId = id;
                this.$emit("on-select-product", id);
            },
        },
    };
</script>

<template>
    <div class="the-checkout-packing-products">
        <div class="the-checkout-packing-products__list">
            <the-checkout-packing-product
                v-for="product in products"
                :key="product.parentId"
                :product="product"
                :selected-product-id="selectedProductId"
                @on-select-product="selectProduct"
            ></the-checkout-packing-product>
        </div>
    </div>
</template>

<style lang="scss">
    .the-checkout-packing-products {
        padding-bottom: 12px;
        @include bp($bp-desktop-sm) {
            padding-bottom: 0;
        }
    }

    .the-checkout-packing-products__list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -6px;
    }

    .the-checkout-packing-products__item {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-basis: calc(33.3333% - 12px);
        max-width: calc(33.3333% - 12px);
        margin: 0 6px 12px;
        border: $checkout-packing-border-width solid $checkout-packing-border-color;
        border-radius: $checkout-packing-radius;
        transition: border-color 0.2s ease-in;
        &::before {
            content: "";
            display: block;
            padding-bottom: 100%;
        }
        &.is-active {
            border-color: $checkout-packing-border-color--selected;
        }
        &.is-selected {
            .the-checkout-packing-products__icon {
                display: block;
                img.icon-success {
                    display: block;
                }
            }
        }
        &.is-disabled {
            border-color: $checkout-packing-disabled-color;
            cursor: default;
            .the-checkout-packing-products__icon {
                display: block;
                pointer-events: all;
                img.icon-info {
                    display: block;
                }
                @include bp($bp-desktop-sm) {
                    &:hover {
                        .the-checkout-packing-products__tooltip {
                            display: block;
                        }
                    }
                }
            }
            .the-checkout-packing-products__image {
                img {
                    opacity: 0.6;
                }
                &::after {
                    content: "";
                    @include center-absolute(0);
                    z-index: 3;
                    background-color: $checkout-packing-disabled-color;
                }
            }
        }
        &.is-active.is-disabled {
            border-color: $checkout-packing-border-color--selected;
        }
        @include bp($bp-desktop-sm) {
            flex-basis: calc(12.5% - 12px);
            max-width: calc(12.5% - 12px);
            cursor: pointer;
            &:hover {
                &:not(.is-disabled) {
                    border-color: $checkout-packing-border-color--selected;
                }
            }
            &.is-disabled {
                pointer-events: none;
            }
        }
    }

    .the-checkout-packing-products__image {
        @include center-absolute(0);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: $checkout-packing-radius--disabled;
        overflow: hidden;
        img {
            max-height: 100%;
        }
    }

    .the-checkout-packing-products__icon {
        position: absolute;
        z-index: 4;
        top: -4px;
        left: -4px;
        display: none;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        img.icon-success {
            display: none;
        }
        img.icon-info {
            display: none;
        }
    }

    .the-checkout-packing-products__tooltip {
        display: none;
        @include bp($bp-desktop-sm) {
            position: absolute;
            z-index: 10;
            left: 50%;
            bottom: calc(100% + 12px);
            width: 210px;
            padding: 10px;
            color: #1c1c28;
            font-size: 12px;
            font-weight: 300;
            line-height: 18px;
            text-align: center;
            background-color: #fff;
            border-radius: 10px;
            box-shadow: 0 8px 15px #c7c9d9;
            transform: translateX(-50%);
            svg {
                position: absolute;
                bottom: -7px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
</style>
