<script>
    import TheCheckoutButtonPreloader from "@/TheCheckout/TheCheckoutButtonPreloader";
    export default {
        name: "TheCheckoutBonusItem",
        components: { TheCheckoutButtonPreloader },
        props: {
            value: String,
            placeholder: String,
            name: String,
            isSidebar: Boolean,
            isSuccess: Boolean,
            singleMode: Boolean,
            isLoading: Boolean,
            isMadRobots: Boolean,
            isMadCoin: Boolean,
            isMadCoinShow: Boolean,
            isFilled: Boolean,
            madCoins: Object,
        },
        methods: {
            handlerBonus() {
                if (this.value.length === 0 && this.name !== "madCoin") return false;
                let updatedValue = this.isMadCoin ? "1" : this.value;
                let updatedEvent = this.isSuccess ? "remove-bonus" : "submit-bonus";

                this.$emit(updatedEvent, { value: updatedValue, name: this.name });
            },
            componentClassNames(className) {
                return [this.value.length > 0 ? `${className}--selected` : ""];
            },
        },
        computed: {
            className() {
                return this.componentClassNames("the-checkout-bonus-item__input").filter(
                    Boolean
                );
            },
        },
    };
</script>

<template>
    <form
        class="the-checkout-bonus-item"
        :class="{
            'the-checkout-bonus-item--sidebar': isSidebar && isMadRobots,
            'the-checkout-bonus-item--success': isSuccess,
            'the-checkout-bonus-item--single': singleMode && !isSidebar,
            'the-checkout-bonus-item--is-madcoin': isMadCoin,
            'the-checkout-bonus-item--is-action-madcoin': isMadCoin && isMadCoinShow,
            'the-checkout-bonus-item--filled': isFilled,
        }"
        @submit.prevent="handlerBonus"
    >
        <label class="the-checkout-bonus-item__label">
            <input
                :value="value"
                :disabled="isSuccess"
                type="text"
                :placeholder="placeholder"
                class="the-checkout-bonus-item__input"
                :class="className"
                @input="$emit('input', $event.target.value)"
            />
        </label>
        <button
            class="the-checkout-bonus-item__button outline"
            :class="{
                'is-animated': isLoading,
                'is-disabled': value.length < 2 && !isMadCoinShow,
            }"
            :disabled="isLoading"
        >
            <span class="the-checkout-bonus-item__button-apply mobile-hidden">Применить</span>
            <span class="the-checkout-bonus-item__button-apply desktop-hidden">Ввод</span>
            <span class="the-checkout-bonus-item__button-cancel">Отменить</span>
            <the-checkout-button-preloader
                v-show="isLoading"
                size="small"
            ></the-checkout-button-preloader>
        </button>
    </form>
</template>

<style lang="scss">
    .the-checkout-bonus-item {
        position: relative;
        flex: 1;
        margin: 0 8px;
        &:not(.the-checkout-bonus-item--sidebar) {
            .the-checkout-bonus-item__button {
                width: auto;
                padding: 0 16px;
                font-size: 14px;
                color: $checkout-button-text;
                &:after,
                &:before {
                    content: none;
                }
            }
        }
        @include bp($bp-desktop-sm) {
            &:not(.the-checkout-bonus-item--sidebar) {
                width: calc(50% - 16px);
                margin-bottom: 16px !important;
            }
        }
    }

    .the-checkout-bonus-item--filled {
        &:not(.the-checkout-bonus-item--sidebar) {
            @include bp($bp-desktop-sm) {
                flex: auto;
                width: calc(100% - 16px) !important;
            }
        }
    }

    .the-checkout-bonus-item__success {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .the-checkout-bonus-item__label {
        display: block;
    }

    .the-checkout-bonus-item__input {
        width: 100%;
        font-size: 16px;
        line-height: 20px;
        color: $checkout-control-color;
        padding: 14px;
        border: $checkout-control-border-width solid $checkout-control-border-color;
        border-radius: $checkout-control-radius;
        transition: 0.3s ease-in-out all;

        @include bp($bp-desktop-sm) {
            padding: 19px;
        }

        @include input-placeholder {
            color: $checkout-control-placeholder-color;
        }

        &:not(.the-checkout-bonus-item__input--selected):hover,
        &:not(.the-checkout-bonus-item__input--selected):focus {
            border-color: $checkout-control-border-color--hover;
        }
    }

    .the-checkout-bonus-item__button {
        margin: auto;
        position: absolute;
        top: 0;
        right: 8px;
        bottom: 0;
        @include box(32px);
        //height: 32px;
        //padding: 0 16px;
        background-color: $checkout-button-color;
        border-radius: $checkout-button-radius--small;
        z-index: 1;
        cursor: pointer;
        font-size: 0;
        //font-size: 14px;

        color: transparent;
        //color: $checkout-button-text;
        transition: background-color 0.3s ease-in-out;

        @include bp($bp-desktop-sm) {
            @include box(40px);
            right: 10px;
        }

        .the-checkout-bonus-item__button-cancel {
            display: none;
        }

        &:after,
        &:before {
            content: "";
            @include center-absolute(0);
            margin: auto;
            width: 2px;
            height: 15px;
            background-color: $checkout-button-text;
            transition: background-color 0.1s ease-in;

            @include bp($bp-desktop-sm) {
                height: 16px;
            }
        }

        &:before {
            transform: rotate(90deg);
        }

        &:not(:disabled):hover {
            background-color: $checkout-button-color--hover;
        }

        &.is-animated {
            color: transparent;
            &::before,
            &::after {
                background-color: transparent;
            }
        }

        &.is-disabled {
            background-color: $checkout-button-color--disabled;
            pointer-events: none;
        }
    }

    .the-checkout-bonus-item__success {
        width: 100%;
        height: 60px;
        background-color: green;
    }

    .the-checkout-bonus-item__button-cancel {
        color: $checkout-base-text-color;
    }

    //
    // --mod
    //

    .the-checkout-bonus-item__input--selected {
        border-color: $checkout-control-border-color--active;
    }

    .the-checkout-bonus-item--sidebar {
        margin: 10px 0 0;

        .the-checkout-bonus-item__input {
            min-height: 40px;
            padding: 14px 10px;
            background: #f7f7f8;
            &:not(.the-checkout-bonus-item__input--selected) {
                border-color: #f7f7f8;
            }
        }

        .the-checkout-bonus-item__button {
            right: 6px;
        }
    }

    .the-checkout-bonus-item--single {
        @include bp($bp-desktop-sm) {
            .the-checkout-bonus-item__button {
                right: 10px;
                width: auto;
                padding: 0 16px;
                font-weight: 500;
                font-size: 16px;
                color: $checkout-button-text;

                &:after,
                &:before {
                    display: none;
                }

                &.is-animated {
                    color: transparent;
                }
            }

            .the-checkout-bonus-item__button-cancel {
                display: none;
            }
        }
    }

    .the-checkout-bonus-item--success {
        .the-checkout-bonus-item__input {
            background-color: #efffe8;
            border-color: #52c41a;
        }

        .the-checkout-bonus-item__button {
            padding: 0;
            background-color: transparent;

            &:after,
            &:before {
                height: 20px;
                background-color: #555770;
            }

            &:after {
                transform: rotate(-45deg);
            }

            &:before {
                transform: rotate(45deg);
            }

            &:hover {
                background-color: transparent;
            }
        }

        .the-checkout-bonus-item__button-apply {
            display: none;
        }

        .the-checkout-bonus-item__button-cancel {
            display: inline;
        }

        &.the-checkout-bonus-item--single {
            .the-checkout-bonus-item__button {
                &:hover {
                    background-color: transparent;
                }
            }
        }
    }

    .the-checkout-bonus-item--is-madcoin {
        .the-checkout-bonus-item__label {
            pointer-events: none;
        }

        .the-checkout-bonus-item__button {
            display: none;
        }
    }

    .the-checkout-bonus-item--is-action-madcoin {
        .the-checkout-bonus-item__button {
            display: block;
        }
    }
</style>
