<script>
    import { sendAnalytics } from "@/core/SendAnalytics";

    export default {
        name: "TheCheckoutNotices",
        props: {
            communication: Array,
            email: String,
        },
        data() {
            return {
                form: this.communication,
                onlyCheckbox: false,
                noticesList: [
                    { id: 1, title: "Оповещения через СМС", name: "sms" },
                    { id: 2, title: "Оповещения через WhatsApp", name: "whatsApp" },
                    { id: 3, title: "Подтверждение заказа по телефону", name: "phone" },
                ],
            };
        },
        watch: {
            form: {
                handler(value) {
                    this.$emit("change-communication", this.form);
                    this.onlyCheckbox = value.length === 1;
                },
            },
        },
        methods: {
            sendDL(name) {
                sendAnalytics.send(sendAnalytics.thirdStepCheckoutEvents.orderSettings, {
                    dynamicName: name,
                });
            },
        },
    };
</script>

<template>
    <div class="the-checkout-notices the-checkout-style__box-shadow-mobile">
        <div class="the-checkout-notices__title">Уведомления о статусе заказа</div>

        <div class="the-checkout-notices__desc">
            Вся информация о заказе будет отправлена на почту
            <strong class="notice__email">{{ email }}</strong>
            <br />
            Вы можете выбрать удобный для вас вариант оповещения: СМС, WhatsApp или
            подтверждение по телефону
        </div>

        <div class="the-checkout-notices__wrapper">
            <label
                v-for="notice in noticesList"
                :key="notice.id"
                class="the-checkout-notices__item"
                :class="{
                    'the-checkout-notices__item--disabled':
                        form[0] === notice.id && onlyCheckbox,
                }"
                @click="sendDL(notice.name)"
            >
                <span class="the-checkout-notices__item-text">
                    {{ notice.title }}
                </span>
                <input
                    type="checkbox"
                    name="communication[]"
                    :value="notice.id"
                    class="the-checkout-notices__item-input visually-hidden"
                    :disabled="form[0] === notice.id && onlyCheckbox"
                    v-model="form"
                />
                <span class="the-checkout-notices__item-radio"></span>
            </label>
        </div>
    </div>
</template>

<style lang="scss">
    .the-checkout-notices {
        margin-top: 24px;

        @include bp_max($bp-desktop-sm_max) {
            padding: 15px;
        }
    }

    .the-checkout-notices__title {
        font-size: 14px;
        line-height: 22px;
        color: #1c1c28;
    }

    .the-checkout-notices__desc {
        margin-top: 10px;
        font-weight: 300;
        font-size: 12px;
        line-height: 20px;
        color: $checkout-base-text-color;
    }

    .the-checkout-notices__wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: 16px -7px 0;
    }

    .the-checkout-notices__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1 1 100%;
        height: 60px;
        padding: 0 14px;
        margin: 0 7px 10px;
        border-radius: $checkout-control-radius;
        border: $checkout-control-border-width solid $checkout-control-border-color;
        cursor: pointer;

        &:last-child {
            margin-bottom: 0;
        }

        @include bp($bp-desktop-sm-for-card) {
            flex: 1;
            margin-bottom: 0;
        }
    }

    .the-checkout-notices__item-text {
        padding-right: 32px;
        position: relative;
        font-weight: $checkout-notification-weight;
        font-size: $checkout-notification-size;
        line-height: 16px;

        color: $checkout-notification-color;
    }

    .the-checkout-notices__item-input {
        @include bp($bp-desktop-sm) {
            &:focus-visible {
                & + .the-checkout-notices__item-radio {
                    @include outline;
                }
            }
        }
    }

    .the-checkout-notices__item-radio {
        flex-shrink: 0;
        position: relative;
        display: block;
        background: #e4e4eb;
        width: 44px;
        height: 24px;
        border-radius: 100px;
        transition: 0.3s ease-in-out left, 0.3s ease-in-out background-color;

        &:before {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto 0;
            content: "";
            left: 3px;
            width: 18px;
            height: 18px;
            background: #fff;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
            border-radius: 50%;
            cursor: pointer;
            transition: 0.3s ease-in-out all;
        }
    }

    .the-checkout-notices__item-input:checked + .the-checkout-notices__item-radio:before {
        left: 23px;
    }

    .the-checkout-notices__item-input:checked + .the-checkout-notices__item-radio {
        background: $checkout-checkbox-fill;
    }

    //
    // --mod
    //

    .the-checkout-notices__item--disabled {
        pointer-events: none;
    }
</style>
