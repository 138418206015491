<script>
    import {
        declineOfNumber,
        getVariable,
        isMobile,
        removeVariable,
        setVariable,
    } from "@/utils";
    import { sendAnalytics } from "@/core/SendAnalytics";
    import TheCheckoutInput from "@/TheCheckout/TheCheckoutInput";
    import TheCheckoutPackingPatterns from "@/TheCheckout/TheCheckoutPackingPatterns";
    import TheCheckoutPackingProducts from "@/TheCheckout/TheCheckoutPackingProducts";
    import TheCheckoutPackingDialog from "@/TheCheckout/TheCheckoutPackingDialog";

    export default {
        name: "TheCheckoutPacking",
        components: {
            TheCheckoutPackingDialog,
            TheCheckoutPackingProducts,
            TheCheckoutPackingPatterns,
            TheCheckoutInput,
        },
        props: {
            packing: Object,
            products: [Object, Array],
            objectForCreatedProduct: Object,
        },
        data() {
            return {
                comment: "",
                commentPlaceholder: "Комментарий к упаковке, если нужно",
                selectedProduct: {},
                mappedProducts: [],
                isOpenedPatternDialog: false,
            };
        },
        computed: {
            selectedPatternName() {
                const id = this.selectedProduct?.packing?.selected;
                const packing = this.selectedProduct?.packing?.giftWraps?.find(
                    (p) => p.id === id
                );
                return packing ? packing.name : "";
            },
            hasSelectedPacking() {
                return this.mappedProducts.some((p) => p.packing.selected);
            },
            isDisabledPacking() {
                return !this.selectedProduct.packing?.giftWraps?.length;
            },
            isDisabledAllPacking() {
                return this.mappedProducts.every((p) => {
                    return !p.packing.giftWraps?.length;
                });
            },
            selectedPackingNumber() {
                return this.mappedProducts.filter((p) => p.packing.selected).length;
            },
            selectedPackingText() {
                return `Вы выбрали ${this.selectedPackingNumber} ${declineOfNumber(
                    this.selectedPackingNumber,
                    ["упаковку", "упаковки", "упаковок"]
                )} на сумму ${this.packing.price}₽`;
            },
        },
        watch: {
            products() {
                this.mappedProducts = this.getProducts();
                this.selectedProduct = this.mappedProducts[0];
                this.$emit("on-set-packing", this.getPackingForRequest());
            },
        },
        mounted() {
            if (this.products) {
                this.mappedProducts = this.getProducts();
            }
            if (isMobile(992)) {
                this.commentPlaceholder = "Комментарий, если нужно";
            }
        },
        methods: {
            openPatternDialog() {
                this.isOpenedPatternDialog = true;
            },
            setPackingComment() {
                this.$emit("on-set-comment", this.comment);
            },
            changePackingHandler(patternId = "") {
                this.selectedProduct.packing.selected = patternId;
                this.$emit("on-set-packing", this.getPackingForRequest());
                setVariable(
                    "MadRobots_Packing",
                    JSON.stringify(this.getPackingForRequest(true))
                );
            },
            clearAllPacking() {
                removeVariable("MadRobots_Packing");
                this.mappedProducts.forEach((p) => (p.packing.selected = ""));
                this.selectedProduct.packing.selected = "";
                this.$emit("on-set-packing", []);
            },
            selectProductHandler(id) {
                this.selectedProduct = this.mappedProducts.find((p) => p.parentId === id);
            },
            getPackingForRequest(forStorage = false) {
                return this.mappedProducts
                    .map((product) => {
                        const productForReturn = {
                            productId: product.productId.toString(),
                            wrapId: product.packing.selected,
                            quantity: 1,
                        };

                        if (forStorage) {
                            productForReturn.fakeId = product.parentId;
                        }

                        return productForReturn;
                    })
                    .filter((packing) => packing.wrapId)
                    .reduce((prev, curr) => {
                        const found = prev.find((p) => {
                            return p.wrapId === curr.wrapId && p.productId === curr.productId;
                        });
                        found && !forStorage
                            ? (found.quantity += curr.quantity)
                            : prev.push(curr);
                        return prev;
                    }, []);
            },
            sendDL() {
                sendAnalytics.send(sendAnalytics.secondStepCheckoutEvents.orderEditAddress, {
                    dynamicName: "packingComment",
                });
            },
            getProducts() {
                const packingFromStorage = JSON.parse(getVariable("MadRobots_Packing"));

                return Object.values(this.products)
                    .flatMap((product) => {
                        return product.quantity <= 1
                            ? product
                            : this.getMappedProducts(product, product.quantity);
                    })
                    .map((product) => {
                        const parentId = product.parentId.toString().split("_")[0];
                        const packing = this.packing.items?.find((item) => {
                            return item.productId.toString() === parentId.toString();
                        });
                        const image =
                            this.objectForCreatedProduct.parentItems[parentId].images[
                                product.productId
                            ];

                        const selected =
                            packingFromStorage?.find((packing) => {
                                return packing.fakeId === product.parentId;
                            }) || null;

                        return {
                            ...product,
                            packing: { ...packing, selected: selected ? selected.wrapId : "" },
                            image,
                        };
                    })
                    .sort((_, b) => {
                        return b.packing.giftWraps?.length ? 1 : -1;
                    });
            },
            getMappedProducts(product, quantity) {
                const result = [];
                for (let i = 0; i < quantity; i++) {
                    const parentId = `${product.parentId}_${i}`;
                    result.push({ ...product, parentId });
                }
                return result;
            },
        },
    };
</script>

<template>
    <div
        class="
            the-checkout-packing
            the-checkout-style__box-shadow the-checkout-style__no-corner
        "
    >
        <div v-show="!isDisabledAllPacking" class="the-checkout-packing__content">
            <div class="the-checkout-packing__header">
                <div class="the-checkout-packing__title">
                    <template v-if="mappedProducts.length > 1">
                        Подарочная упаковка. Стоимость услуги для одного товара 400 ₽
                    </template>
                    <template v-else>
                        Подарочная упаковка.
                        <span>Стоимость услуги 400 ₽</span>
                    </template>
                </div>
                <div class="the-checkout-packing__subtitle">
                    Мы красиво завернем покупку в бумагу по вашему выбору.
                    <span v-if="mappedProducts.length > 1">
                        Каждая позиция в заказе будет упакована отдельно. Кликните на товар и
                        выберите для него подарочную упаковку.
                    </span>
                </div>
            </div>

            <div
                v-if="hasSelectedPacking && mappedProducts.length > 1"
                class="the-checkout-packing__notification mobile-hidden"
            >
                {{ selectedPackingText }}
            </div>

            <div
                v-if="hasSelectedPacking && mappedProducts.length > 1"
                class="the-checkout-packing__info desktop-hidden"
            >
                <div class="the-checkout-packing__notification">{{ selectedPackingText }}</div>

                <button class="the-checkout-packing__remove" @click="clearAllPacking">
                    <img
                        class="desktop-hidden"
                        src="@/TheCheckout/assets/icons/packing/remove.svg"
                        alt=""
                    />
                </button>
            </div>

            <div class="the-checkout-packing__products" v-show="mappedProducts.length > 1">
                <the-checkout-packing-products
                    :products="mappedProducts"
                    :initial-product-id="selectedProduct.parentId"
                    @on-select-product="selectProductHandler"
                >
                </the-checkout-packing-products>
            </div>

            <div
                v-if="!selectedPatternName"
                class="the-checkout-packing__text desktop-hidden"
                :class="{ 'is-disabled': isDisabledPacking }"
            >
                <template v-if="isDisabledPacking">
                    К сожалению, мы не сможем упаковать этот товар!
                </template>
                <template v-if="!isDisabledPacking && mappedProducts.length > 1">
                    Выберите подарочную упаковку для
                    <a :href="selectedProduct.url" target="_blank">
                        {{ selectedProduct.name }}
                    </a>
                </template>
            </div>

            <div
                v-if="mappedProducts.length > 1"
                class="the-checkout-packing__info mobile-hidden"
            >
                <div class="the-checkout-packing__text">
                    <template v-if="selectedPatternName">
                        Для
                        <a :href="selectedProduct.url" target="_blank">
                            {{ selectedProduct.name }}
                        </a>
                        вы выбрали упаковку: {{ selectedPatternName }}
                    </template>
                    <template v-else>
                        Выберите подарочную упаковку для
                        <a :href="selectedProduct.url" target="_blank">
                            {{ selectedProduct.name }}
                        </a>
                    </template>
                </div>

                <button
                    v-if="hasSelectedPacking"
                    class="the-checkout-packing__remove outline"
                    @click="clearAllPacking"
                >
                    <span>Убрать всю упаковку</span>
                </button>
            </div>

            <div
                class="the-checkout-packing__selected"
                v-if="selectedPatternName && mappedProducts.length > 1"
            >
                Для
                <a :href="selectedProduct.url" target="_blank">{{ selectedProduct.name }}</a>
                вы выбрали упаковку: {{ selectedPatternName }}
                <button @click="changePackingHandler()">
                    <img src="@/TheCheckout/assets/icons/packing/close.svg" alt="" />
                </button>
            </div>

            <button
                v-if="!selectedPatternName && !isDisabledPacking && mappedProducts.length > 1"
                class="the-checkout-packing__button desktop-hidden outline"
                @click="openPatternDialog"
            >
                Выбрать упаковку
            </button>

            <div
                class="the-checkout-packing__patterns"
                :class="{ 'is-single': mappedProducts.length === 1 }"
            >
                <the-checkout-packing-patterns
                    :selected-product="selectedProduct"
                    is-single
                    @on-select-pattern="changePackingHandler"
                    @on-clear-pattern="changePackingHandler"
                ></the-checkout-packing-patterns>
            </div>

            <div class="the-checkout-packing__comment">
                <the-checkout-input
                    v-model="comment"
                    @keyup="setPackingComment"
                    name="comment"
                    @focus="sendDL"
                    :placeholder="commentPlaceholder"
                ></the-checkout-input>
            </div>

            <the-checkout-packing-dialog
                :selected-product="selectedProduct"
                :is-opened="isOpenedPatternDialog"
                @on-close="isOpenedPatternDialog = false"
                @on-confirm="changePackingHandler"
            ></the-checkout-packing-dialog>
        </div>

        <div v-show="isDisabledAllPacking" class="the-checkout-packing__error">
            К сожалению, дополнительная услуга подарочной упаковки доступна только при выборе
            вариантов доставки «Курьером Madrobots» или «Самовывоз из магазина» и при
            соответствующих габаритах покупки. Только так мы можем гарантировать сохранность
            внешнего вида подарочной упаковки
        </div>
    </div>
</template>

<style lang="scss">
    .the-checkout-packing {
        width: calc(100% + 20px);
        margin-left: -10px;
        margin-top: 24px;
        padding: 15px 25px;

        @include bp($bp-desktop-sm) {
            position: relative;
            width: 100%;
            margin-left: 0;
            margin-top: 15px;
            padding: 20px;
        }
    }

    .the-checkout-packing__header {
        margin-bottom: 25px;
        @include bp($bp-desktop-sm) {
            margin-bottom: 20px;
        }
    }

    .the-checkout-packing__title {
        margin-bottom: 10px;
        color: var(--primary-text);
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        span {
            display: block;
        }
        @include bp($bp-desktop-sm) {
            span {
                display: inline;
            }
        }
    }

    .the-checkout-packing__subtitle {
        color: var(--secondary-text);
        font-size: 12px;
        line-height: 20px;
        @include bp($bp-desktop-sm) {
            max-width: 410px;
        }
    }

    .the-checkout-packing__info {
        display: flex;
        justify-content: space-between;
        @include bp($bp-desktop-sm) {
            align-items: flex-start;
        }
    }

    .the-checkout-packing__text {
        margin-bottom: 20px;
        color: var(--secondary-text);
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        &.is-disabled {
            padding: 10px 20px;
            font-weight: 500;
            background-color: #f8f8fa;
            border-radius: $checkout-packing-notification-radius;
        }
        a {
            color: #2e7bed;
        }
        @include bp($bp-desktop-sm) {
            max-width: 550px;
            font-size: 14px;
            font-weight: 500;
        }
    }

    .the-checkout-packing__notification {
        flex-grow: 1;
        margin-bottom: 25px;
        margin-left: -7px;
        padding: 7px;
        color: #fff;
        font-size: 12px;
        font-weight: 600;
        line-height: 22px;
        text-align: center;
        border-radius: 50px;
        background-color: $checkout-packing-danger-color;
        @include bp($bp-desktop-sm) {
            position: absolute;
            top: 20px;
            right: 20px;
            padding: 7px 10px;
        }
    }

    .the-checkout-packing__remove {
        margin-left: 10px;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        @include bp($bp-desktop-sm) {
            width: auto;
            height: auto;
            color: var(--secondary-text);
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            border-radius: 0;
            transition: color 0.2s ease-in;
            &:hover {
                color: $checkout-packing-danger-color;
            }
        }
    }

    .the-checkout-packing__selected {
        position: relative;
        margin-bottom: 20px;
        padding: 10px 20px;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        background-color: #f8f8fa;
        border-radius: $checkout-packing-notification-radius;
        a {
            color: #2e7bed;
        }
        button {
            position: absolute;
            top: -6px;
            right: -6px;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            &::before {
                content: "";
                @include center-absolute(-5px);
            }
        }
        @include bp($bp-desktop-sm) {
            display: none;
        }
    }

    .the-checkout-packing__patterns {
        display: none;
        &.is-single {
            display: block;
        }
        @include bp($bp-desktop-sm) {
            display: block;
        }
    }

    .the-checkout-packing__button {
        position: relative;
        display: block;
        width: 100%;
        margin-bottom: 20px;
        padding: 10px;
        color: $checkout-button-text-color;
        font-size: $checkout-button-text-size--mobile;
        font-weight: $checkout-button-text-weight;
        line-height: 20px;
        text-align: center;
        background-color: $checkout-button-color;
        border-radius: $checkout-button-radius--large;
    }

    .the-checkout-packing__error {
        font-size: 14px;
        line-height: 22px;
        color: $checkout-packing-danger-color;
    }
</style>
