<script>
    import { mapState, mapActions } from "vuex";
    import {
        declineOfNumber,
        getVariable,
        setVariable,
        removeVariable,
        scrollTo,
        isMobile,
    } from "@/utils";
    import { metaInfo } from "@/core/MetaInfo";
    import { sendAnalytics } from "@/core/SendAnalytics";
    import TheCheckoutHeader from "@/TheCheckout/TheCheckoutHeader";
    import TheCheckoutSidebar from "@/TheCheckout/TheCheckoutSidebar";
    import TheCheckoutBonuses from "@/TheCheckout/TheCheckoutBonuses";
    import TheCheckoutOrder from "@/TheCheckout/TheCheckoutOrder";
    import TheCheckoutUpSaleList from "@/TheCheckout/TheCheckoutUpSaleList";
    import TheCheckoutDelivery from "@/TheCheckout/TheCheckoutDelivery";
    import TheCheckoutEditor from "@/TheCheckout/TheCheckoutEditor";
    import TheCheckoutPayment from "@/TheCheckout/TheCheckoutPayment";
    import TheCheckoutContacts from "@/TheCheckout/TheCheckoutContacts";
    import TheCheckoutNotices from "@/TheCheckout/TheCheckoutNotices";
    import TheCheckoutLastStep from "@/TheCheckout/TheCheckoutLastStep";
    import TheOrderItemSkeleton from "@/TheCheckout/TheOrderItemSkeleton";
    import TheCheckoutEmpty from "@/TheCheckout/TheCheckoutEmpty";
    import TheCheckoutDownDetector from "@/TheCheckout/TheCheckoutDownDetector";
    import TheCheckoutPacking from "@/TheCheckout/TheCheckoutPacking";

    export default {
        name: "TheCheckout",
        components: {
            TheCheckoutPacking,
            TheCheckoutDownDetector,
            TheCheckoutEmpty,
            TheOrderItemSkeleton,
            TheCheckoutLastStep,
            TheCheckoutNotices,
            TheCheckoutContacts,
            TheCheckoutPayment,
            TheCheckoutEditor,
            TheCheckoutDelivery,
            TheCheckoutUpSaleList,
            TheCheckoutOrder,
            TheCheckoutBonuses,
            TheCheckoutSidebar,
            TheCheckoutHeader,
        },
        data() {
            return {
                // for sidebar
                isLoadingSidebar: false,
                currentStep: 0,

                // for order
                basketItems: [],
                orderPrice: 0,
                basketPrice: 0,
                orderDiscount: 0,
                orderFullPrice: 0,
                upSaleList: [],
                upSaleCart: null,
                objectForCreatedProduct: null,
                discountsData: [],

                // for payment system
                paySystem: {
                    activeId: 0,
                    activeList: [1, 19], // 1 - оплата наличными, 19 - оплата для мск и питер
                    activeListByDelivery: {
                        7: [1, 19],
                        8: [1, 19],
                    }, // 1 - оплата наличными, 19 - оплата для мск и питер
                    list: null,
                    isPrint: true,
                },

                // bonuses
                bonuses: {
                    coupon: {
                        name: "",
                        discountPercent: 0,
                        discountSum: 0,
                    },
                    certificate: {
                        name: "",
                        discountSum: 0,
                    },
                    madCoins: {
                        isApply: false,
                        total: "",
                        applied: "",
                        discountSum: 0,
                        totalCount: "",
                    },
                },

                // for delivery
                globalDelivery: {
                    id: "", // id достаки
                    type: "", // тип доставки Курьер ПВЗ почта(COURIER PICKUP POST)
                    service: "", // yandex или madRobots
                },
                address: {
                    street: "",
                    house: "",
                    build: "",
                    housing: "",
                    flat: "",
                    comment: "",
                },
                contact: {
                    last_name: "",
                    name: "",
                    second_name: "",
                    phone: "",
                    email: "",
                },
                delivery_interval: undefined,
                isSubscribe: true,
                communication: [2, 3],
                errorList: {
                    street: {
                        empty: "Введите улицу",
                        status: false,
                    },
                    house: {
                        empty: "Введите номер дома",
                        status: false,
                    },
                    build: {
                        empty: "Введите номер строения",
                        status: false,
                    },
                    housing: {
                        empty: "Введите номер корпуса",
                        status: false,
                    },
                    name: {
                        empty: "Введите имя",
                        checkLetter: "Пожалуйста используйте кириллицу",
                        status: false,
                    },
                    second_name: {
                        empty: "Введите отчесвто",
                        checkLetter: "Пожалуйста используйте кириллицу",
                        status: false,
                    },
                    last_name: {
                        empty: "Введите фамилию",
                        checkLetter: "Пожалуйста используйте кириллицу",
                        status: false,
                    },
                    phone: {
                        empty: "Введите телефон",
                        status: false,
                    },
                    email: {
                        empty: "Введите почту",
                        status: false,
                    },
                },
                currentSelectedPickup: {
                    price: 0,
                    address: "",
                    isMr: false,
                },
                currentPriceDelivery: 0,
                idExpressDeliveryList: [
                    "1974:6:2112:0",
                    "1974:6:21112:0",
                    "1974:6:21121:0",
                    "1974:6:211121:0",
                ],
                idMadRobotsCouriersList: ["1974:6:2106:0", "1974:6:2107:0", "1974:6:620075:0"],
                deliveryList: {
                    COURIER_FAST: {
                        minPrice: false,
                        minDays: false,
                        maxDays: false,
                        name: "Экспресс-доставка",
                        tooltip:
                            "Доставка день в&nbsp;день возможна при оформлении заказа до&nbsp;16:30 текущего дня. Стоимость услуги в&nbsp;пределах МКАД&nbsp;&mdash; 500&nbsp;рублей.",
                        items: {},
                        fastest: { id: "", deliveryId: "" },
                    },
                    COURIER: {
                        minPrice: false,
                        minDays: false,
                        maxDays: false,
                        tooltip: "Курьер доставит заказ через #NUM# после оформления",
                        name: "Курьерская",
                        items: {},
                        fastest: { id: "", deliveryId: "" },
                    },
                    PICKUP: {
                        minPrice: false,
                        minDays: false,
                        maxDays: false,
                        tooltip:
                            "Вы&nbsp;сможете забрать покупку через #NUM# дней после оформления. Для отслеживания доставки выберите в&nbsp;меню сайта пункт &laquo;Статус заказа&raquo; и&nbsp;введите номер заказа",
                        name: "Самовывоз",
                        items: {},
                        fastest: { id: "", deliveryId: "" },
                    },
                    POST: {
                        minPrice: false,
                        minDays: false,
                        maxDays: false,
                        tooltip:
                            "Мы&nbsp;отправим заказ Почтой России после его оплаты на&nbsp;сайте. Время доставки: #NUM# рабочих дней после оформления. Для отслеживания доставки выберите в&nbsp;меню сайта пункт &laquo;Статус заказа&raquo; и&nbsp;введите номер заказа",
                        name: "Почтой России",
                        items: {},
                        fastest: { id: "", deliveryId: "" },
                    },
                },
                isErrorPostDelivery: false,
                isSecondName: false,
                isDownExternalServices: false,

                // packing
                packing: {
                    items: [],
                    productWrapPositions: [],
                    price: 400,
                    cart: {},
                    comment: "",
                },

                // for success order
                statusOrder: {
                    status: "",
                    id: 0,
                    email: "",
                    phone: "",
                },

                // other
                isMadRobots: this.$root.$_name !== "travel-blue",
                isEmptyCheckout: false,
                freeDeliveryCount: 5000,
                isLoginUser: false,
            };
        },
        computed: {
            ...mapState("Root", ["city"]),
            countOrders() {
                return Object.keys(this.basketItems).length;
            },
            isAddressCompleted() {
                let { street, house, housing, build, flat } = this.address;
                let address = "";

                if (!street || !house) return address;

                address += `${street} ${house}`;

                if (housing !== "") address += `, к. ${housing}`;

                if (build !== "") address += `, стр. ${build}`;

                if (flat !== "") address += `, кв. ${flat}`;

                return address;
            },
            nameDelivery() {
                return {
                    type: this.globalDelivery.type,
                    address:
                        this.globalDelivery.type === "PICKUP"
                            ? this.currentSelectedPickup.address
                            : this.isAddressCompleted,
                };
            },
            isOverPriceState() {
                let countCurrentPaySystems =
                    this.paySystem.activeListByDelivery[this.globalDelivery.service] || [];
                return this.orderPrice > 15000 && countCurrentPaySystems.length < 2;
            },
            isContactsCompleted() {
                let regEmail = /\S+@\S+\.\S+/;

                let { name, second_name, last_name, phone, email } = this.contact;

                if (phone.length !== 18) {
                    phone = "";
                }

                if (!regEmail.test(email)) {
                    email = "";
                }

                return !(
                    !name ||
                    !last_name ||
                    !phone ||
                    !email ||
                    (this.globalDelivery.type === "POST" && !second_name)
                );
            },
            dataForSendForm() {
                return {
                    ...this.contact,
                    ...this.address,
                    subscribe: this.isSubscribe ? 1 : 0,
                    communication: this.communication,
                    PAY_SYSTEM_ID: this.paySystem.activeId,
                    city: this.city,
                    productWrapPositions: this.packing.productWrapPositions,
                    packingComment: this.packing.comment,
                };
            },
            isCheckRegions() {
                return this.city !== "Москва" && this.city !== "Санкт-Петербург";
            },
            isScreenDownDetector() {
                return this.isDownExternalServices && this.currentStep === 2;
            },
            isVisibleUpsaleList() {
                const maxUpsaleItemPrice = Math.max(
                    ...Object.values(this.upSaleList).map((upsale) => {
                        return upsale.price;
                    })
                );
                const untilFreeDelivery = this.freeDeliveryCount - this.basketPrice;

                return (
                    this.currentStep === 0 &&
                    Object.keys(this.upSaleList).length &&
                    !this.upSaleCart &&
                    untilFreeDelivery > 0 &&
                    untilFreeDelivery - maxUpsaleItemPrice <= 0
                );
            },
        },
        mounted() {
            this.expiryLocalStorage();

            if (getVariable("MadRobots_Step") > 0) {
                this.currentStep = parseInt(getVariable("MadRobots_Step"));
            }

            if (this.isCheckRegions) {
                this.setPaySystem({ activeId: 19 });
            }

            this.setLocalStorage();

            this.loadYandexMap();

            this.getCheckoutResultForFirstView();

            if (this.currentStep === 2 && !this.nameDelivery.address) {
                this.goPrevStep();
            }
        },
        watch: {
            currentStep: {
                handler(updatedStep) {
                    if (updatedStep === 1) {
                        sendAnalytics.send(sendAnalytics.events.delivery);
                    }
                    if (updatedStep === 2) {
                        sendAnalytics.send(sendAnalytics.events.payment);
                    }
                },
            },
            city() {
                this.checkPackingAvailability();
            },
        },
        methods: {
            ...mapActions("Root", ["setNewCity"]),
            /**
             * Sidebar
             */
            goNextStep() {
                if (this.currentStep === 0) {
                    sendAnalytics.send(
                        sendAnalytics.firstStepCheckoutEvents.cartGoToOrderButton
                    );
                }
                if (this.currentStep === 1) {
                    sendAnalytics.send(
                        sendAnalytics.secondStepCheckoutEvents.orderGoToPaymentButton
                    );
                }
                this.currentStep++;

                if (this.isDownExternalServices && this.currentStep !== 3) {
                    this.currentStep = 2;
                }

                if (this.currentStep < 4) {
                    this.setLocalStorageStep();
                    this.routerPushToStepComponent(this.currentStep);
                    this.sendAnalyticsForCheckoutWithSteps();
                }
            },
            goPrevStep() {
                this.currentStep--;
                this.setLocalStorageStep();
                this.routerPushToStepComponent(this.currentStep);
                this.sendAnalyticsOtherCheckoutEvents(this.currentStep);
                this.sendAnalyticsForCheckoutWithSteps();
            },
            updateCurrentStep(step) {
                this.currentStep = step;
                this.setLocalStorageStep();
                this.routerPushToStepComponent(step);
                this.sendAnalyticsOtherCheckoutEvents(step);
                this.sendAnalyticsForCheckoutWithSteps();
            },
            sendAnalyticsOtherCheckoutEvents(step) {
                if (step === 0)
                    sendAnalytics.send(sendAnalytics.otherCheckoutEvents.cartEditLink);
                if (step === 1)
                    sendAnalytics.send(sendAnalytics.otherCheckoutEvents.cartDeliveryLink);
            },
            routerPushToStepComponent(step) {
                const filteredComponentsName = {
                    0: "TheCheckoutStepOne",
                    1: "TheCheckoutStepTwo",
                    2: "TheCheckoutStepThree",
                    3: "TheCheckoutStepFour",
                };
                this.$router.push({
                    name: filteredComponentsName[step],
                });
            },
            setLocalStorageStep() {
                if (this.currentStep < 3) {
                    setVariable("MadRobots_Step", this.currentStep);
                }
            },
            setErrorStep(type) {
                if (type === "COURIER" || type === "POST") {
                    if (this.address["street"].length < 3 || this.isErrorPostDelivery) {
                        this.errorList["street"].status = true;
                    }
                    if (this.address["house"].length === 0 || this.isErrorPostDelivery) {
                        this.errorList["house"].status = true;
                    }
                    this.scrollToErrorField();
                }

                if (type === "CONTACTS") {
                    this.validationSteps(this.contact);
                }
            },
            validationSteps(list) {
                let regEmail = /\S+@\S+\.\S+/;

                Object.keys(list).forEach((key) => {
                    if (list[key].length === 0 && key !== "second_name") {
                        this.errorList[key].status = true;
                    } else {
                        if (this.globalDelivery.type === "POST") {
                            this.errorList["second_name"].status = true;
                        }
                    }
                    if (key === "phone" && list[key].length !== 18) {
                        this.errorList[key].status = true;
                    }
                    if (key === "email" && !regEmail.test(list[key])) {
                        this.errorList[key].status = true;
                    }
                });

                this.scrollToErrorField();
            },
            startLoadingSidebar() {
                this.isLoadingSidebar = true;
            },
            stopLoadingSidebar() {
                this.isLoadingSidebar = false;
            },
            setErrorsFromBackend(list) {
                Object.keys(list).forEach((nameField) => {
                    this.errorList[nameField].status = true;
                });
            },

            /**
             * Orders
             */
            setOrdersData(res) {
                this.setBasketItems(res);
                this.setOrdersVariables(res);
                this.setBonuses(res);
            },
            setBasketItems({ basketItems, upSaleCart }) {
                this.basketItems = basketItems;
                if (upSaleCart) {
                    this.basketItems[upSaleCart.id + 100500] = upSaleCart;
                    this.upSaleCart = upSaleCart;
                }
            },
            setOrdersVariables({
                orderPrice,
                basketPrice,
                orderDiscount,
                orderFullPrice,
                discountsData,
            }) {
                this.orderPrice = orderPrice || 0;
                this.basketPrice = basketPrice || 0;
                this.orderDiscount = orderDiscount || 0;
                this.orderFullPrice = orderFullPrice || 0;
                this.discountsData = discountsData || [];
            },
            updateCheckoutOrder(res) {
                this.setOrdersData(res);
                this.$eventBus.$emit("checkout-call-load-delivery");
                this.updatePackingPrice(res.packingCart);
            },
            addUpSale(id) {
                this.startLoadingSidebar();
                this.$store.orderApi
                    .modifyQuantityOrder({
                        quantity: 1,
                        product_id: id,
                        PAY_SYSTEM_ID: this.paySystem.activeId,
                        isUpSale: true,
                    })
                    .then((res) => {
                        let nameProduct = res.upSaleCart.url;

                        sendAnalytics.send(
                            sendAnalytics.firstStepCheckoutEvents.cartAddToOrder,
                            { dynamicName: nameProduct }
                        );

                        this.setOrdersData(res);

                        this.stopLoadingSidebar();

                        this.$eventBus.$emit("checkout-call-load-delivery");

                        window.scrollTo({ top: 0, behavior: "smooth" });
                    });
            },
            updateFromResult(res) {
                this.updatePacking(res);
                this.setOrdersData(res);
            },

            /**
             * Bonuses
             */
            setBonuses(res) {
                if (res.coupons || res.couponDiscountSum) {
                    this.setCoupons(res);
                    this.setMadCoins(res);
                    this.setCertificate(res);
                }
            },
            setCoupons({ coupons, couponDiscountSum, couponDiscountPercent }) {
                this.bonuses.coupon.discountPercent = couponDiscountPercent;

                if (couponDiscountPercent > 0) {
                    this.bonuses.coupon.name =
                        Object.keys(coupons).length > 0
                            ? coupons[Object.keys(coupons)].COUPON
                            : "";
                    this.bonuses.coupon.discountSum = couponDiscountSum;
                } else {
                    this.bonuses.coupon.name = "";
                    this.bonuses.coupon.discountSum = 0;
                    this.bonuses.coupon.discountPercent = 0;
                }
            },
            setMadCoins({ isApplyBonuses, bonusTotal = 0, bonusBalance = 0 }) {
                let decline = (count) =>
                    `${declineOfNumber(count, ["мэдкоин", "мэдкоина", "мэдкоинов"])}`;

                this.bonuses.madCoins.isApply = isApplyBonuses;
                this.bonuses.madCoins.total = `У вас ${bonusTotal} ${decline(bonusTotal)}`;
                this.bonuses.madCoins.discountSum = 0;
                this.bonuses.madCoins.totalCount = bonusTotal;
                this.bonuses.madCoins.applied = "";

                if (isApplyBonuses) {
                    let result = bonusTotal - bonusBalance;
                    this.bonuses.madCoins.applied = `${result} ${decline(result)}`;
                    this.bonuses.madCoins.discountSum = result;
                }
            },
            setCertificate({ giftCards }) {
                if (Object.keys(giftCards).length > 0) {
                    this.bonuses.certificate.name = giftCards[Object.keys(giftCards)].COUPON;
                    this.bonuses.certificate.discountSum =
                        giftCards[Object.keys(giftCards)].DISCOUNT;
                } else {
                    this.bonuses.certificate.name = "";
                    this.bonuses.certificate.discountSum = 0;
                }
            },

            /**
             * Delivery
             */
            setErrorPostDelivery(state) {
                this.isErrorPostDelivery = state;
            },
            setStepFromDelivery() {
                this.updateCurrentStep(1);
            },
            scrollToErrorField() {
                setTimeout(() => {
                    let elsError = document.querySelectorAll(".the-base-input--error");
                    let bodyRect = document.body.getBoundingClientRect();
                    if (elsError.length) {
                        let elemRect = elsError[0].getBoundingClientRect();
                        let offset = elemRect.top - bodyRect.top - 120;
                        scrollTo(offset, 500);
                    }
                }, 300);
            },
            resetError(name) {
                this.errorList[name].status = false;
            },
            resetAddressFields() {
                this.resetRequiredFieldErrors();
                for (let key in this.address) {
                    this.address[key] = "";
                }
            },
            resetRequiredFieldErrors() {
                this.errorList["street"].status = false;
                this.errorList["house"].status = false;
            },
            setErrorFromPost() {
                let requiredFields = ["street", "house", "build", "housing"];
                requiredFields.forEach((field) => {
                    if (this.address[field].length > 0) {
                        this.address[field] = "";
                        this.errorList[field].status = true;
                    }
                });
            },
            setFromLocalStorage({ name, value, type }) {
                if (type === "address") {
                    this.address[name] = value;
                }
                if (type === "contacts") {
                    this.contact[name] = value;
                }
            },
            toggleSecondName(state) {
                this.isSecondName = state;
            },
            setSubscribe(value) {
                this.isSubscribe = value;
            },
            setSelectedPickup({ address, price, isMr }) {
                if (price !== "Бесплатно") {
                    price = parseInt(price);
                }
                this.currentSelectedPickup.address = address;
                this.currentSelectedPickup.price = price;
                this.currentSelectedPickup.isMr = isMr;

                this.currentPriceDelivery = price;
                this.checkPackingAvailability();
            },
            updateDeliveryList(deliveryItems) {
                for (let item of deliveryItems) {
                    item.label = null;
                    if (
                        this.deliveryList[item.type].minPrice === false ||
                        this.deliveryList[item.type].minPrice > item.price
                    ) {
                        this.deliveryList[item.type].minPrice = item.price;
                    }
                    if (
                        item.minDays !== false &&
                        (this.deliveryList[item.type].minDays === false ||
                            this.deliveryList[item.type].minDays > item.minDays)
                    ) {
                        this.deliveryList[item.type].minDays = item.minDays;
                    }
                    if (
                        item.maxDays !== false &&
                        (this.deliveryList[item.type].maxDays === false ||
                            this.deliveryList[item.type].maxDays < item.maxDays)
                    ) {
                        this.deliveryList[item.type].maxDays = item.maxDays;
                    }
                    this.deliveryList[item.type].items[item.id] = item;
                    if (this.deliveryList[item.type].fastest.id.length <= 0) {
                        this.deliveryList[item.type].fastest = item;
                    }
                    item.company = item.company.replace(/\(.+\)/, "");
                }
            },
            resetDeliveryList() {
                for (let type in this.deliveryList) {
                    this.deliveryList[type].items = {};
                    this.deliveryList[type].minPrice = false;
                    this.deliveryList[type].minDays = false;
                    this.deliveryList[type].maxDays = false;
                }
            },
            resetDeliveryFastest() {
                for (let type in this.deliveryList) {
                    this.deliveryList[type].fastest.id = "";
                    this.deliveryList[type].fastest.deliveryId = "";
                }
            },
            setCurrentPriceDelivery(price) {
                this.currentPriceDelivery = parseInt(price);
            },
            /**
             * Сервис доставки умер
             */
            setStateExternalServices(state) {
                this.isDownExternalServices = state;

                if (state) {
                    if (this.currentStep === 1) {
                        this.goNextStep();
                    }
                    this.setPaySystem({ activeId: 1 });
                }
            },

            /**
             * Packing
             */
            setPacking(positions) {
                this.packing.productWrapPositions = positions;
                this.$store.orderApi
                    .checkoutResult({
                        productWrapPositions: positions,
                    })
                    .then(({ packingCart }) => {
                        this.updatePackingPrice(packingCart);
                    });
            },
            checkPackingAvailability() {
                const wrongCity = !["Москва", "Санкт-Петербург"].includes(this.city);
                const wrongPickup =
                    this.globalDelivery.type === "PICKUP" && !this.currentSelectedPickup.isMr;
                const wrongCourier =
                    this.globalDelivery.type === "COURIER" &&
                    (!this.idExpressDeliveryList.includes(this.globalDelivery.id) ||
                        !this.idMadRobotsCouriersList.includes(this.globalDelivery.id));

                if (wrongCity || wrongPickup || wrongCourier) {
                    this.packing.productWrapPositions.length = 0;
                    removeVariable("MadRobots_Packing");
                }
            },
            updatePackingPrice(packingCart) {
                if (!packingCart) {
                    this.packing.price = 0;
                } else {
                    this.packing.price = packingCart.reduce((acc, item) => {
                        return acc + item.price;
                    }, 0);
                }
            },
            updatePacking({ packingItems, packingCart }) {
                this.packing.items = packingItems;
                this.packing.cart = packingCart;

                if (Object.keys(packingItems).length === 0) {
                    this.packing.comment = "";
                }
            },
            setPackingComment(value) {
                this.packing.comment = value;
            },

            /**
             * Payment && Delivery
             */
            setPaySystem({ activeId, activeList, activeListByDelivery, list, isPrint }) {
                if (activeId || activeId === 0) this.paySystem.activeId = activeId;
                if (activeList) this.paySystem.activeList = activeList;
                if (activeListByDelivery)
                    this.paySystem.activeListByDelivery = activeListByDelivery;
                if (list) this.paySystem.list = list;
                if (isPrint) this.paySystem.isPrint = isPrint;
            },
            setGlobalDeliveryVariables({ id, type, service }) {
                if (id) this.globalDelivery.id = id;
                if (type) this.globalDelivery.type = type;
                if (service) this.globalDelivery.service = service;
            },
            resetGlobalDeliveryVariables() {
                this.globalDelivery.id = 0;
                this.globalDelivery.type = "";
                this.globalDelivery.service = 0;
            },
            setLocalStorage() {
                const packingFromStorage = JSON.parse(getVariable("MadRobots_Packing"));
                if (packingFromStorage) {
                    this.packing.productWrapPositions = packingFromStorage;
                }

                this.setGlobalDeliveryVariables({
                    id: getVariable("MadRobots_Main_Delivery_ID"),
                    type: getVariable("MadRobots_Main_Delivery_Type"),
                    service: getVariable("MadRobots_Main_Delivery_Service"),
                });
            },
            setDeliveryInterval(id) {
                this.delivery_interval = id;
            },

            /**
             * End step
             */
            setStatusOrder({ status, orderId, userEmail, sitePhone }) {
                this.statusOrder.status = status;
                this.statusOrder.id = orderId;
                this.statusOrder.email = userEmail;
                this.statusOrder.phone = sitePhone;
            },

            /**
             * Other
             */
            getCheckoutResultForFirstView() {
                return this.$store.orderApi
                    .checkoutResult({
                        city: this.city,
                        PAY_SYSTEM_ID: this.paySystem.activeId,
                        DELIVERY_METHOD: this.globalDelivery.id,
                        DELIVERY_ID: this.globalDelivery.service,
                        deliveryMethod: this.globalDelivery.id,
                        deliveryService: this.globalDelivery.service,
                    })
                    .then((res) => {
                        if (!Object.keys(res.basketItems).length) {
                            this.isEmptyCheckout = true;
                            return;
                        }

                        this.isLoginUser = res.isLoginUser || false;
                        this.freeDeliveryCount = res.freeDeliveryCount || 5000;

                        if (this.isMadRobots && res.isLoginUser) {
                            this.setUserDataForMadRobots(res);
                        }

                        this.setPaySystem({ activeId: res.paySystemId, list: res.paySystems });

                        this.setOrdersData(res);

                        this.upSaleList = res.upSaleList;
                        this.objectForCreatedProduct = {
                            parentItems: res.parentItems,
                            propertyItems: res.itemProperties,
                        };

                        setTimeout(() => {
                            this.sendAnalyticsForCheckoutWithSteps();
                        }, 600);
                        this.$eventBus.$emit("checkout-call-load-delivery");
                    });
            },
            loadYandexMap() {
                let script = document.createElement("script");
                script.async = true;
                script.defer = true;
                script.src = "https://api-maps.yandex.ru/2.1/?lang=ru_RU";
                document.getElementsByTagName("head")[0].appendChild(script);
            },
            changeCommunication(updatedList) {
                this.communication = updatedList;
            },
            onChildInit(step) {
                this.currentStep = step;
            },
            isMobile,
            setUserDataForMadRobots({ userData }) {
                if (userData.city) {
                    this.setNewCity({ cityName: userData.city });
                }

                this.contact.name = userData.name || "";
                this.contact.last_name = userData.lastName || "";
                this.contact.email = userData.email || "";
                this.contact.phone = userData.phone || "";

                this.address.street = userData.street ? String(userData.street) : "";
                this.address.build = userData.build ? String(userData.build) : "";
                this.address.flat = userData.flat ? String(userData.flat) : "";
                this.address.house = userData.house ? String(userData.house) : "";
                this.address.housing = userData.housing ? String(userData.housing) : "";
            },
            expiryLocalStorage() {
                let hours = 24;
                let saved = localStorage.getItem("MadRobots_Saved");
                let currentTime = new Date().getTime();
                if (saved && currentTime - saved > hours * 60 * 60 * 1000) {
                    setVariable("MadRobots_Step", 0);

                    setVariable("MadRobots_Main_Delivery_ID", 0);
                    setVariable("MadRobots_Main_Delivery_Type", "");
                    setVariable("MadRobots_Main_Delivery_Service", 0);

                    removeVariable("MadRobots_street");
                    removeVariable("MadRobots_house");
                    removeVariable("MadRobots_build");
                    removeVariable("MadRobots_housing");
                    removeVariable("MadRobots_flat");
                    removeVariable("MadRobots_Pickup_Address");
                    removeVariable("MadRobots_Pickup_Price");
                    removeVariable("MadRobots_Pickup_Mr");
                }

                setVariable("MadRobots_Saved", currentTime);
            },
            sendAnalyticsForCheckoutWithSteps() {
                sendAnalytics.send(sendAnalytics.events.checkout, {
                    coupons: this.bonuses.coupon.name,
                    sumDiscountCoupon: this.bonuses.coupon.discountSum,
                    basketItems: this.basketItems,
                    step: this.currentStep,
                });
            },
        },
        metaInfo() {
            return metaInfo.getMeta("order");
        },
    };
</script>

<template>
    <div class="the-checkout" :class="{ 'the-checkout--is-down': isDownExternalServices }">
        <router-view
            @child-init="onChildInit"
            @set-empty-checkout="isEmptyCheckout = true"
        ></router-view>
        <div v-if="isEmptyCheckout" class="the-container">
            <the-checkout-empty></the-checkout-empty>
        </div>
        <div v-else class="the-container">
            <the-checkout-header
                :current-step="currentStep"
                @go-prev-step="goPrevStep"
            ></the-checkout-header>

            <div class="the-checkout__wrapper">
                <the-checkout-editor
                    v-if="currentStep > 0 && currentStep < 3"
                    :current-step="currentStep"
                    :count-orders="countOrders"
                    :name-delivery="nameDelivery"
                    @update-current-step="updateCurrentStep"
                ></the-checkout-editor>

                <the-checkout-order
                    v-if="countOrders > 0 && currentStep === 0"
                    :orders-list="basketItems"
                    :object-for-created-product="objectForCreatedProduct"
                    :active-pay-system="paySystem.activeId"
                    :is-over-price-state="isOverPriceState"
                    @update-orders-data="setOrdersData"
                    @start-loading-sidebar="startLoadingSidebar"
                    @stop-loading-sidebar="stopLoadingSidebar"
                ></the-checkout-order>
                <the-order-item-skeleton v-if="countOrders === 0 && currentStep === 0">
                </the-order-item-skeleton>

                <the-checkout-delivery
                    v-show="currentStep === 1"
                    :address="address"
                    :error-list="errorList"
                    :current-step="currentStep"
                    :delivery-list="deliveryList"
                    :pay-system="paySystem"
                    :global-delivery="globalDelivery"
                    :id-express-delivery-list="idExpressDeliveryList"
                    :is-error-post-delivery="isErrorPostDelivery"
                    :is-check-regions="isCheckRegions"
                    :id-mad-robots-couriers-list="idMadRobotsCouriersList"
                    @reset-error="resetError"
                    @set-pay-system="setPaySystem"
                    @set-selected-pickup="setSelectedPickup"
                    @update-delivery="updateDeliveryList"
                    @reset-delivery-list="resetDeliveryList"
                    @start-loading-sidebar="startLoadingSidebar"
                    @stop-loading-sidebar="stopLoadingSidebar"
                    @set-current-price-delivery="setCurrentPriceDelivery"
                    @reset-fields-error="resetRequiredFieldErrors"
                    @set-global-delivery-variables="setGlobalDeliveryVariables"
                    @reset-global-delivery-variables="resetGlobalDeliveryVariables"
                    @set-from-local-storage="setFromLocalStorage"
                    @reset-delivery-fastest="resetDeliveryFastest"
                    @reset-address-fields="resetAddressFields"
                    @toggle-second-name="toggleSecondName"
                    @set-error-from-post="setErrorFromPost"
                    @set-step-from-delivery="setStepFromDelivery"
                    @set-error-post-delivery="setErrorPostDelivery"
                    @set-state-external-services="setStateExternalServices"
                    @set-delivery-interval="setDeliveryInterval"
                ></the-checkout-delivery>

                <div
                    v-show="currentStep === 2 || isScreenDownDetector"
                    class="the-checkout-step-payment the-checkout-style__box-shadow-desktop"
                >
                    <the-checkout-payment
                        v-show="!isDownExternalServices"
                        :city="city"
                        :paySystem="paySystem"
                        :delivery-list="deliveryList"
                        :global-delivery="globalDelivery"
                        :id-express-delivery-list="idExpressDeliveryList"
                        :is-over-price-state="isOverPriceState"
                        :current-selected-pickup="currentSelectedPickup"
                        :id-mad-robots-couriers-list="idMadRobotsCouriersList"
                        @set-pay-system="setPaySystem"
                        @update-from-result="updateFromResult"
                    ></the-checkout-payment>

                    <the-checkout-down-detector v-if="isDownExternalServices" :city="city">
                    </the-checkout-down-detector>

                    <the-checkout-contacts
                        :contact="contact"
                        :is-subscribe="isSubscribe"
                        :error-list="errorList"
                        :is-second-name="isSecondName"
                        :is-login-user="isLoginUser"
                        :is-mad-robots="isMadRobots"
                        @reset-error="resetError"
                        @set-subscribe="setSubscribe"
                        @set-from-local-storage="setFromLocalStorage"
                    ></the-checkout-contacts>

                    <the-checkout-notices
                        :communication="communication"
                        :email="contact.email"
                        @change-communication="changeCommunication"
                    ></the-checkout-notices>
                </div>

                <the-checkout-packing
                    v-show="currentStep === 1 && !isCheckRegions"
                    :packing="packing"
                    :products="basketItems"
                    :object-for-created-product="objectForCreatedProduct"
                    @on-set-packing="setPacking"
                    @on-set-comment="setPackingComment"
                >
                </the-checkout-packing>
            </div>

            <!--BONUSES-->
            <div
                v-show="currentStep === 0 || (isMobile() && currentStep < 3)"
                class="the-checkout__wrapper the-checkout__wrapper--bonuses"
            >
                <the-checkout-bonuses
                    :bonuses="bonuses"
                    :is-mad-robots="isMadRobots"
                    :current-step="currentStep"
                    :is-authorized="isLoginUser"
                    :product-wrap-positions="packing.productWrapPositions"
                    @update-checkout-order="updateCheckoutOrder"
                ></the-checkout-bonuses>
            </div>
            <!--BONUSES-->

            <div v-if="isVisibleUpsaleList" class="the-checkout__wrapper">
                <the-checkout-up-sale-list
                    :up-sale-list="upSaleList"
                    :object-for-created-product="objectForCreatedProduct"
                    @add-up-sale="addUpSale"
                ></the-checkout-up-sale-list>
            </div>

            <!--SIDEBAR-->
            <the-checkout-sidebar
                v-show="currentStep < 3"
                :basket-items="basketItems"
                :up-sale-cart="upSaleCart"
                :basket-price="basketPrice"
                :order-full-price="orderFullPrice"
                :order-discount="orderDiscount"
                :discounts-data="discountsData"
                :current-step="currentStep"
                :data-for-send-form="dataForSendForm"
                :is-loading-sidebar="isLoadingSidebar"
                :global-delivery="globalDelivery"
                :is-address-completed="isAddressCompleted"
                :is-contacts-completed="isContactsCompleted"
                :is-error-post-delivery="isErrorPostDelivery"
                :current-selected-pickup="currentSelectedPickup"
                :current-price-delivery="currentPriceDelivery"
                :bonuses="bonuses"
                :is-mad-robots="isMadRobots"
                :packing="packing"
                :free-delivery-count="freeDeliveryCount"
                :is-authorized="isLoginUser"
                :delivery-interval="delivery_interval"
                @go-next-step="goNextStep"
                @set-error-step="setErrorStep"
                @update-checkout-order="updateCheckoutOrder"
                @set-status-order="setStatusOrder"
                @set-errors-from-backend="setErrorsFromBackend"
            ></the-checkout-sidebar>
            <!--SIDEBAR-->

            <div class="js-divider-stop"></div>

            <the-checkout-last-step
                v-show="statusOrder.status"
                :status-order="statusOrder"
            ></the-checkout-last-step>
        </div>
    </div>
</template>

<style lang="scss">
    .the-checkout {
        margin-top: 16px;
        font-family: $checkout-font;

        @include bp($bp-desktop-sm) {
            margin-top: 50px;
            margin-bottom: 110px;
        }
    }

    .the-container {
        max-width: 1292px;
        padding: 0 10px 32px;
        margin: 0 auto;
        overflow: hidden;

        @include bp($bp-tablet-sm) {
            overflow: visible;
        }

        @include bp($bp-desktop-sm) {
            padding: 0 16px;
            position: relative;
        }
    }

    .the-checkout__wrapper {
        position: relative;

        @include bp($bp-desktop-sm) {
            padding-right: 320px;
        }

        @include bp($bp-desktop-md) {
            padding-right: 360px;
        }
    }

    .the-checkout-step-payment {
        @include bp($bp-desktop-sm) {
            padding: 15px 20px;
        }
    }

    .the-checkout-style__box-shadow {
        position: relative;
        background: #fff;
        border-radius: var(--checkout-box-radius);
        border: $checkout-box-border;

        &:after {
            content: "";
            @include center-absolute(0);
            margin: auto;
            border-radius: var(--checkout-box-radius);
            box-shadow: $checkout-box-shadow-lg;
            z-index: -1;
        }
    }

    .the-checkout-style__no-corner {
        border-radius: 0;
        @include bp($bp-desktop-sm) {
            border-radius: var(--checkout-box-radius);
        }
    }

    .the-checkout-style__box-shadow-desktop {
        @include bp($bp-desktop-sm) {
            position: relative;
            background: #fff;
            border-radius: var(--checkout-box-radius);
            border: $checkout-box-border;

            &:after {
                content: "";
                @include center-absolute(0);
                margin: auto;
                border-radius: var(--checkout-box-radius);
                box-shadow: $checkout-box-shadow-lg;
                z-index: -1;
            }
        }
    }

    .the-checkout-style__box-shadow-mobile {
        @include bp_max($bp-desktop-sm_max) {
            position: relative;
            background: #fff;
            border-radius: var(--checkout-box-radius);

            &:after {
                content: "";
                @include center-absolute(0);
                margin: auto;
                border-radius: var(--checkout-box-radius);
                box-shadow: 0 0 70px #f2f2f5;
                z-index: -1;
            }
        }
    }

    .the-checkout-tooltip {
        position: relative;
        z-index: 11;
        &:hover {
            .the-checkout-tooltip__text {
                display: block;
                visibility: visible;
                opacity: 1;
            }
        }
    }
    .the-checkout-tooltip__text {
        position: absolute;
        display: none;
        font-weight: 300;
        font-size: 12px;
        min-height: 32px;
        padding: 10px;
        text-align: center;
        color: #000;
        background: #ffffff;
        box-shadow: 0 8px 15px #c7c9d9;
        border-radius: 10px;
        visibility: hidden;
        opacity: 0;
        z-index: 111;
    }

    .the-checkout-tooltip__sub-text {
        margin-left: 3px;
    }

    //
    // --mod
    //

    .the-checkout__wrapper--bonuses {
        margin-top: 24px;

        @include bp($bp-desktop-sm) {
            margin-top: 36px;
        }
    }

    .the-checkout--is-down {
        min-height: 600px;

        .the-checkout-editor__item {
            &:nth-child(2) {
                display: none;
            }
        }

        .the-checkout-header__steps {
            opacity: 0;
        }

        .the-checkout-sidebar-order--delivery {
            display: none;
        }
    }
</style>
