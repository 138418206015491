<script>
    import { directive as onClickaway } from "vue-clickaway";

    export default {
        name: "TheCheckoutSelectBox",
        props: {
            value: [String, Number],
            options: [Object, Array],
            type: String,
        },
        data() {
            return {
                showDropdown: false,
                valueTitle: this.value,
            };
        },
        methods: {
            toggleDropdown() {
                this.showDropdown = !this.showDropdown;
            },
            selectOption(option) {
                this.valueTitle = option.name;
                this.$emit("input", { option, type: this.type });
                this.hideDropdown();
            },
            hideDropdown() {
                setTimeout(() => {
                    this.showDropdown = false;
                }, 50);
            },
        },
        directives: {
            onClickaway,
        },
    };
</script>

<template>
    <div class="select-box">
        <div
            class="select-box__name outline"
            :class="{ 'select-box__name--active': showDropdown }"
            tabindex="0"
            @click="toggleDropdown"
            @keypress.enter="toggleDropdown"
            v-on-clickaway="hideDropdown"
        >
            {{ valueTitle }}

            <svg width="10" height="6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M.158.82A.783.783 0 011.263.66L5 3.5 8.737.66a.783.783 0 011.105.16.807.807 0 01-.158 1.12L5 5.5.316 1.94A.807.807 0 01.158.82z"
                    fill="#8F90A6"
                />
            </svg>
        </div>
        <div v-show="showDropdown" class="select-box__dropdown">
            <div
                class="select-box__item outline"
                :class="{ 'select-box__item--selected': option.name === valueTitle }"
                v-for="(option, i) in options"
                :key="i"
                tabindex="0"
                @click="selectOption(option)"
                @keypress.enter="selectOption(option)"
            >
                {{ option.name }}
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .select-box {
        width: 100%;
        position: relative;
    }

    .select-box__name {
        border-radius: $checkout-select-radius;
        color: $checkout-select-color;
        font-size: 14px;
        font-weight: $checkout-select-weight;
        line-height: 16px;
        padding: 6px 20px 6px 9px;
        border: $checkout-control-border-width solid $checkout-control-border-color;
        position: relative;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        background: #fff;
        white-space: nowrap;

        svg {
            position: absolute;
            top: 11px;
            right: 9px;
        }
    }

    .select-box__name--active {
        border-radius: $checkout-select-radius $checkout-select-radius 0 0;

        svg {
            transform: rotate(180deg);
        }
    }

    .select-box__dropdown {
        position: absolute;
        background: #fff;
        width: 100%;
        left: 0;
        top: calc(100% - 1px);
        border: $checkout-control-border-width solid $checkout-control-border-color;
        border-top: 1px;
        overflow: auto;
        max-height: 253px;
        z-index: 111;
        text-align: left;
        border-radius: 0 0 $checkout-select-radius $checkout-select-radius;
    }

    .select-box__item {
        padding: 7px 10px 7px;
        cursor: pointer;
        font-size: 13px;
        line-height: normal;
        transition: 0.2s ease background-color;

        &:hover {
            background-color: rgba(23, 71, 158, 0.05);
        }

        @include bp($bp-desktop-sm) {
            &:focus-visible {
                @include outline(-2px);
            }
        }
    }

    .select-box__item--selected {
        background-color: rgba(23, 71, 158, 0.15);
        pointer-events: none;
    }
</style>
