<script>
    export default {
        name: "TheCheckoutButtonPreloader",
        props: {
            size: {
                type: String,
                default: "large",
                validator: (value) => {
                    return ["small", "large"].includes(value);
                },
            },
        },
    };
</script>

<template>
    <div class="checkout-button-preloader" :class="[`checkout-button-preloader--${size}`]">
        <div :class="[`${size}`]"></div>
        <div :class="[`${size}`]"></div>
        <div :class="[`${size}`]"></div>
        <div v-if="size === 'large'" :class="[`${size}`]"></div>
        <div v-if="size === 'large'" :class="[`${size}`]"></div>
    </div>
</template>

<style lang="scss">
    .checkout-button-preloader {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 40px;
        max-width: 100%;
        height: 6px;
        transform: translate(-50%, -50%);
        div {
            width: 6px;
            height: 6px;
            border-radius: 100%;
            background-color: #fff;
        }
        div.large {
            position: absolute;
            content: "";
            animation: checkout-button-preloader-large 2s linear infinite;
            &:nth-child(1) {
                animation-delay: -0.4s;
            }
            &:nth-child(2) {
                animation-delay: -0.8s;
            }
            &:nth-child(3) {
                animation-delay: -1.2s;
            }
            &:nth-child(4) {
                animation-delay: -1.6s;
            }
        }
        div.small {
            width: 5px;
            height: 5px;
            margin: 0 2px;
            opacity: 0;
            &:nth-child(1) {
                animation: checkout-button-preloader-small 1s ease-in-out infinite;
            }
            &:nth-child(2) {
                animation: checkout-button-preloader-small 1s ease-in-out 0.33s infinite;
            }
            &:nth-child(3) {
                animation: checkout-button-preloader-small 1s ease-in-out 0.66s infinite;
            }
        }
    }

    .checkout-button-preloader--small {
        display: flex;
        justify-content: center;
        height: 5px;
    }

    @keyframes checkout-button-preloader-large {
        0% {
            left: -24px;
            opacity: 0;
            background-color: #fff;
        }
        10% {
            left: 0;
            opacity: 1;
        }
        90% {
            left: 40px;
            opacity: 1;
        }
        100% {
            left: 64px;
            background-color: $checkout-button-color;
            opacity: 0;
        }
    }

    @keyframes checkout-button-preloader-small {
        0%,
        100% {
            opacity: 0;
        }

        60% {
            opacity: 1;
        }
    }
</style>
