<script>
    import { apiUrl } from "@/utils";

    export default {
        name: "TheCheckoutPackingProduct",
        props: {
            product: Object,
            selectedProductId: [String, Number],
        },
        computed: {
            isDisabledPacking() {
                return !this.product.packing.giftWraps?.length;
            },
        },
        methods: {
            apiUrl,
            selectProduct(id) {
                this.$emit("on-select-product", id);
            },
        },
    };
</script>

<template>
    <div
        class="the-checkout-packing-products__item"
        :class="{
            'is-selected': product.packing.selected,
            'is-active': product.parentId === selectedProductId,
            'is-disabled': isDisabledPacking,
        }"
        @click="selectProduct(product.parentId)"
    >
        <div class="the-checkout-packing-products__icon">
            <img
                class="icon-success"
                src="@/TheCheckout/assets/icons/packing/success.svg"
                alt=""
            />
            <img class="icon-info" src="@/TheCheckout/assets/icons/packing/info.svg" alt="" />
            <div class="the-checkout-packing-products__tooltip">
                <svg
                    width="13"
                    height="9"
                    viewBox="0 0 13 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M6.5 9L0.0048101 0.749999L12.9952 0.75L6.5 9Z" fill="white" />
                </svg>
                К сожалению, мы не сможем упаковать этот товар!
            </div>
        </div>
        <div class="the-checkout-packing-products__image">
            <img :src="apiUrl(product.image)" alt="" />
        </div>
    </div>
</template>

<style lang="scss"></style>
