<script>
    import { scrollState } from "@/utils";
    import TheCheckoutPackingPatterns from "@/TheCheckout/TheCheckoutPackingPatterns";

    export default {
        name: "TheCheckoutPackingDialog",
        components: {
            TheCheckoutPackingPatterns,
        },
        props: {
            isOpened: Boolean,
            selectedProduct: Object,
        },
        watch: {
            isOpened(status) {
                status ? this.show() : this.close();
            },
        },
        mounted() {
            window.addEventListener("popstate", () => {
                this.close();
            });
        },
        updated() {
            this.$refs.dialogClose.focus();
        },
        beforeDestroy() {
            window.removeEventListener("popstate ", () => {});
        },
        methods: {
            show() {
                window.addEventListener("keyup", this.onEscapeKeyUp);
                scrollState.disable();
            },
            close() {
                this.$emit("on-close");
                window.removeEventListener("keyup", this.onEscapeKeyUp);
                scrollState.enable();
            },
            selectPatternHandler(patternId) {
                this.$emit("on-confirm", patternId);
                this.close();
            },
            onEscapeKeyUp(event) {
                if (event.which === 27) {
                    this.close();
                }
            },
        },
    };
</script>

<template>
    <transition name="fade">
        <div v-show="isOpened" class="base-popup-overlay">
            <div class="base-popup-container" @click.self="close">
                <div class="base-popup">
                    <button
                        class="base-popup__close"
                        @click="close"
                        ref="dialogClose"
                    ></button>
                    <div class="base-popup__content">
                        <div class="base-popup__title">
                            Мы красиво завернем покупку в бумагу по вашему выбору.
                        </div>
                        <the-checkout-packing-patterns
                            :selected-product="selectedProduct"
                            @on-select-pattern="selectPatternHandler"
                            @on-clear-pattern="selectPatternHandler"
                        ></the-checkout-packing-patterns>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<style lang="scss" scoped>
    .base-popup-overlay {
        --transition-duration: 0.25s;
        position: fixed;
        z-index: 2101;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(#000000, 0.7);
    }

    .base-popup-container {
        width: 100%;
        height: 100%;
        outline: none;
        overflow-y: auto;
        box-sizing: border-box;
    }

    .base-popup {
        position: relative;
        min-height: 100vh;
        padding: 25px;
        background-color: #fff;
        box-sizing: border-box;

        -webkit-overflow-scrolling: touch;
        @include bp($bp-tablet-md) {
            max-width: 400px;
            margin: 20vh auto 10px;
            padding: 40px 50px 45px;
        }
    }

    .base-popup__close {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 30px;
        height: 30px;
        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 2px;
            height: 22px;
            margin: auto;
            background-color: #555770;
            border-radius: 2px;
        }
        &::before {
            transform: rotate(45deg);
        }
        &::after {
            transform: rotate(-45deg);
        }
    }

    .base-popup__content {
        @include bp($bp-tablet-md) {
            text-align: center;
        }
    }

    .base-popup__title {
        margin-bottom: 25px;
        color: var(--secondary-text);
        font-size: 12px;
        line-height: 20px;
    }
</style>
