<script>
    import TheCheckoutButtonPreloader from "@/TheCheckout/TheCheckoutButtonPreloader";
    export default {
        name: "TheCheckoutSuccess",
        components: { TheCheckoutButtonPreloader },
        props: {
            statusOrder: Object,
        },
        data() {
            return {
                isLoadingActive: false,
            };
        },
        computed: {
            calcHref() {
                return `tel:${this.statusOrder.phone}`;
            },
            calcTg() {
                return `tg://resolve?domain=MadrobotsBot&start=${this.statusOrder.id}`;
            },
            isFail() {
                return this.statusOrder.status === "fail";
            },
            isSuccessOnline() {
                return this.statusOrder.status === "successOnline";
            },
            isSuccessOffline() {
                return this.statusOrder.status === "successOffline";
            },
            title() {
                let namesTitle = "Заказ успешно оформлен!";

                if (this.isSuccessOnline) {
                    namesTitle = "Оплата прошла успешно!";
                }
                if (this.isFail) {
                    namesTitle = "Произошла ошибка платежа!";
                }

                return namesTitle;
            },
        },
        methods: {
            restartPayment() {
                if (this.isLoadingActive) return false;

                this.isLoadingActive = true;
                setTimeout(() => {
                    this.isLoadingActive = false;
                }, 2800);
                window._startPayMadRobots();
            },
            openStatusOrderModal() {
                this.$popup.show("modal-status-order", {
                    statusOrderId: String(this.statusOrder.id),
                });
            },
        },
    };
</script>

<template>
    <div class="the-checkout-last-step the-checkout-style__box-shadow">
        <div
            class="the-checkout-last-step__header"
            :class="{ 'the-checkout-last-step__header--fail': isFail }"
            :style="{
                backgroundImage: `url(/static/${$root.$_name}/checkout/success-lips.png)`,
            }"
        >
            <div class="the-checkout-last-step__header-text">
                {{ title }}
            </div>
        </div>
        <div class="the-checkout-last-step__body">
            <div class="the-checkout-last-step__order-id">{{ statusOrder.id }}</div>
            <div class="the-checkout-last-step__order-id-title">Номер вашего заказа</div>
            <div v-if="isFail" class="the-checkout-last-step__fail">
                <div class="the-checkout-last-step__fail-desc">
                    Ваш платеж по какой-то причине не был проведен.
                    <br />
                    Согласуйте другой способ оплаты с нашим оператором при подтверждении заказа
                    — или попробуйте еще раз
                </div>

                <div class="the-checkout-last-step__phone-desc">
                    <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="40" height="40" rx="10" fill="#52C41A" />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M29.959 8.35a1.196 1.196 0 111.68 1.704l-7.548 7.543h4.308c.66 0 1.2.54 1.2 1.199 0 .66-.54 1.2-1.2 1.2h-7.2a1.222 1.222 0 01-1.2-1.2V11.6c0-.66.54-1.2 1.2-1.2.66 0 1.2.54 1.2 1.2v4.305l7.56-7.555zM24.846 23.4l2.58 2.58c.252.251.444.539.576.875.132.323.204.671.204 1.019 0 .348-.072.696-.204 1.02a2.596 2.596 0 01-.576.863l-.612.611A5.638 5.638 0 0122.879 32c-1.056 0-2.1-.3-3-.875-.006 0-.012-.006-.018-.012A40.574 40.574 0 018.899 20.163a5.571 5.571 0 01-.864-3.646 5.564 5.564 0 011.596-3.333l.624-.612c.996-.995 2.76-.995 3.756 0l2.58 2.578c.252.252.444.54.576.864.132.324.204.671.204 1.02 0 .347-.072.683-.204 1.018a2.596 2.596 0 01-.576.864.244.244 0 00-.072.18c0 .072.024.132.072.18l4.116 4.113a.276.276 0 00.372 0c.252-.252.54-.444.864-.576a2.728 2.728 0 012.04 0c.324.144.612.336.864.588zm.276 5.254l.612-.612.06-.18-.024-.108-.048-.072-2.58-2.578-.18-.084-.18.084c-1.008.995-2.748.995-3.756 0l-4.128-4.125a2.628 2.628 0 01-.78-1.883c0-.72.276-1.38.78-1.883l.072-.192-.024-.12-2.64-2.638a.244.244 0 00-.18-.072.264.264 0 00-.18.072l-.612.611a3.21 3.21 0 00-.912 1.895c-.072.72.096 1.44.48 2.05a38.062 38.062 0 0010.284 10.266c.006.006.015.012.024.018a.166.166 0 01.024.018l.012.012c.528.336 1.272.516 1.98.432a3.18 3.18 0 001.896-.912z"
                            fill="#fff"
                        />
                    </svg>
                    Обычно наши операторы перезванивают в течение 10-30 минут после оформления
                    заказа в рабочее время.
                </div>
                <div class="the-checkout-last-step__controls">
                    <button
                        class="the-checkout-last-step__button outline"
                        :class="{ 'is-loading': isLoadingActive }"
                        @click="restartPayment"
                    >
                        Попробовать оплатить еще раз

                        <the-checkout-button-preloader
                            v-if="isLoadingActive"
                        ></the-checkout-button-preloader>
                    </button>
                </div>
            </div>
            <div v-else class="the-checkout-last-step__success">
                <div class="the-checkout-last-step__desc">
                    Уведомления о статусе заказа вы будете получать в WhatsApp, на почту
                    {{ statusOrder.email }}
                </div>

                <a :href="calcTg" class="the-checkout-last-step__tg">
                    Отслеживать заказ в Telegram
                </a>

                <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15.003 21.881l3.142 3.142-5.5 5.5-3.142-3.143 5.5-5.499z"
                        fill="#414042"
                    />
                    <path
                        d="M1.397 32.344l6.533-6.532a2.222 2.222 0 013.143 0l3.142 3.142a2.222 2.222 0 010 3.143l-6.532 6.532a4.444 4.444 0 11-6.286-6.285z"
                        fill="#0283D1"
                    />
                    <path
                        d="M24.839 30.188c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15z"
                        fill="#03A9F4"
                    />
                    <path
                        d="M24.839 25.743c5.83 0 10.555-4.725 10.555-10.555S30.668 4.632 24.84 4.632c-5.83 0-10.556 4.726-10.556 10.556 0 5.83 4.726 10.555 10.556 10.555z"
                        fill="#fff"
                    />
                </svg>
                <div class="the-checkout-last-step__desc the-checkout-last-step__desc--accent">
                    Узнать статус заказа можно по телефону <br />
                    <a :href="calcHref">
                        {{ statusOrder.phone }}
                    </a>
                    в меню или в чате на сайте
                </div>
                <div class="the-checkout-last-step__controls">
                    <button
                        class="
                            the-checkout-last-step__button
                            the-checkout-last-step__button--border
                            outline
                        "
                        @click="openStatusOrderModal"
                    >
                        Узнать статус заказа
                    </button>
                    <a href="/" class="the-checkout-last-step__button outline">На главную</a>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .the-checkout-last-step__header {
        height: 86px;
        background-color: $checkout-last-step-header-fill;
        border-radius: var(--checkout-box-radius) var(--checkout-box-radius) 0 0;
        background-image: url("~@/assets/images/checkout/success-lips.png");
        display: flex;
        justify-content: center;
        align-items: center;
        background-size: cover;
        padding: 0 60px;

        @include bp($bp-desktop-sm) {
            height: 120px;
            background-size: contain;
        }
    }

    .the-checkout-last-step__header-text {
        font-size: 18px;
        line-height: 21px;
        font-weight: bold;
        color: $checkout-last-step-title-color;
        text-align: center;

        @include bp($bp-desktop-sm) {
            font-size: 30px;
            line-height: 35px;
        }
    }

    .the-checkout-last-step__body {
        max-width: 420px;
        margin: 0 auto;
        padding: 16px 23px;
        text-align: center;

        @include bp($bp-desktop-sm) {
            padding: 24px 0;
        }
    }

    .the-checkout-last-step__order-id {
        font-weight: bold;
        font-size: 30px;
        line-height: 35px;
        color: $checkout-active-color;
        margin-bottom: 4px;

        @include bp($bp-desktop-sm) {
            font-size: 35px;
            line-height: 42px;
        }
    }

    .the-checkout-last-step__order-id-title {
        font-size: 14px;
        line-height: 16px;
        color: $checkout-base-text-color;
        margin-bottom: 14px;

        @include bp($bp-desktop-sm) {
            font-size: 16px;
            line-height: 20px;
        }
    }

    .the-checkout-last-step__desc {
        font-weight: 300;
        font-size: 14px;
        line-height: 16px;

        color: $checkout-base-text-color;
        margin-bottom: 20px;

        @include bp($bp-desktop-sm) {
            font-size: 16px;
            line-height: 20px;
        }

        a {
            color: $checkout-base-text-color;
        }
    }

    .the-checkout-last-step__fail {
        .the-checkout-last-step__button {
            @include bp($bp-desktop-sm) {
                width: $checkout-button-fail-width;
            }
        }
    }

    .the-checkout-last-step__fail-desc {
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: #1c1c28;
        margin-bottom: 20px;

        @include bp($bp-desktop-sm) {
            font-size: 16px;
            line-height: 28px;
        }
    }

    .the-checkout-last-step__phone-desc {
        max-width: 278px;
        margin: 0 auto 16px;
        font-weight: 300;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: $checkout-base-text-color;

        svg {
            display: block;
            margin: 0 auto 10px;
        }
    }

    .the-checkout-last-step__tg {
        display: block;
        font-size: 14px;
        line-height: 16px;
        color: $checkout-last-step-link-color;
        margin-bottom: 24px;

        @include bp($bp-desktop-sm) {
            font-size: 16px;
            line-height: 20px;
        }
    }

    .the-checkout-last-step__controls {
        @include bp($bp-desktop-sm) {
            display: flex;
            justify-content: center;
        }
    }

    .the-checkout-last-step__button {
        position: relative;
        display: block;
        margin-bottom: 10px;
        width: 100%;
        font-weight: 500;
        font-size: $checkout-button-text-size--mobile;
        line-height: 19px;
        padding: 9px;
        color: $checkout-button-text;
        background: $checkout-button-color;
        border-radius: $checkout-button-radius--large;
        border: $checkout-control-border-width solid $checkout-button-color;
        cursor: pointer;
        transition: 0.3s ease-in-out all;
        white-space: nowrap;

        @include bp($bp-desktop-sm) {
            margin: 0 5px 24px;
            font-size: $checkout-button-text-size;
            padding: 15px;
            width: $checkout-popup-button-width;
        }

        &:hover {
            background-color: $checkout-button-color--hover;
            border-color: $checkout-button-color--hover;
            color: $checkout-button-text;
        }

        &.is-loading {
            color: transparent;
        }
    }

    //
    // --mod
    //

    .the-checkout-last-step__header--fail {
        background: #fce3ec;
        background-image: none !important;

        .the-checkout-last-step__header-text {
            color: $checkout-danger-color;
        }
    }

    .the-checkout-last-step__desc--accent {
        margin-top: 4px;
        font-weight: normal;
    }

    .the-checkout-last-step__button--border {
        background-color: transparent;
        color: $checkout-button-color;
    }
</style>
